import moment from 'moment';

import { dateFormat } from '../../../../../constants/patientsConst';
import { getStringEndTime } from '../../../../../helpers';
import { getMomentDateFormatter } from '../../../../../helpers/ucr';
import { Text } from '../../../../v2/form';
import { DateInput, DurationInput, TimeInput } from '../../../../v2/form';
import { FormikValues } from '../common/types';
import { AffectedFieldsInterface } from '../common/warningHighlight';

interface BaseProps {
    isEditMode?: boolean;
    values: FormikValues;
}

interface EditModeProps extends BaseProps {
    isEditMode: true;
    loading?: boolean;
    isVisitDateDisabled?: boolean;
    isContinueBtnClicked?: boolean;
    maxDate?: Date;
    minTime?: Date;
    highlightedWarnings?: AffectedFieldsInterface;
    timeRequired?: boolean;
    timepickerKey: string;
    defaultStartTime?: Date;
}

interface ViewModeProps extends BaseProps {
    isEditMode?: false;
}

type DateTimeSelectProps = EditModeProps | ViewModeProps;

export const DateTimeSelect = (props: DateTimeSelectProps) => {
    const { values, isEditMode } = props;
    const { visitDate, startTime, duration } = values;

    if (!isEditMode) {
        const plannedDate = moment(visitDate).format('dddd Do MMMM YYYY');
        const plannedStartTime = moment(startTime).format('HH:mm');
        const expectedEndTime = startTime ? getStringEndTime(startTime, duration, 'HH:mm') : '';

        return (
            <>
                <Text
                    name="visitDate"
                    title="Planned date of job"
                    description={plannedDate}
                    descriptionTestId="visit-date"
                    className="v2__form-group--pos-1-4"
                />
                <Text
                    name="startTime"
                    title="Planned start time"
                    description={plannedStartTime}
                    descriptionTestId="start-time"
                    className="v2__form-group--pos-2-4"
                />
                <Text
                    name="duration"
                    title="Planned duration"
                    description={duration}
                    descriptionTestId="duration"
                    className="v2__form-group--pos-3-4"
                />
                <Text
                    name="endTime"
                    title="Expected end time"
                    description={expectedEndTime}
                    descriptionTestId="end-time"
                    className="v2__form-group--pos-4-4"
                />
            </>
        );
    }

    const {
        highlightedWarnings = {},
        isContinueBtnClicked,
        loading,
        maxDate,
        minTime,
        timeRequired,
        isVisitDateDisabled,
        timepickerKey,
        defaultStartTime,
    } = props;

    const { visitDate: visitDateWarning, startTime: startTimeWarning } = highlightedWarnings;

    return (
        <>
            <DateInput
                name="visitDate"
                className="v2__form-group--pos-1-4"
                displayErrors={typeof visitDateWarning === 'string' ? visitDateWarning : undefined}
                label="Planned date of job"
                {...getMomentDateFormatter(dateFormat)}
                dateFormat={dateFormat}
                required
                disabled={isVisitDateDisabled}
                isContinueBtnClicked={isContinueBtnClicked}
                maxDate={maxDate}
                minDate={minTime}
            />
            <TimeInput
                name="startTime"
                className="v2__form-group--pos-2-4"
                displayErrors={typeof startTimeWarning === 'string' ? startTimeWarning : undefined}
                label="Planned start time"
                defaultValue={startTime || defaultStartTime}
                selectAllOnFocus
                disabled={loading}
                required={timeRequired}
            />
            <DurationInput
                key={timepickerKey}
                name="duration"
                className="v2__form-group--pos-3-4"
                label="Planned duration"
                selectAllOnFocus
                disabled={loading}
                required
            />
            <TimeInput
                name="endTime"
                className="v2__form-group--pos-4-4"
                label="Expected end time"
                disabled
            />
        </>
    );
};
