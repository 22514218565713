import {
    FormattedPDSRecord,
    JobStatus,
    Patient,
    PatientAlert,
    StaffAlert,
    Warning,
} from '@doc-abode/data-models';
import { action, makeObservable, observable } from 'mobx';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { getAlerts, getJobAlerts, updateAlert } from '../api/alertsApi';
import { getPDSSpineData } from '../api/pdsApi';
import { VisitData } from '../components/pages/ucr/forms/AddVisit/AddVisitTypes';
import { ViewToShow } from '../constants/mainConst';
import { WarningCategories } from '../helpers/ucr/getWarnings';
import { isMultiAssigneeJob } from '../helpers/ucr/isMultiAssigneeJob';
import {
    IAlertProps,
    IFilterOption,
    IHcp,
    IHcpFilter,
    IHcpsPos,
    IHcpType,
    IJobsPos,
    IJobsPosArr,
    INameFilter,
    PDSDataWarning,
} from '../interfaces/ucr';
import { FormattedWarning } from '../interfaces/warning';

const moment = extendMoment(Moment as any);

export enum SideSizes {
    SMALL = 'small',
    LARGE = 'large',
}

export enum Dialogs {
    ADD_NEW_VISIT = 'add-new-visit',
    ADMINISTRATIVE_TIME = 'administrative-time',
    EDIT_ADMINISTRATIVE_TIME = 'edit-administrative-time',
    EDIT_VISIT = 'edit-visit',
    EXPORT_JOB_TIMINGS = 'export-job-timings',
    IMPORT_SCHEDULES = 'import-schedules',
    ABORT_VISIT = 'abort-visit',
    PATIENT_ALERT_INFO = 'patient-alert-info',
    REVERSE_VISIT = 'reverse-visit',
    STAFF_ALERT_INFO = 'staff-alert-info',
    NONE = 'none',
    CHANGE_VISIT_STATUS = 'change-visit-status',
    CHANGE_DOUBLE_UP_TO_SINGLE = 'change-double-up-to-single',
    DISCHARGE_PATIENT = 'discharge-patient',
}

export enum SubDialogs {
    NONE = 'none',
    PULL_FROM_REFERRAL = 'pull-from-referral',
}

export enum DialogAlerts {
    SAVE_VISIT = 'save-visit',
    SAVE_ADMINISTRATIVE_TIME = 'save-administrative-time',
    NONE = 'none',
}

export enum DragContainers {
    CALENDAR = 'calendar',
    VISITS = 'visits',
    NONE = 'none',
}

export interface IJobFilter {
    [key: string]: any;
    referralPathway: IFilterOption[];
    disposition: IFilterOption[];
    careComplexity: IFilterOption[];
    jobStatus: IFilterOption[];
    carRequired?: boolean;
    warning: IFilterOption[];
    referrer: IFilterOption[];
}

export type TFocusedUser = '' | 'user1' | 'user2';

interface ISetFocused {
    jobId?: string;
    user?: TFocusedUser;
}

const defaultHcpFilters: IHcpFilter = {
    availability: [],
    staffName: [],
    hcpType: [],
    band: [],
    gender: [],
};

const defaultJobFilters: IJobFilter = {
    referralPathway: [],
    disposition: [],
    careComplexity: [],
    jobStatus: [],
    carRequired: undefined,
    warning: [],
    referrer: [],
};

const defaultNameFilters: INameFilter = {
    staffName: [],
    patientName: [],
};

type WarningAPI = {
    id: string;
    warnings: Warning[];
};

class UCRStore {
    constructor(rootStore: any, refreshTimeout = 60000) {
        makeObservable(this, {
            hcps: observable,
            jobs: observable,
            assignedJobs: observable,
            unassignedJobs: observable,

            hcpSchedulingConflicts: observable,

            loadingJobs: observable,
            warnings: observable,
            loadingWarnings: observable,
            patientAlerts: observable,
            staffAlerts: observable,
            jobAlerts: observable,
            loadingJobAlerts: observable,
            viewToShow: observable,

            hcpFilters: observable,
            jobFilters: observable,
            nameFilters: observable,
            showAbortedJobs: observable,
            appliedFilters: observable,

            openedDialog: observable,
            openedSubDialog: observable,
            openedDialogAlert: observable,
            openedPatientAlertToResolve: observable,
            openedStaffAlertToResolve: observable,
            dragContainer: observable,
            abortVisit: observable,
            reverseVisit: observable,

            jobSummaryJob: observable,
            jobSummaryHcp: observable,

            calendarEl: observable,
            visitsEl: observable,

            cellWidth: observable,
            hcpsOffset: observable,
            hcpsWidth: observable,
            snapMinutes: observable,
            hourStart: observable,
            hourEnd: observable,
            hourScroll: observable,

            selectedDate: observable,
            panelWidth: observable,
            followUpVisitData: observable,
            followUpAdminTimeData: observable,

            hcpsPos: observable,
            expandedSwimlanes: observable,
            jobsPos: observable,
            jobsPosArr: observable,

            alertProps: observable,
            hcpTypes: observable,

            focusedJobId: observable,
            focusedUser: observable,

            localPatientData: observable,
            selectedPatientData: observable,
            hasSelectedPatientData: observable,
            pinnedStaff: observable,
            processingJobs: observable,

            setHcpFilters: action,
            setJobFilters: action,
            setNameFilters: action,
            clearAllFilters: action,
            setShowAbortedJobs: action,

            setOpenedDialog: action,
            setOpenedDialogAlert: action,
            setAbortVisit: action,

            setViewToShow: action,
            setReverseVisit: action,

            setHcps: action,
            setJobs: action,
            updateHcpSchedulingConflicts: action,
            setLoadingJobs: action,
            setLoadingWarnings: action,
            setHcpsOffset: action,
            expandHcpSwimlane: action,
            setPinnedStaff: action,
            setHcpsPos: action,
            setAllExpandedState: action,
            setJobsPosArr: action,
            setSelectedDate: action,
            setDragContainer: action,
            getCurrentJobsByUser: action,

            setFollowUpVisitData: action,

            setWarnings: action,
            updateWarnings: action,
            fetchAlerts: action,
            fetchJobAlerts: action,
            setAlertAcknowledged: action,
            dispose: action,

            openAlert: action,
            closeAlert: action,

            setFocused: action,
            setLocalPatientData: action,
            clearLocalPatientData: action,
            setSelectedPatientData: action,

            draggedJobs: observable,
            updateDragged: action,
            setBottomPanelOpen: action,
            bottomPanelOpen: observable,

            loadingLocalData: observable,
            setLocalPatientDataLoading: action,

            loadingPDSData: observable,
            pdsData: observable,
            pdsError: observable,
            pdsWarnings: observable,
            getPDSData: action,
            clearPDSData: action,
            setPDSErrorMessage: action,
            setPDSWarnings: action,
        });

        this.rootStore = rootStore;

        this.refreshTimeout = refreshTimeout;
    }

    rootStore: any;

    viewToShow: ViewToShow = ViewToShow.TIMELINE;
    hcps: IHcp[] = [];
    jobs: Patient[] = [];
    assignedJobs: Patient[] = [];
    unassignedJobs: Patient[] = [];
    hcpSchedulingConflicts: Record<string, boolean> = {};
    loadingJobs = false;
    warnings: FormattedWarning = {};
    loadingWarnings = false;
    patientAlerts: PatientAlert[] = [];
    staffAlerts: StaffAlert[] = [];
    hcpTypes: IHcpType[] = [];

    loadingJobAlerts = false;
    jobAlerts: PatientAlert[] = [];

    hcpFilters: IHcpFilter = defaultHcpFilters;
    jobFilters: IJobFilter = defaultJobFilters;
    nameFilters: INameFilter = defaultNameFilters;
    showAbortedJobs: boolean = false;
    appliedFilters: number = 0;

    openedDialog: Dialogs = Dialogs.NONE;
    openedSubDialog: SubDialogs = SubDialogs.NONE;
    openedDialogAlert: DialogAlerts = DialogAlerts.NONE;
    openedPatientAlertToResolve?: PatientAlert = undefined;
    openedStaffAlertToResolve?: StaffAlert = undefined;
    dragContainer: DragContainers = DragContainers.NONE;
    abortVisit: Patient | null = null;
    reverseVisit: Patient | null = null;

    jobSummaryJob: string | void = undefined;
    jobSummaryHcp: string | void = undefined;

    calendarEl?: HTMLDivElement;
    visitsEl?: HTMLDivElement;

    cellWidth: number = 150;
    hcpsOffset: number = 0;
    hcpsWidth: number = 460;
    hcpsMinHeight: number = 140;
    snapMinutes: number = 5;
    hourStart: number = 0;
    hourEnd: number = 23;
    hourScroll: number = 7;

    selectedDate = new Date();
    panelWidth: number = 250;

    hcpsPos: IHcpsPos = {};

    expandedSwimlanes: Record<string, boolean> = {};

    pinnedStaff: Record<string, number | undefined> = {};

    jobsPos: IJobsPos = {
        default: {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            isExpanded: false,
        },
    };

    jobsPosArr: IJobsPosArr = {
        default: [
            {
                left: 0,
                top: 0,
                width: 0,
                height: 0,
                isExpanded: false,
            },
        ],
    };

    followUpVisitData: VisitData | null = null;
    followUpAdminTimeData: Partial<VisitData> | null = null;

    alertProps: IAlertProps = {
        message: '',
        isOpen: false,
        onConfirm: () => {},
    };

    focusedJobId: string = '';

    focusedUser: TFocusedUser = '';

    fetchInterval?: NodeJS.Timeout = undefined;
    refreshTimeout: number;
    localPatientData?: Patient = undefined;
    selectedPatientData?: Patient = undefined;
    hasSelectedPatientData = false;

    draggedJobs: Record<string, true> = {};

    processingJobs: boolean = true;
    bottomPanelOpen: boolean = false;

    loadingLocalData = false;

    pdsData?: Record<string, FormattedPDSRecord>;
    pdsError = '';
    pdsWarnings: PDSDataWarning[] = [];
    loadingPDSData = false;

    setBottomPanelOpen = (open: boolean) => {
        if (open && !this.bottomPanelOpen) {
            this.bottomPanelOpen = true;
        } else if (!open && this.bottomPanelOpen) {
            this.bottomPanelOpen = false;
        }
    };

    setHcpFilters = (name: string, value?: string | string[] | IFilterOption | IFilterOption[]) => {
        this.hcpFilters = {
            ...this.hcpFilters,
            [name]: value || defaultHcpFilters[name],
        };
        this.setAppliedFilters();
    };

    setJobFilters = (
        name: string,
        value?: boolean | string | string[] | IFilterOption | IFilterOption[],
    ) => {
        this.jobFilters = {
            ...this.jobFilters,
            [name]: value ?? defaultJobFilters[name],
        };
        this.setAppliedFilters();
    };

    setNameFilters = (name: string, value?: string | string[]) => {
        this.nameFilters = {
            ...this.nameFilters,
            [name]: value || defaultNameFilters[name],
        };
    };

    clearAllFilters = () => {
        // Don't reset name filters
        this.hcpFilters = defaultHcpFilters;
        this.jobFilters = defaultJobFilters;
        this.showAbortedJobs = false;
        this.setAppliedFilters();
    };

    setAppliedFilters = () => {
        const hcpFilters = Object.values(this.hcpFilters).reduce((sum, val) => {
            if (Array.isArray(val)) {
                return sum + val.length;
            } else if (!!val) {
                return sum + 1;
            }
            return sum;
        }, 0);
        const jobFilters = Object.values(this.jobFilters).reduce((sum, val) => {
            if (Array.isArray(val)) {
                return sum + val.length;
            } else if (typeof val !== 'undefined' && val !== '') {
                return sum + 1;
            }
            return sum;
        }, 0);

        const showAborted = this.showAbortedJobs ? 1 : 0;

        this.appliedFilters = hcpFilters + jobFilters + showAborted;
    };

    setShowAbortedJobs = (value: boolean) => {
        this.showAbortedJobs = value;
        this.setAppliedFilters();
    };

    setHcpsOffset = (hcpsOffset: number) => {
        this.hcpsOffset = hcpsOffset;
    };

    setOpenedDialog = (newOpenedDialog: Dialogs) => {
        this.openedDialog = newOpenedDialog;
    };

    setOpenedSubDialog = (newOpenedSubDialog: SubDialogs) => {
        this.openedSubDialog = newOpenedSubDialog;
    };

    clearOpenedDialog = () => {
        this.openedDialog = Dialogs.NONE;
        this.openedStaffAlertToResolve = undefined;
        this.openedPatientAlertToResolve = undefined;
    };

    setOpenedStaffAlertInfo = (staffAlertToResolve: StaffAlert) => {
        this.openedDialog = Dialogs.STAFF_ALERT_INFO;
        this.openedStaffAlertToResolve = staffAlertToResolve;
    };

    setOpenedPatientAlertInfo = (patientAlertToResolve: PatientAlert) => {
        this.openedDialog = Dialogs.PATIENT_ALERT_INFO;
        this.openedPatientAlertToResolve = patientAlertToResolve;
    };

    setOpenedDialogAlert = (newOpenedDialogAlert: DialogAlerts) => {
        this.openedDialogAlert = newOpenedDialogAlert;
    };

    setJobSummary = (jobId?: string, hcpId?: string) => {
        if (!jobId) {
            this.jobSummaryJob = undefined;
            this.jobSummaryHcp = undefined;
        }

        this.jobSummaryJob = jobId;
        this.jobSummaryHcp = hcpId;
    };

    setCalendarEl = (calendarEl: HTMLDivElement) => {
        this.calendarEl = calendarEl;
    };
    setVisitsEl = (visitsEl: HTMLDivElement) => {
        this.visitsEl = visitsEl;
    };

    expandHcpSwimlane = (state: boolean, hcpId: string) => {
        this.expandedSwimlanes = { ...this.expandedSwimlanes, [hcpId]: state };
    };

    setHcpsPos = (hcpId: string, hcpTop: number, hcpHeight: number) => {
        this.hcpsPos = {
            ...this.hcpsPos,

            [hcpId]: {
                top: hcpTop - this.hcpsOffset,
                height: hcpHeight,
            },
        };
    };

    setAllExpandedState = (state: boolean) => {
        this.expandedSwimlanes = Object.fromEntries(
            Object.keys(this.hcpsPos).map((key) => [key, state]),
        );
    };

    setJobsPosArr = (jobsPos: IJobsPosArr) => {
        this.jobsPosArr = jobsPos;
    };

    openAlert = (newProps: IAlertProps) => {
        this.alertProps = {
            ...newProps,
            isOpen: true,
        };
    };

    closeAlert = () => {
        this.alertProps = {
            ...this.alertProps,
            heading: undefined,
            isOpen: false,
        };
    };

    setViewToShow = (viewToShow: ViewToShow) => {
        if (this.viewToShow === viewToShow) return false;
        this.viewToShow = viewToShow;
    };

    setSelectedDate = (date: Date) => {
        this.selectedDate = date;
    };

    get getSelectedDateMoment() {
        return this.selectedDate !== undefined ? moment(this.selectedDate.toISOString()) : moment();
    }

    setDragContainer = (container: DragContainers) => {
        this.dragContainer = container;
    };

    setHcps = (hcps: IHcp[]) => {
        this.hcps = hcps;
        this.hcpsPos = {};
    };

    setJobs = (jobs: Patient[], isFiltered?: boolean) => {
        if (!jobs) {
            return;
        }
        this.processingJobs = true;

        const assignedJobs: Patient[] = [];
        const unassignedJobs: Patient[] = [];

        jobs.forEach((job) => {
            const isDoubleUp = isMultiAssigneeJob(job);

            if (
                (job.jobStatus !== JobStatus.PENDING || job.buddyJobStatus !== JobStatus.PENDING) &&
                this.rootStore.usersStore.users.find(
                    (hcp: any) =>
                        hcp.userId === job.hcpId || (isDoubleUp && hcp.userId === job.buddyId),
                )
            ) {
                assignedJobs.push(job);
            }
            if (
                job.jobStatus === JobStatus.PENDING ||
                (isDoubleUp && job.buddyJobStatus === JobStatus.PENDING && !job.buddyId)
            ) {
                unassignedJobs.push(job);
            }
        });

        this.assignedJobs = assignedJobs;
        this.unassignedJobs = unassignedJobs
            .sort((job1: Patient, job2: Patient) =>
                job1.firstName && job2.firstName ? job1.firstName.localeCompare(job2.firstName) : 0,
            )
            .sort((job1: Patient, job2: Patient) =>
                job1.lastName && job2.lastName ? job1.lastName.localeCompare(job2.lastName) : 0,
            )
            .sort((job1: Patient, job2: Patient) =>
                moment(job1.startDateTime || job1.dateOfVisit).diff(
                    job2.startDateTime || job2.dateOfVisit,
                ),
            );

        if (!isFiltered) {
            this.jobs = jobs;
        }

        this.processingJobs = false;
    };

    updateHcpSchedulingConflicts() {
        const conflicts: Record<string, boolean> = {};

        const currentDay = moment(this.selectedDate).startOf('day');
        const currentDayInterval = moment.range(currentDay, moment(currentDay).add(1, 'days'));

        Object.keys(this.warnings).forEach((jobId) => {
            const jobWarnings = this.warnings[jobId];

            jobWarnings.forEach((warning) => {
                const { category, data } = warning;

                if (category === WarningCategories.HCP_SCHEDULING_CONFLICT && data?.hcpId) {
                    const jobDetails = this.assignedJobs.find(({ id }) => id === jobId);

                    // Only check when the job has a planned start time
                    if (jobDetails?.startDateTime) {
                        const start = moment(jobDetails.startDateTime).milliseconds(0);
                        const [hours, minutes] = (jobDetails?.duration || '').split(':');
                        const end = moment(start).add(hours, 'hours').add(minutes, 'minutes');

                        const jobInterval = moment.range(start, end);

                        if (currentDayInterval.overlaps(jobInterval)) {
                            conflicts[data?.hcpId] = true;
                        }
                    }
                }
            });
        });

        this.hcpSchedulingConflicts = conflicts;
    }

    setFollowUpVisitData = (data: VisitData | null) => {
        this.followUpVisitData = data;
    };

    setFollowUpAdminTimeData = (data: Partial<VisitData> | null) => {
        this.followUpAdminTimeData = data;
    };

    setLoadingJobs = (loading: boolean) => {
        this.loadingJobs = loading;
    };

    setAbortVisit = (job: Patient | null) => {
        this.openedDialog = Dialogs.ABORT_VISIT;
        this.abortVisit = job;
    };

    setReverseVisit = (job: Patient | null) => {
        this.openedDialog = Dialogs.REVERSE_VISIT;
        this.reverseVisit = job;
    };

    setLoadingWarnings = (loading: boolean) => {
        this.loadingWarnings = loading;
    };

    setWarnings = (data: WarningAPI[]) => {
        const warningsData: FormattedWarning = {};

        data?.forEach(({ id, warnings }) => {
            warningsData[id] = warnings;
        });

        this.warnings = warningsData;
        this.updateHcpSchedulingConflicts();
    };

    updateWarnings = (id: string, warnings: Warning[]) => {
        this.warnings[id] = warnings;
        this.updateHcpSchedulingConflicts();
    };

    fetchAlerts = async () => {
        try {
            const { patientAlerts, staffAlerts } = await getAlerts(
                {
                    jobIds: this.jobs.map((job) => job.id),
                },
                true,
            );
            this.patientAlerts = patientAlerts.map((alert) => ({
                resolvedAt: undefined,
                resolvedBy: undefined,
                resolutionNotes: undefined,
                resolution: undefined,
                ...alert,
            }));
            this.staffAlerts = staffAlerts;
        } catch (err) {
            console.error('Unable to fetch alerts', err);
        }

        // If there is a focused job we need to retrieve all the alerts for that job
        await this.fetchJobAlerts();

        // After updating the jobs we immediately call fetchAlerts so once those are set we
        // it is safe to drag jobs again
        this.resetAllDragged();
    };

    fetchJobAlerts = async () => {
        if (this.focusedJobId) {
            try {
                this.loadingJobAlerts = true;

                const { alerts } = await getJobAlerts(this.focusedJobId, true);

                this.jobAlerts = alerts;
            } catch (err) {
                console.error(`Unable to fetch alerts for job ${this.focusedJobId}`, err);
            }

            this.loadingJobAlerts = false;
        }
    };

    setAlertAcknowledged = (alert: string) => {
        this.updateAlert(alert, { acknowledged: true });
    };

    updateAlert = async (alertId: string, alert: Partial<PatientAlert | StaffAlert>) => {
        await updateAlert(alertId, alert, true);
        await this.fetchAlerts();
    };
    // todo is focused user a global bit of data?
    setFocused = ({ jobId, user }: ISetFocused) => {
        this.focusedJobId = jobId || '';
        this.focusedUser = user || '';
    };

    startTimer() {
        if (!this.fetchInterval) {
            this.fetchAlerts();
            this.fetchInterval = setInterval(() => this.fetchAlerts(), this.refreshTimeout);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = undefined;
        }
    }

    setHcpTypes = (hcpTypes: IHcpType[]) => {
        this.hcpTypes = hcpTypes;
    };

    setLocalPatientDataLoading = (isLoading: boolean) => {
        this.loadingLocalData = isLoading;
    };

    setLocalPatientData = (lastJobLocalData?: Patient) => {
        this.localPatientData = lastJobLocalData;
        this.setLocalPatientDataLoading(false);
    };

    clearLocalPatientData = () => {
        this.localPatientData = undefined;
    };

    setSelectedPatientData = (patientData?: Patient) => {
        this.selectedPatientData = patientData;
        this.hasSelectedPatientData = !!patientData;
    };

    getCurrentJobsByUser = (hcpId: string) => {
        const currentDateJobs = this.assignedJobs.filter(
            (j) =>
                moment(j.startDateTime).format('YYYY-DD-MM') ===
                    moment(this.selectedDate).format('YYYY-DD-MM') &&
                (j.hcpId === hcpId || j.buddyId === hcpId),
        );

        return currentDateJobs;
    };

    setPinnedStaff = (pinnedStaff: any) => {
        this.pinnedStaff = pinnedStaff;
    };

    updateDragged = (id: string, state: boolean) => {
        if (state) {
            this.draggedJobs = {
                ...this.draggedJobs,
                [id]: true,
            };
        } else {
            const { [id]: discard, ...rest } = this.draggedJobs;
            this.draggedJobs = rest;
        }
    };

    resetAllDragged = () => {
        this.draggedJobs = {};
    };

    setPDSErrorMessage = (error?: string) => {
        this.pdsError = error || '';
    };

    setPDSWarnings = (warnings?: PDSDataWarning[]) => {
        this.pdsWarnings = warnings || [];
    };

    getPDSData = async (nhsNumber: string) => {
        this.loadingPDSData = true;
        this.clearPDSData();

        let formattedRecord: FormattedPDSRecord | undefined;
        try {
            formattedRecord = await getPDSSpineData(nhsNumber, true);
        } catch (e) {
            if (e instanceof Error) this.setPDSErrorMessage(e.message);
        }

        const warinings: PDSDataWarning[] = [];

        if (formattedRecord?.nhsNumber && formattedRecord?.nhsNumber !== nhsNumber) {
            warinings.push({
                title: 'NHS number superseded',
                message:
                    'Please check that you have looked up the right patient record. The NHS number has been superseded. The system will not prevent you from creating a job for this patient but will record this against the new NHS number.',
            });
        }

        if (formattedRecord?.deceasedDateTime) {
            warinings.push({
                title: 'Patient marked as deceased on SPINE',
                message:
                    'Please check that you have looked up the right patient record. The system will not prevent you from creating a job for this patient.',
            });
        }

        if (formattedRecord?.security.code === 'REDACTED') {
            warinings.push({
                title: 'NHS number invalid',
                message:
                    'The NHS number being used is no longer valid. The invalid number has been flagged to Doc Abode support and will no longer be synchronised with SPINE. Please be aware that any demographic and clinical information held against this NHS number should be regarded with caution and could constitute a clinical risk.',
            });
        }

        const newPdsData = { [nhsNumber]: { ...(formattedRecord || ({} as FormattedPDSRecord)) } };

        this.pdsData = newPdsData;
        this.setPDSWarnings(warinings);

        this.loadingPDSData = false;
    };

    clearPDSData = (overwriteIssues = true) => {
        this.pdsData = undefined;
        if (overwriteIssues) {
            this.pdsError = '';
            this.pdsWarnings = [];
        }
    };
}

export default UCRStore;
