import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Amplify } from 'aws-amplify';
import { Provider } from 'mobx-react';

import config from '../config';
import { useServiceWorker } from '../hook/useServiceWorker';
import RootStore from '../stores/RootStore';
import App from './App';
import AppShellErrorBoundary from './common/ErrorBoundaries/AppShellBoundary';
import Footer from './layout/Footer';
import BasicHeader from './layout/header/BasicHeader';
import { EnvironmentInfo } from './modules/callout/EnvironmentInfo';
import { ServiceWorkerUpdate } from './modules/callout/ServiceWorkerUpdate';
import { authComponents } from './v2/components/Auth/authComponents';

const rootStore = new RootStore();
const queryClient = new QueryClient();

const { aws } = config();

Amplify.configure({
    ...aws,
    aws_project_region: 'eu-west-2',
    aws_cognito_region: 'eu-west-2',
});

const AuthLayer = () => (
    <Authenticator
        hideSignUp
        components={authComponents}
        passwordSettings={{
            minLength: 16,
            requireLowercase: true,
            requireUppercase: true,
            requireNumbers: true,
            requireSpecialCharacters: true,
        }}
    >
        <Provider RootStore={rootStore}>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
            <Footer />
        </Provider>
    </Authenticator>
);

const AuthWrapper = () => {
    const { user } = useAuthenticator((context) => [context.user]);

    if (user) {
        return <AuthLayer />;
    }

    return (
        <div>
            <BasicHeader />
            <AuthLayer />
        </div>
    );
};

export const AppShell = () => {
    const { isUpdateReady, applyUpdate } = useServiceWorker();

    const callouts = [];

    if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
        callouts.push(<EnvironmentInfo key="environment-info" />);
    }

    if (isUpdateReady) {
        callouts.push(
            <ServiceWorkerUpdate applyUpdate={applyUpdate} key="service-worker-update" />,
        );
    }

    return (
        <>
            <div className={`container container--callout-x${callouts.length}`}>
                {callouts}
                <ErrorBoundary fallback={AppShellErrorBoundary}>
                    <Authenticator.Provider>
                        <AuthWrapper />
                    </Authenticator.Provider>
                </ErrorBoundary>
            </div>
            <div id="custom-prompt"></div>
        </>
    );
};
