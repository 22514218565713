import { isAdminTypeEnabled } from '@doc-abode/helpers';

import { ADMIN_TIME } from '../../../components/pages/ucr/forms/AdminTime/AdminTimeConsts';
import { FormFieldRecord } from '../jobCreator';
import { JobType } from './JobTypes';

export const adminGenericSchema: FormFieldRecord[] = [
    {
        key: 'disposition',
        followUpCopy: [JobType.ADMIN],
        transformValue: () => {
            return ADMIN_TIME;
        },
    },
    {
        key: 'activityType',
        followUpCopy: [JobType.ADMIN],
    },
    {
        key: 'activityType',
        followUpCopy: [JobType.ADMIN],
        onCreateOnly: true,
        transformValue: (props) => {
            const { values, adminTypes } = props;

            // if its not a follow up, return set activityType
            if (!props.followUp) {
                return values.activityType;
            }

            // Return previous activity type if its still enabled
            const { enabled } = isAdminTypeEnabled({
                adminTypes,
                selectedAdminType: values.activityType,
            });

            if (!enabled) {
                return;
            }

            return values.activityType;
        },
    },
];
