const BasicHeader = () => {
    return (
        <header className="header ucr__header header--centered" data-testid="basic-header">
            <img
                src={'/images/logos/docabode_logo-white.png'}
                alt="Doc Abode Logo"
                className="header__logo header__logo--large"
            />
        </header>
    );
};

export default BasicHeader;
