import {
    AbortReason,
    AdminTypeV2,
    Band,
    Config,
    Features,
    HCPType,
    Hub,
    JobStatus,
    JobType,
    LegendV2,
    Pathway,
    Patient,
    VaccinationDetails,
} from '@doc-abode/data-models';
import { getAssignmentStatusReasons } from '@doc-abode/helpers';
import { action, computed, makeObservable, observable } from 'mobx';

import { JobDictionary } from '../__generated__/v2';
import { setOrganisation } from '../api/baseApi';
import { getClientKeys, getConfig } from '../api/configApi';

class ConfigStore implements Partial<Config> {
    rootStore: any;

    constructor(rootStore: any) {
        makeObservable(this, {
            loaded: observable,
            mapCentre: observable,
            mapZoomLevel: observable,
            confirmRegisterHCPText: observable,
            bands: observable,
            liveLocationThreshold: observable,
            name: observable,
            org: observable,
            features: observable,
            hubs: observable,
            presetAddresses: observable,
            hcpTypes: observable,
            patientUploadAdapter: observable,
            defaultShiftPrice: observable,
            defaultShiftLength: observable,
            setConfig: action,
            permittedDomains: observable,
            getClientKeys: action,
            clientKeys: observable,
            vaccinationDuration: observable,
            vaccinationDetails: observable,
            doseInterval: observable,
            pathways: observable,
            adminTypes: observable,
            legend: observable,
            abortReasons: observable,
            jobDictionary: observable,
            setJobDictionary: action,
            jobTypes: computed,
            getAssignmentStatusReasonsForJob: action,
        });

        this.rootStore = rootStore;
    }

    loaded = false;
    mapCentre = {};
    liveLocationThreshold = {};
    mapZoomLevel = 0;
    confirmRegisterHCPText = '';
    bands: Band[] = [];
    name = '';
    org = '';
    features: Features = {};
    hubs: Hub[] = [];
    presetAddresses: string[] = [];
    hcpTypes: HCPType[] = [];
    patientUploadAdapter = '';
    defaultShiftPrice = null;
    defaultShiftLength = null;
    permittedDomains: string[] = [];
    clientKeys = {
        googleMaps: '',
    };
    vaccinationDuration: Record<string, string> = {};
    vaccinationDetails: VaccinationDetails = {};
    doseInterval = {};
    pathways: Pathway[] = [];
    adminTypes: AdminTypeV2[] = [];
    legend: LegendV2 = {};
    abortReasons: AbortReason[] = [];
    jobDictionary: JobDictionary = { jobTypes: [] };

    setConfig = (config: Config) => {
        Object.keys(config).forEach((key) => {
            (this as Record<typeof key, unknown>)[key] = config[key as keyof Config];
        });

        this.pathways = config.pathwaysV2 || [];
        this.legend = config.legendV2 || {};

        const { lovsStore } = this.rootStore;

        lovsStore.setLov('disposition', config.disposition);
        lovsStore.setLov(
            'hcpType',
            config.hcpTypes
                ?.filter(({ enabled }) => enabled)
                .sort((a: HCPType, b: HCPType) => a.label.localeCompare(b.label)) || [],
        );
        lovsStore.setLov('referrer', config.referrer || []);
        lovsStore.setLov('availabilityTimeSettings', config.availabilityTimeSettings);
        lovsStore.setLov('managementDay', config.managementDay);
        lovsStore.setLov(
            'bands',
            config.bands?.sort((a: Band, b: Band) => a.label.localeCompare(b.label)) || [],
        );
        lovsStore.setLov('liveLocationThreshold', config.liveLocationThreshold);

        this.loaded = true;
    };

    getConfig = async () => {
        try {
            const config = await getConfig(true);
            this.setConfig(config);
        } catch (err) {
            console.error('Unable to fetch config for organisation', err);
        }
    };

    isFeatureEnabled = (feature: string) => this.features[feature] === true;

    getHubName = (hubId: string) => this.hubs.find((hub) => hub.id === hubId)?.name;

    get hubIds() {
        return this.hubs.map((hub) => hub.id);
    }

    updateOrganisation = (organisation: string) => {
        this.org = organisation;
        window.localStorage.setItem('DocAbodeOrganisation', organisation);
        setOrganisation(organisation);
    };

    checkOrganisation = (userOrganisations: string[]) => {
        const presetOrganisation = window.localStorage.getItem('DocAbodeOrganisation');
        if (presetOrganisation && userOrganisations.includes(presetOrganisation)) {
            this.org = presetOrganisation;
            setOrganisation(presetOrganisation);
            return presetOrganisation;
        } else {
            const [organisation] = userOrganisations;
            this.updateOrganisation(organisation);
            return organisation;
        }
    };

    switchOrganisation = (organisation: string) => {
        this.updateOrganisation(organisation);
        window.location.pathname = '/';
    };

    getClientKeys = async () => {
        try {
            this.clientKeys = await getClientKeys(true);
        } catch (err) {
            console.error('Unable to fetch client keys', err);
        }
    };

    setJobDictionary = (jobDictionary: JobDictionary) => {
        if (jobDictionary?.jobTypes?.length) {
            this.jobDictionary = jobDictionary;
        }
    };

    get jobTypes() {
        return this.jobDictionary.jobTypes ?? [];
    }

    getAssignmentStatusReasonsForJob = (job: Patient, status: JobStatus) => {
        const useRelationalDB = this.isFeatureEnabled('useRelationalDB');

        let jobType = job.jobType;

        if (jobType === JobType.UCR && useRelationalDB) {
            jobType = job.disposition === 'admin' ? 'administrative' : 'visit';
        }

        return getAssignmentStatusReasons(this.jobTypes, jobType, status);
    };
}

export default ConfigStore;
