import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import {
    HCPType,
    Hub,
    RoutePatientInput,
    Vaccination,
    VaccinationCategory,
} from '@doc-abode/data-models';
import moment from 'moment'; // needs to be imported like this, default import does not work with extension
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { confirmRoutes, createRoutes, requestRoutes } from '../../../../../api/routes/routesApi';
import { ICreateRoutesResponse, SmsResults } from '../../../../../api/routes/types';
import usePatientsContext from '../../../../../hook/usePatientsContext';
import { isValidRouteForm } from '../../routesHelpers';
import { HubWithCompatibleWith, VaccinationRoute, VaccinationWithWarnings } from '../../types';
import { checkDateTimeWarnings, formatPatientForRoute, getWarnings, routeTypes } from '../../utils';
import { ErrorWithDetails, errorWithDetailsSchema } from './validation';

interface IUseCreateRoutesViewControllerProps {
    doseInterval: Record<string, Record<string, number>>;
    vaccinationDuration: Record<string, string>;
    vaccinationDetails: Record<string, { requiresPickup: boolean }>;
    hubs: HubWithCompatibleWith[];
    getUserSession: any;
    setVaccinations: any;
    refetchRoutes: (
        variables?: Partial<OperationVariables> | undefined,
    ) => Promise<ApolloQueryResult<any>>;
    jobs: Vaccination[];
    deselect: (id: string) => void;
    hcpType: HCPType[];
}
const useCreateRoutesViewController = ({
    getUserSession,
    setVaccinations,
    refetchRoutes,
    deselect,
    hcpType,
    vaccinationDetails,
    vaccinationDuration,
    hubs,
    doseInterval,
}: IUseCreateRoutesViewControllerProps) => {
    const [startTime, setStartTime] = useState(moment().hour(9).startOf('hour').toDate());
    const [endTime, setEndTime] = useState(moment().hour(17).startOf('hour').toDate());
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [requestingRoutes, setRequestingRoutes] = useState(false);
    const [confirmingRoutes, setConfirmingRoutes] = useState(false);
    const [confirmedRoutes, setConfirmedRoutes] = useState(false);
    const [routes, setRoutes] = useState<VaccinationRoute[]>([]);
    const [capacity, setCapacity] = useState(11);
    const [hub, setHub] = useState<Hub | undefined>(undefined);
    const [didError, setDidError] = useState(false);
    const [actualErrorForDidError, setActualErrorForDidError] = useState<ErrorWithDetails>();
    const [smsResults, setSmsResults] = useState<SmsResults>();
    const [selectedRoute, setSelectedRoute] = useState<string | null>(null);
    const [showPatientList, setShowPatientList] = useState(false);
    const { state } = useLocation<{
        selectedDate: Date;
        routeType?: string;
    }>();
    const [loadingWarnings, setLoadingWarnings] = useState(true);

    const { selectedPatients: jobs } = usePatientsContext();

    const selectedDate = useRef(moment().add(1, 'day').seconds(0).milliseconds(0).toDate());
    const { selectedDate: selectedDateFromState } = state || {};
    if (selectedDateFromState && moment(selectedDateFromState).isAfter(moment().startOf('day'))) {
        selectedDateFromState.setSeconds(0);
        selectedDate.current = selectedDateFromState;
    }

    const [newSelectedDate, setNewSelectedDate] = useState<Date>(selectedDate.current);

    const multipleHubWarning = useMemo(() => {
        for (let i = 0; i < jobs.length; i++) {
            const { hubId } = jobs[i];
            const hub = hubs.find((hub) => hub.id === hubId);

            if (i === 0) {
                setHub(hub);
                continue;
            }

            if (!hub) {
                continue;
            }

            const { compatibleWith = [] } = hub;
            const otherHubIds = jobs
                .filter((otherJob) => otherJob.id !== jobs[i].id)
                .map((otherJob) => otherJob.hubId);

            if (otherHubIds.some((hubId) => hubId !== hub.id && !compatibleWith.includes(hubId))) {
                return true;
            }
        }
        return false;
    }, [hubs, jobs]);

    let routeType = jobs.some(
        ({ vaccinationCategory }) => vaccinationCategory === VaccinationCategory.COVID_19,
    )
        ? routeTypes.COVID
        : routeTypes.NON_COVID;

    if (state.routeType) {
        routeType = state.routeType;
    }

    const routeForItinerary = useMemo(() => {
        if (!routes || !selectedRoute) return null;
        return routes.find((route) => route.id === selectedRoute);
    }, [routes, selectedRoute]);

    const onConfirm = async () => {
        setConfirmingRoutes(true);

        try {
            const userSession = await getUserSession();
            const { smsResults } = await confirmRoutes(userSession.tokens.id, {
                routes: routes.map(({ id, itineraryId, itinerary }) => ({
                    id,
                    itineraryId,
                    hubId: hub?.id,
                    hcpTypes: hcpType.map(({ value }) => value),
                    jobs: itinerary.instructions
                        .map(
                            ({ instructionType, itineraryItem }) =>
                                instructionType === 'VisitLocation' && itineraryItem?.name,
                        )
                        .filter((_) => _),
                })),
            });
            setSmsResults(smsResults);
            setVaccinations();
            await refetchRoutes();
            jobs.forEach(({ id }) => deselect(id));
            setConfirmedRoutes(true);
        } catch (err) {
            console.error(err);
        }

        setConfirmingRoutes(false);
    };

    const disableSubmit = useMemo(() => {
        return !isValidRouteForm({
            multipleHubWarning,
            selectedUsers,
            jobs,
            selectedDate: newSelectedDate,
        });
    }, [multipleHubWarning, selectedUsers, jobs, newSelectedDate]);

    const hubOptions = Array.from(new Set(jobs.map((job) => job.hubId)))
        .map((hubId) => hubs.find((hub) => hub.id === hubId))
        .map((hub) => ({
            label: hub!.name,
            value: hub!.id,
        }));

    const selectedStartDateTime = () =>
        moment(newSelectedDate)
            .hour(moment(startTime).hour())
            .minute(moment(startTime).minute())
            .startOf('minute');

    const selectedEndDateTime = () =>
        moment(newSelectedDate)
            .hour(moment(endTime).hour())
            .minute(moment(endTime).minute())
            .startOf('minute');

    const isCovidRoute = jobs.some(
        ({ vaccinationCategory }) => vaccinationCategory === VaccinationCategory.COVID_19,
    );

    const [vaccinationsWithWarnings, setVaccinationsWithWarnings] = useState<
        VaccinationWithWarnings[]
    >([]);
    const onRemoveWarningPatients = () => {
        vaccinationsWithWarnings.forEach(({ id }) => deselect(id));
    };

    const fetchWarnings = useCallback(async () => {
        const warnings = await getWarnings(
            jobs,
            newSelectedDate.toISOString(),
            doseInterval,
            vaccinationDetails,
        );
        setVaccinationsWithWarnings(warnings);
        setLoadingWarnings(false);
    }, [doseInterval, jobs, newSelectedDate, vaccinationDetails]);
    useEffect(() => {
        fetchWarnings();
    }, [fetchWarnings]);

    const selectedDateErrorMessage = newSelectedDate ? '' : 'Date required';

    const onToggleUser = (
        id: string,
        checked: boolean,
        event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }

        if (checked) {
            setSelectedUsers([...selectedUsers, id]);
        } else {
            setSelectedUsers(selectedUsers.filter((userId) => userId !== id));
        }
    };

    const onChangeHub = (event: React.FormEvent<HTMLSelectElement>) =>
        setHub(hubs.find((hub) => hub.id === event.currentTarget.value));

    const onSubmit = async () => {
        const hcps = selectedUsers.map((userId) => {
            const shifts = [
                {
                    userId,
                    startTime: selectedStartDateTime().format(),
                    endTime: selectedEndDateTime().format(),
                    startAddress: hub?.address,
                    endAddress: hub?.address,
                },
            ];

            return {
                userId,
                shifts,
                capacity: [isCovidRoute ? capacity : 0],
            };
        });

        let patients = jobs.reduce((patients, job) => {
            const formattedPatient = formatPatientForRoute({
                patient: job,
                selectedDate: newSelectedDate.toISOString(),
                vaccinationDuration,
                vaccinationDetails,
                routeType,
                jobs,
            });

            if (formattedPatient) {
                patients.push(formattedPatient);
            }

            return patients;
        }, [] as RoutePatientInput[]);

        setDidError(false);
        setRequestingRoutes(true);

        try {
            const userSession = await getUserSession();
            const response = await requestRoutes(userSession.tokens.id, {
                hcps,
                patients,
            });

            const { requestId } = response;
            let timeout = response.callbackInSeconds;

            const queryRoutes = async (): Promise<void> => {
                const response = await new Promise<ICreateRoutesResponse>((resolve) =>
                    setTimeout(async () => {
                        const response = await createRoutes(
                            userSession.tokens.id,
                            requestId,
                            isCovidRoute ? routeTypes.COVID : routeTypes.NON_COVID,
                        );
                        resolve(response);
                    }, timeout * 1000),
                );

                if (response.callbackInSeconds) {
                    timeout = response.callbackInSeconds;
                    return queryRoutes();
                }

                setRoutes(response.routes);
                setRequestingRoutes(false);
            };

            queryRoutes();
        } catch (err) {
            setRequestingRoutes(false);
            try {
                let message = 'Error requesting routes. Error is not instance of Error';
                if (err instanceof Error) message = err.message;
                const parsedErrorAttempt = errorWithDetailsSchema.safeParse(JSON.parse(message));
                if (parsedErrorAttempt.success) {
                    const parsedError = parsedErrorAttempt.data;
                    setDidError(true);
                    setActualErrorForDidError(parsedError);
                    if (parsedError.message !== 'Not Found') {
                        console.error(message);
                    }
                } else if (parsedErrorAttempt.error) {
                    console.error(parsedErrorAttempt.error);
                }
            } catch (parseError) {
                console.error(err);
            }
        }
    };

    const selectedDateTimeWarnings = checkDateTimeWarnings(
        newSelectedDate.toISOString(),
        startTime.toISOString(),
        endTime.toISOString(),
    );

    return {
        startTime,
        endTime,
        onEndTimeChange: setEndTime,
        selectedUsers,
        requestingRoutes,
        confirmingRoutes,
        confirmedRoutes,
        routes,
        capacity,
        hub,
        vaccinationsWithWarnings,
        didError,
        actualErrorForDidError,
        smsResults,
        selectedRoute,
        setSelectedRoute,
        showPatientList,
        setShowPatientList,
        state,
        loadingWarnings,
        selectedDateErrorMessage,
        onConfirm,
        onStartTimeChange: setStartTime,
        onSubmit,
        disableSubmit,
        routeType,
        onRemoveWarningPatients,
        multipleHubWarning,
        isCovidRoute,
        onChangeHub,
        onCapacityChange: setCapacity,
        hubOptions,
        onToggleUser,
        selectedDateTimeWarnings,
        newSelectedDate,
        onNewSelectedDateChange: setNewSelectedDate,
        routeForItinerary,
    };
};

export default useCreateRoutesViewController;
