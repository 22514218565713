import cn from 'classnames';
import { FC, ReactNode, useCallback, useState } from 'react';

import { IconClose } from '../../../../../helpers/ucr/icons';

interface IProps {
    title: string;
    side: string;
    children: ReactNode;
    isShow: boolean;
    testId?: string;
    closePanel: () => void;
}

const Panel: FC<IProps> = ({ title, side, children, isShow, testId, closePanel }) => {
    const [isHover, setHover] = useState<boolean>(false);

    const onMouseEnter = useCallback(() => setHover(true), []);
    const onMouseLeave = useCallback(() => setHover(false), []);

    const dataTestId = testId ?? title;

    return (
        <div
            className={cn('ucr__side-panel', `ucr__side-panel--${side}`, {
                'ucr__side-panel--show': isShow,
                'ucr__side-panel--hide': !isShow,
            })}
            data-testid={dataTestId}
        >
            <div
                className={cn('ucr__side-panel-wrapper', `ucr__side-panel-wrapper--${side}`, {
                    'ucr__side-panel-wrapper--show': isShow,
                    'ucr__side-panel-wrapper--hide': !isShow,
                })}
            >
                <div className="ucr__side-panel-header">
                    <span className="ucr__side-panel-title" data-testid={`${dataTestId}Title`}>
                        {title}
                    </span>
                    <button
                        type="button"
                        className={cn('ucr__side-panel-close', {
                            'ucr__side-panel-close--hovered': isHover,
                            'ucr__side-panel-close--default': !isHover,
                        })}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        onClick={closePanel}
                        data-testid={`${dataTestId}CloseButton`}
                    >
                        <IconClose />
                    </button>
                </div>
                <div className="ucr__side-panel-content">{children}</div>
            </div>
        </div>
    );
};

export default Panel;
