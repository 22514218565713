import { Patient } from '@doc-abode/data-models';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { IconExpandMore } from '../../../../../../helpers/ucr/icons';
import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../../v2/components';
import {
    DROPDOWN_ITEM_STYLE,
    useInfoPanelActionMenuViewModel,
} from './useInfoPanelActionMenuViewModel';

interface IActionMenuOptionProp {
    title: string;
    onClick: () => void;
}

const ActionMenuOption: FC<IActionMenuOptionProp> = ({ title, onClick }) => {
    return (
        <button
            className={cn('visit-details__header-actions-list-row', DROPDOWN_ITEM_STYLE)}
            onClick={onClick}
        >
            {title}
        </button>
    );
};

interface IInfoPanelActionMenuProps {
    job: Patient;
    currentVisit: Patient;
}

export const InfoPanelActionMenu: FC<IInfoPanelActionMenuProps> = observer(
    ({ job, currentVisit }) => {
        const {
            showDropDown,
            setShowDropDown,
            onBlur,
            onOpenJobDetails,
            showPatientAlert,
            patientAlerts,
            onOpenPatientAlert,
            showCreateFollowUpVisit,
            onCreateFollowUpVisit,
            showCreateAdmin,
            showCreateFollowUpAdmin,
            onCreateFollowUpAdmin,
            showAbortJob,
            onAbortJob,
            showReverseAbortJob,
            onReverseAbortJob,
            showChangeToSingleAssignee,
            onChangeToSingleAssignee,
            showChangeStatus,
            onChangeStatus,
            showS1MessageLog,
            onShowS1MessageLog,
        } = useInfoPanelActionMenuViewModel({ job, currentVisit });

        return (
            <div className={cn(DROPDOWN_ITEM_STYLE)} onBlur={onBlur}>
                <Button
                    className="v2__form-submit-button visit-details__header-actions-button"
                    size={ButtonSizes.MEDIUM}
                    elem={ButtonElems.BUTTON}
                    color={ButtonColors.BLUE}
                    Icon={IconExpandMore}
                    name="Actions"
                    clickEvent={() => setShowDropDown(!showDropDown)}
                    ariaLabel="visit actions"
                />

                {showDropDown && (
                    <div className={cn('visit-details__header-actions-list')}>
                        <ActionMenuOption title="View full details" onClick={onOpenJobDetails} />

                        {showPatientAlert && (
                            <>
                                {patientAlerts.map((alert) => (
                                    <ActionMenuOption
                                        title="View alert details"
                                        key={alert.alertId}
                                        onClick={() => onOpenPatientAlert(alert)}
                                    />
                                ))}
                            </>
                        )}

                        {showAbortJob && <ActionMenuOption title="Cancel" onClick={onAbortJob} />}

                        {showReverseAbortJob && (
                            <ActionMenuOption
                                title="Reverse cancellation"
                                onClick={onReverseAbortJob}
                            />
                        )}

                        {showCreateFollowUpVisit && (
                            <ActionMenuOption
                                title="Create follow-up visit"
                                onClick={onCreateFollowUpVisit}
                            />
                        )}

                        {(showCreateAdmin || showCreateFollowUpAdmin) && (
                            <ActionMenuOption
                                title="Create admin task"
                                onClick={onCreateFollowUpAdmin}
                            />
                        )}

                        {showChangeToSingleAssignee && (
                            <ActionMenuOption
                                title="Change to single staff"
                                onClick={onChangeToSingleAssignee}
                            />
                        )}

                        {showChangeStatus ? (
                            <ActionMenuOption title="Change status" onClick={onChangeStatus} />
                        ) : null}

                        {showS1MessageLog ? (
                            <ActionMenuOption
                                title="Show S1 messages"
                                onClick={onShowS1MessageLog}
                            />
                        ) : null}
                    </div>
                )}
            </div>
        );
    },
);
