import { Warning } from '@doc-abode/data-models';

type IFilterWarnings = {
    warnings?: Warning[];
    category?: string;
    userId?: string;
};

export function filterWarnings({ warnings, category, userId }: IFilterWarnings) {
    if (!warnings) {
        return [];
    }

    return warnings.filter((warning) => {
        if (category && warning.category !== category) {
            return false;
        }
        if (userId && warning.data?.hcpId !== userId) {
            return false;
        }

        return true;
    });
}
