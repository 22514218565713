import { Card, Tag } from '@blueprintjs/core';
import { Route } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';

import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import { VaccinationRoute } from '../../../types';
import { useRouteSummaryCardViewModel } from './useRouteSummaryCardViewModel';

interface IProps {
    index: number;
    route: Route | VaccinationRoute;
    showJobStatusTag: boolean;
    children?: ReactNode | ReactNode[];
}

export const RouteSummaryCard = observer(({ index, route, showJobStatusTag, children }: IProps) => {
    const {
        borderColour,
        jobStatusIntentColour,
        jobStatusTagMinimal,
        jobStatusLabel,
        hcpName,
        numberOfPatients,
        formattedTravelDistance,
        totalTravelTime,
        formattedStartTime,
        formattedEndTime,
    } = useRouteSummaryCardViewModel({ index, route });

    return (
        <Card
            key={`route-summary-card-${route.id}`}
            className="create-routes__summary create-routes__summary--snug"
            style={{ borderBottomColor: borderColour }}
        >
            <ConditionalDisplay show={showJobStatusTag}>
                <Tag
                    intent={jobStatusIntentColour}
                    minimal={jobStatusTagMinimal}
                    className="create-routes__summary-tag"
                    data-testid="route-summary-card-job-status-card"
                >
                    {jobStatusLabel}
                </Tag>
            </ConditionalDisplay>

            <p>
                <strong>HCP:</strong> {hcpName}
            </p>
            <p>
                <strong>Patients:</strong> {numberOfPatients}
            </p>
            <p>
                <strong>Start:</strong> {formattedStartTime}
            </p>
            <p>
                <strong>End:</strong> {formattedEndTime}
            </p>
            <p>
                <strong>Total travel time:</strong> {totalTravelTime}
            </p>
            <p>
                <strong>Total travel distance:</strong> {formattedTravelDistance} miles
            </p>
            {children}
        </Card>
    );
});
