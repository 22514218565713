import { AdminTypeV2, Pathway, Patient, Service } from '@doc-abode/data-models';
import { getDefaultAdminDuration, getDefaultServiceDuration } from '@doc-abode/helpers';

import { getPathwayServiceOrAdminTypeLabels } from '../../../../../helpers/ucr/getPathwayServiceOrAdminTypeLabels';
import { isAdminJob } from '../../../../../helpers/ucr/isAdminJob';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { FormikErrors, FormikValues, SetFormikValues } from '../common/types';

export interface UsePathwayServiceSelectViewModelProps {
    values: FormikValues;
    setValues: SetFormikValues | null;
}

export interface UsePathwayServiceSelectViewModelOutput {
    selectedPathwayOverride: Pathway | null;
    setValuesFn: SetValuesFn;
    pathways: Pathway[];
    services: (Service | AdminTypeV2)[];
    pathwayDisplayLabel: string;
    serviceOrAdminTypeDisplayLabel: string;
    serviceOrAdminTypeLabel: string;
    serviceOrAdminTypeName: string;
}

export type SetValuesFn = (value: string) => Promise<void | FormikErrors<FormikValues>>;

type GetValuesOutput = {
    selectedPathwayOverride: Pathway | null;
    setValuesFn: SetValuesFn;
    sortedServices: (Service | AdminTypeV2)[];
};

const sortAndFilter = <T extends Pathway | Service | AdminTypeV2>(list: T[]) => {
    const result = list.filter(({ enabled }) => enabled);
    return result.sort((a: T, b: T) => a.label.localeCompare(b.label));
};

const getAdminValues = ({
    values,
    setValues,
    adminTypes,
}: {
    values: FormikValues;
    setValues: SetFormikValues | null;
    adminTypes: AdminTypeV2[];
}): GetValuesOutput => {
    const setValuesFn: SetValuesFn = async (value: string) =>
        setValues?.({
            ...values,
            activityType: value,
            duration: getDefaultAdminDuration({
                adminTypes,
                selectedAdminType: value,
            }),
        });

    const defaultPathway: Pathway = {
        label: 'Admin',
        value: 'admin',
        enabled: true,
        services: adminTypes,
    };

    const sortedServices = sortAndFilter(adminTypes);

    return {
        selectedPathwayOverride: defaultPathway,
        setValuesFn,
        sortedServices,
    };
};

const getVisitValues = ({
    values,
    setValues,
    pathways,
}: {
    values: FormikValues;
    setValues: SetFormikValues | null;
    pathways: Pathway[];
}): GetValuesOutput => {
    const setValuesFn: SetValuesFn = async (value: string) =>
        setValues?.({
            ...values,
            disposition: value,
            duration: getDefaultServiceDuration({
                pathways,
                selectedPathway: values.referralPathway,
                selectedService: value,
            }),
        });

    const selectedPathway = pathways.find((pathway) => pathway.value === values.referralPathway);
    const servicesForPathway = selectedPathway?.services ?? [];
    const sortedServices = sortAndFilter(servicesForPathway);

    return {
        selectedPathwayOverride: null,
        setValuesFn,
        sortedServices,
    };
};

const getValues = ({
    values,
    setValues,
    pathways,
    adminTypes,
    isAdmin,
}: {
    values: FormikValues;
    setValues: SetFormikValues | null;
    pathways: Pathway[];
    adminTypes: AdminTypeV2[];
    isAdmin: boolean;
}): GetValuesOutput => {
    if (isAdmin) {
        return getAdminValues({ values, setValues, adminTypes });
    }

    return getVisitValues({ values, setValues, pathways });
};

export const usePathwayServiceSelectViewModel = ({
    values,
    setValues,
}: UsePathwayServiceSelectViewModelProps): UsePathwayServiceSelectViewModelOutput => {
    const {
        RootStore: {
            configStore: { pathways, adminTypes },
        },
    } = useStores<{ RootStore: RootStore }>();

    const isAdmin = isAdminJob(values as Patient);
    const sortedPathways = sortAndFilter(pathways);

    const { selectedPathwayOverride, setValuesFn, sortedServices } = getValues({
        values,
        setValues,
        pathways,
        adminTypes,
        isAdmin,
    });

    const {
        pathwayDisplayLabel,
        serviceOrAdminTypeDisplayLabel,
        serviceOrAdminTypeLabel,
        serviceOrAdminTypeName,
    } = getPathwayServiceOrAdminTypeLabels(values as Patient, pathways, adminTypes);

    return {
        pathways: sortedPathways,
        services: sortedServices,
        pathwayDisplayLabel,
        serviceOrAdminTypeDisplayLabel,
        serviceOrAdminTypeLabel,
        serviceOrAdminTypeName,
        selectedPathwayOverride,
        setValuesFn,
    };
};
