import { Patient } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { IconArrowDropDown, IconArrowDropUp } from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import { TSortValue } from '../../../../../interfaces/sort';
import RootStore from '../../../../../stores/RootStore';
import EmptyListView from './EmptyListView';
import { ListViewRow } from './ListViewRow';
import { IListGenerate, useListViewViewModel } from './useListViewViewModel';

interface ITableHeaderColumnProps {
    key: string;
    headerColumnClassName: string;
    headerColumn: TSortValue;
    sort: (key: string) => void;
    getClassNamesFor: (name: string) => string | undefined;
}

export const TableHeaderColumn: FC<ITableHeaderColumnProps> = ({
    key,
    headerColumnClassName,
    headerColumn,
    sort,
    getClassNamesFor,
}): JSX.Element => {
    const {
        RootStore: {
            configStore: { isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();

    if (!headerColumn.featureFlag || isFeatureEnabled(headerColumn.featureFlag)) {
        return (
            <th
                className={headerColumnClassName}
                key={key}
                onClick={() => sort(headerColumn.columnName)}
            >
                <div className="patient-list__header-flex">
                    <div
                        className={`patient-list__header-text ${getClassNamesFor(
                            headerColumn.columnName,
                        )}`}
                    >
                        {headerColumn.headerName}
                    </div>
                    {headerColumn.sortable && (
                        <span className={getClassNamesFor(headerColumn.columnName)}>
                            <IconArrowDropUp
                                className={'patient-list__header-sort-icon icon-ascending'}
                            />
                            <IconArrowDropDown
                                className={`patient-list__header-sort-icon icon-descending`}
                            />
                        </span>
                    )}
                </div>
            </th>
        );
    }

    return <></>;
};

interface ITableHeaderProps {
    headerKey: string;
    headerRowClassName: string;
    headerColumnClassName: string;
    headerColumns: TSortValue[];
    sort: (key: string) => void;
    getClassNamesFor: (name: string) => string | undefined;
}

export const TableHeader: FC<ITableHeaderProps> = ({
    headerKey,
    headerRowClassName,
    headerColumnClassName,
    headerColumns,
    sort,
    getClassNamesFor,
}): JSX.Element => {
    return (
        <thead>
            <tr className={headerRowClassName}>
                {headerColumns.map((headerColumn) => (
                    <TableHeaderColumn
                        key={`${headerKey}-${headerColumn.columnName}`}
                        headerColumn={headerColumn}
                        headerColumnClassName={headerColumnClassName}
                        sort={sort}
                        getClassNamesFor={getClassNamesFor}
                    />
                ))}
            </tr>
        </thead>
    );
};

export const emptyStateMessage: string =
    'Cannot find any Visits / Admin tasks. Please change date or clear your filters.';

export const headerProperties: TSortValue[] = [
    { headerName: 'NHS number', columnName: 'nhsNumber', sortable: true },
    { headerName: 'Patient name', columnName: 'lastName', sortable: true },
    { headerName: 'Date of birth (Age)', columnName: 'dateOfBirth', sortable: true },
    { headerName: 'Gender', columnName: 'gender', sortable: true },
    { headerName: 'Postcode', columnName: 'postCode', sortable: true },
    { headerName: 'Pathway', columnName: 'referralPathway', sortable: true },
    { headerName: 'Service / activity', columnName: 'disposition', sortable: true },
    { headerName: 'Planned time', columnName: 'startDateTime', sortable: true },
    { headerName: 'Duration', columnName: 'duration', sortable: false },
    {
        headerName: 'Actual start time',
        columnName: 'arrivedDateTime',
        sortable: true,
    },
    {
        headerName: 'Actual end time / cancelled time',
        columnName: 'finishedDateTime',
        sortable: true,
    },
    { headerName: 'Staff', columnName: 'hcpId', sortable: true },
    { headerName: 'Status', columnName: 'jobStatus', sortable: true },
    { headerName: 'Flag', columnName: '', sortable: false },
    { headerName: 'S1 status', columnName: '', sortable: false, featureFlag: 's1Enabled' },
];

export interface IPatientExtended extends Patient {
    hasStaffAlert?: boolean | null;
    hasPatientAlert?: boolean | null;
}

interface IProps {}

const ListView: FC<IProps> = () => {
    const { listOfJobs, getClassNamesFor, listOfDoubleUps, requestSort } = useListViewViewModel();

    // generates the list view per row.
    const GenerateList: FC<IListGenerate> = ({ jobList, doubleUps }): JSX.Element => {
        let jsxArr = jobList.map((job: IPatientExtended, index: number) => {
            return <ListViewRow job={job} index={index} isDoubleUp={doubleUps.includes(job)} />;
        });
        return <>{jsxArr}</>;
    };

    // generates the header if adding or removing a remove please add/remove grid-template-columns on listview.scss
    return (
        <div className="ucr-listview__main">
            <table className="ucr-listview__containerWidth">
                <TableHeader
                    headerKey="listview-header"
                    headerRowClassName="ucr-listview__list-row ucr-listview__list-row--sticky"
                    headerColumns={headerProperties}
                    headerColumnClassName="ucr-listview__header"
                    getClassNamesFor={getClassNamesFor}
                    sort={requestSort}
                />
                {listOfJobs.length > 0 && (
                    <tbody>
                        <GenerateList jobList={listOfJobs} doubleUps={listOfDoubleUps} />
                    </tbody>
                )}
            </table>
            {listOfJobs.length === 0 && <EmptyListView message={emptyStateMessage} />}
        </div>
    );
};

export default observer(ListView);
