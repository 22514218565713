import { useQuery } from '@apollo/client';
import {
    ConsentStatus,
    JobStatus,
    Vaccination,
    VaccinationCategory,
    VaccineManufacturer,
} from '@doc-abode/data-models';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { convertFiltersToV2 } from '../components/modules/helpers/convertFiltersToV2';
import { filterableJobStatuses } from '../components/pages/vaccinations/patients/filterOptions';
import filterBuilder from '../graphql/filterBuilder';
import { QUERY_JOBS_BY_JOB_TYPE } from '../graphql/queries/jobs';
import RootStore from '../stores/RootStore';
import { useJobDictionary } from './useJobDictionary';
import useStores from './useStores';

export type VaccinationFilter = {
    jobStatus?: JobStatus[];
    hubId?: string[];
    practice?: string[];
    flags?: { contains: string }[];
    vaccinationCategory?: VaccinationCategory[];
    doseNumber?: string[];
    vaccineManufacturer?: VaccineManufacturer[];
    consented?: ConsentStatus[];
    dateOfBirth?: { ge: string; le: string }[];
    dateOfPreviousDose?: {
        spread: {
            and: {
                doseNumber?: { eq: number };
                and?: {
                    dateOfDose1: { ge?: string; le?: string };
                }[];
            }[];
        }[];
    };
};

export type Variables = {
    jobType: string;
    filter: Record<string, any>;
    search?: string;
};

const variables: Variables = {
    jobType: 'vaccination',
    filter: {
        jobStatus: filterableJobStatuses,
    },
};

interface IOverrides {
    filters: { jobStatus?: JobStatus[] };
    sort: '';
}

export type TPatientsContext = {
    selectedPatientIds: string[];
    filters: any;
    overrides: IOverrides;
    search: string;
    sortKey: string;
    sortDirection: string;
    page: number;
    setSelectedPatientIds: (
        selectedPatientIds: string[] | ((selectedPatientIds: string[]) => string[]),
    ) => void;
    setOverrides: (overrides: any) => void;
    setSearch: (search: string) => void;
    setPage: (page: number) => void;
    patients: Vaccination[];
    selectedPatients: Vaccination[];
    select: (id: string) => void;
    deselect: (id: string) => void;
    selectAll: () => void;
    deselectAll: () => void;
    nextToken?: string;
    getPatients: any;
    performSort: (a: any, b: any) => number;
    onClearSearch: () => void;
    updateFilters: (nextFilters?: any) => void;
    onOverrideJobStatus: (checked: boolean, jobStatus: string) => void;
    changeSortKey: (key: string) => void;
    practiceOptions: any[];
};

const context: TPatientsContext = {
    selectedPatientIds: [],
    filters: {},
    overrides: { filters: {}, sort: '' },
    search: '',
    sortKey: 'lastName',
    sortDirection: 'desc',
    page: 1,
    patients: [],
    selectedPatients: [],
    getPatients: {},
    practiceOptions: [],
    setSelectedPatientIds: () => {},
    setOverrides: () => {},
    setSearch: () => {},
    setPage: () => {},
    select: () => {},
    deselect: () => {},
    selectAll: () => {},
    deselectAll: () => {},
    performSort: (a, b) => 1,
    onClearSearch: () => {},
    updateFilters: () => {},
    onOverrideJobStatus: () => {},
    changeSortKey: () => {},
};

export const PatientsContext = createContext(context);

export function PatientsContextState(): TPatientsContext {
    const [selectedPatientIds, setSelectedPatientIds] = useState(context.selectedPatientIds);
    const [filters, setFilters] = useState<VaccinationFilter>(context.filters);
    const [overrides, setOverrides] = useState(context.overrides);
    const [search, setSearch] = useState(context.search);
    const [sortKey, setSortKey] = useState(context.sortKey);
    const [sortDirection, setSortDirection] = useState(context.sortDirection);
    const [page, setPage] = useState(context.page);
    const [practiceOptions, setPracticeOptions] = useState(context.practiceOptions);

    const {
        RootStore: { configStore },
    } = useStores<{ RootStore: RootStore }>();

    const getPatients = useQuery(QUERY_JOBS_BY_JOB_TYPE, {
        variables: {
            ...variables,
            filter: filterBuilder(variables.filter),
            v2Filter: JSON.stringify(convertFiltersToV2(variables.filter)),
        },
        pollInterval: 60000,
        notifyOnNetworkStatusChange: true,
    });

    const patients = useMemo(
        () =>
            (getPatients.data?.queryJobsByJobTypeIndex?.items || []).map(
                (job: Vaccination) => new Vaccination(job),
            ) as Vaccination[],
        [getPatients.data],
    );

    const selectedPatients = useMemo(
        () =>
            selectedPatientIds
                .map((id: string) => {
                    const patient = patients.find((patient) => patient.id === id);
                    return patient ? new Vaccination(patient) : null;
                })
                .filter((_) => _) as Vaccination[],
        [patients, selectedPatientIds],
    );

    useEffect(() => {
        setSelectedPatientIds((selectedPatientIds) =>
            selectedPatientIds.filter((id) => patients.some((patient) => patient.id === id)),
        );
        if (practiceOptions.length === 0) {
            const practiceValues = Array.from(
                new Set(
                    patients.filter(({ practice }) => practice).map((patient) => patient.practice),
                ),
            );
            setPracticeOptions(
                practiceValues.map((value) => ({
                    label: value,
                    value: value,
                })),
            );
        }
    }, [patients, practiceOptions.length]);

    const { jobDictionary } = useJobDictionary();

    useEffect(() => {
        if (jobDictionary) {
            configStore.setJobDictionary(jobDictionary);
        }
    }, [jobDictionary, configStore]);

    const select = (id: string) =>
        setSelectedPatientIds((selectedPatientIds) => [...selectedPatientIds, id]);

    const deselect = (id: string) => {
        setSelectedPatientIds((selectedPatientIds: string[]) =>
            selectedPatientIds.filter((jobId) => jobId !== id),
        );
    };

    const deselectAll = () => setSelectedPatientIds([]);

    const selectAll = () => {
        patients
            .filter(({ selectable }) => selectable)
            .forEach(({ id }) => {
                if (!selectedPatientIds.includes(id)) {
                    setSelectedPatientIds((selectedPatientIds) => [...selectedPatientIds, id]);
                }
            });
    };

    const { nextToken } = getPatients.data?.queryJobsByJobTypeIndex || {};

    const sortTransforms: { [key: string]: any } = {
        hubId: (hubId: string) => configStore.getHubName(hubId),
    };

    const performSort = (a: any, b: any) => {
        const key = overrides.sort || sortKey;

        let firstItem = String(a[key]).toLowerCase();
        let secondItem = String(b[key]).toLowerCase();

        const sortTransform = sortTransforms[key];

        if (sortTransform) {
            firstItem = sortTransform(firstItem);
            secondItem = sortTransform(secondItem);
        }

        if (firstItem < secondItem) {
            return sortDirection === 'desc' ? -1 : 1;
        }

        return sortDirection === 'desc' ? 1 : -1;
    };

    const changeSortKey = (key: string) => {
        if (key !== sortKey) {
            setSortKey(key);
        } else {
            const direction = sortDirection === 'desc' ? 'asc' : 'desc';
            setSortDirection(direction);
        }
    };

    const updateFilters = ({
        nextFilters,
        nextSearch,
        nextOverrides,
    }: {
        nextFilters?: VaccinationFilter;
        nextSearch?: string;
        nextOverrides?: VaccinationFilter;
    } = {}) => {
        if (nextFilters) {
            setFilters(nextFilters);
        }

        const combinedFilters: VaccinationFilter = {
            ...variables.filter,
            ...(nextFilters || filters),
            ...(nextOverrides || overrides.filters),
        };

        getPatients.refetch({
            ...variables,
            filter: filterBuilder(combinedFilters),
            v2Filter: JSON.stringify(convertFiltersToV2(combinedFilters)),
            search: nextSearch !== undefined ? nextSearch : search,
        });

        setPage(1);
    };

    const onClearSearch = () => {
        setSearch('');
        updateFilters({ nextSearch: '' });
    };

    const onOverrideJobStatus = (checked: boolean, jobStatus: string) => {
        const nextOverrides: any = { ...overrides.filters };

        if (checked) {
            nextOverrides.jobStatus = [jobStatus];
        } else {
            delete nextOverrides.jobStatus;
        }

        setOverrides({ ...overrides, filters: nextOverrides });
        updateFilters({ nextOverrides });
    };

    return {
        selectedPatientIds,
        setSelectedPatientIds,
        patients,
        selectedPatients,
        select,
        deselect,
        selectAll,
        deselectAll,
        nextToken,
        getPatients,
        overrides,
        setOverrides,
        performSort,
        setSearch,
        search,
        onClearSearch,
        filters,
        updateFilters,
        onOverrideJobStatus,
        changeSortKey,
        sortKey,
        sortDirection,
        page,
        setPage,
        practiceOptions,
    };
}

export default function usePatientsContext() {
    return useContext(PatientsContext);
}
