import { Radio, RadioGroup } from '@blueprintjs/core';
import { JobStatus, Patient } from '@doc-abode/data-models';
import { Form, FormikContextType, FormikErrors, useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { FC, FormEvent, useCallback, useEffect, useState } from 'react';

import { dateTimeFormat } from '../../../../../constants/patientsConst';
import { getMomentDateFormatter } from '../../../../../helpers/ucr';
import { getFormattedHcpUserForRadioLabelChooser } from '../../../../../helpers/ucr/getFormattedHcpUserForRadioLabelChooser';
import { isMultiAssigneeJob } from '../../../../../helpers/ucr/isMultiAssigneeJob';
import useStores from '../../../../../hook/useStores';
import { IHcp } from '../../../../../interfaces/ucr';
import RootStore from '../../../../../stores/RootStore';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../v2/components';
import { DateInput, Select, TextArea } from '../../../../v2/form';
import { WarningBanner } from '../../../../v2/form/WarningBanner';
import { useView } from '../../views/useView';
import {
    focusedUserHasAnotherJobInProgress,
    getActiveJobsForHcp,
    getHcpHasActiveJob,
    getIsUser1,
    getPropertyNames,
    getShowActualStartTime,
    getShowTimeMarkedAsCurrent,
    getValidationWarnings,
    getValidJobStatusTransitions,
    IDateTimeValues,
    IHcpResult,
    isConfirmButtonDisabled,
    IValidJobStatusTransitions,
    jobStatusCanBeChange,
} from './changeVisitStatusHelpers';
import { IChangeStatusFormFormikValues } from './changeVisitStatusTypes';

export interface IGetUserSessionAndActiveJobs {
    getAuthToken: () => Promise<any>;
    buddyId: string | null | undefined;
    hcpId: string | null | undefined;
    setBuddyResults: (iHcpResult: IHcpResult) => void;
    setHcpResults: (iHcpResult: IHcpResult) => void;
    setApiLoaded: (value: boolean) => void;
}

/**
 * logic extracted from component to make testing easier
 */
export async function getUserSessionAndActiveJobs({
    getAuthToken,
    buddyId,
    hcpId,
    setBuddyResults,
    setHcpResults,
    setApiLoaded,
}: IGetUserSessionAndActiveJobs): Promise<void> {
    const token = await getAuthToken();
    if (token) {
        const buddyActiveJobs = getActiveJobsForHcp({ hcpId: buddyId, token });
        const hcpActiveJobs = getActiveJobsForHcp({ hcpId: hcpId, token });

        const results: IHcpResult[] = await Promise.all([buddyActiveJobs, hcpActiveJobs]);
        setBuddyResults(results[0]);
        setHcpResults(results[1]);
        setApiLoaded(true);
    }
}

export interface IChangeVisitStatusFormProps {
    visit?: Patient;
    loading: boolean;
    onClose: () => void;
    errors: FormikErrors<IChangeStatusFormFormikValues>;
}

const ChangeVisitStatusForm: FC<IChangeVisitStatusFormProps> = ({
    visit,
    loading,
    onClose,
    errors,
}) => {
    const {
        RootStore: {
            ucrStore: { focusedUser, focusedJobId },
            userStore: { getAuthToken },
            usersStore: { hcpUsers },
            configStore: { isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();
    const { openDeepLink } = useView();
    const [init, setInit] = useState(false);
    const [apiLoaded, setApiLoaded] = useState<boolean>(false);
    const [validStates, setValidStates] = useState<IValidJobStatusTransitions[]>([]);
    const [hcpResults, setHcpResults] = useState<IHcpResult>({
        current: 0,
        arrived: 0,
        pass: false,
    });
    const [buddyResults, setBuddyResults] = useState<IHcpResult>({
        current: 0,
        arrived: 0,
        pass: false,
    });

    const { values, setFieldValue }: FormikContextType<IChangeStatusFormFormikValues> =
        useFormikContext();
    const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
    const [showPostVisitNote, setShowPostVisitNote] = useState(false);
    const isPostVisitNoteRequired = isFeatureEnabled('postVisitNotesMandatory');
    const isDoubleUp = isMultiAssigneeJob(visit);

    const isHcp1 = getIsUser1({
        isDoubleUp,
        focusedUser,
    });
    const propertyNames = getPropertyNames({ isHcp1 });
    // CHANGE HCP
    const onChangeFocusedUser = useCallback(
        (formEvent: FormEvent<HTMLInputElement>) => {
            openDeepLink(focusedJobId, formEvent.currentTarget.value);
        },
        [focusedJobId, openDeepLink],
    );
    // CHECK API FOR ACTIVE JOBS FOR THE HCP's
    useEffect(() => {
        if (!init) {
            setInit(true);
            // do any of the Hcp's have an active job.
            getUserSessionAndActiveJobs({
                getAuthToken,
                hcpId: visit?.hcpId,
                buddyId: visit?.buddyId,
                setBuddyResults,
                setHcpResults,
                setApiLoaded,
            });
        }
    }, [init, getAuthToken, visit?.buddyId, visit?.hcpId]);

    // SHOW post visit note.
    useEffect(() => {
        const previousJobStatus = isHcp1 ? visit?.jobStatus : visit?.buddyJobStatus;

        setShowPostVisitNote(
            Boolean(
                values.newJobStatus &&
                    (values.newJobStatus === JobStatus.COMPLETED ||
                        (values.newJobStatus === JobStatus.ARRIVED &&
                            previousJobStatus === JobStatus.COMPLETED)),
            ),
        );
    }, [isHcp1, setFieldValue, values.newJobStatus, visit?.buddyJobStatus, visit?.jobStatus]);

    // CHANGE DATE TIME WARNINGS
    useEffect(() => {
        const propertyNames = getPropertyNames({ isHcp1 });
        const dateTimeValues: IDateTimeValues = {
            arrivedDateTime: values[propertyNames.arrivedDateTime],
            finishedDateTime: values[propertyNames.finishedDateTime],
            madeCurrentDateTime: values[propertyNames.madeCurrentDateTime],
        };

        let updatedValidationWarnings = getValidationWarnings({
            newJobStatus: values.newJobStatus,
            statusHasChanged: Boolean(values.newJobStatus),
            dateTimeValues,
        });

        setValidationWarnings(updatedValidationWarnings);
    }, [
        isHcp1,
        values,
        values.newJobStatus,
        values.arrivedDateTime,
        values.madeCurrentDateTime,
        values.finishedDateTime,
        values.buddyMadeCurrentDateTime,
        values.buddyArrivedDateTime,
        values.buddyFinishedDateTime,
        setValidationWarnings,
    ]);

    // VALID STATUS THAT THE CURRENT JOB STATUS CAN CHANGE TO.
    useEffect(() => {
        let currentJobStatus;
        if (visit) {
            currentJobStatus = visit[propertyNames.jobStatus];
        }
        // Selected user already has an active job, when that is the case we need to restrict the list of available states
        const hasAnotherJobInProgress = focusedUserHasAnotherJobInProgress({
            isHcp1,
            hcpResults,
            currentJobStatus,
            buddyResults,
        });
        let newValidStates: IValidJobStatusTransitions[] = [];
        if (currentJobStatus) {
            newValidStates = getValidJobStatusTransitions({
                currentJobStatus,
                hasAnotherJobInProgress,
            });
        }
        setValidStates(newValidStates);
    }, [apiLoaded, buddyResults, isHcp1, hcpResults, propertyNames.jobStatus, visit]);

    // GET THE HPC's
    const hcp1 = hcpUsers.find((hcp: IHcp) => hcp.userId === visit?.hcpId);
    const hcp2 = hcpUsers.find((hcp: IHcp) => hcp.userId === visit?.buddyId);

    const isJobStatusCanBeChange = jobStatusCanBeChange({ jobStatus: visit?.jobStatus });
    const isBuddyStatusCanBeChange = jobStatusCanBeChange({ jobStatus: visit?.buddyJobStatus });

    const showActualStartTime: boolean = getShowActualStartTime({
        newJobStatus: values.newJobStatus,
    });
    const showActualEndTime = values.newJobStatus === JobStatus.COMPLETED;
    // SETTING POST VISIT NOTES FOR CORRECT HCP
    useEffect(() => {
        const propertyNames = getPropertyNames({ isHcp1 });
        if (showPostVisitNote) {
            let postVisitNotesValue = '';
            if (visit) {
                postVisitNotesValue = visit[propertyNames.postVisitNotes] || postVisitNotesValue;
            }
            setFieldValue('postVisitNotes', postVisitNotesValue);
        }
    }, [
        showPostVisitNote,
        isHcp1,
        setFieldValue,
        visit?.postVisitNotes,
        visit?.postVisitNotesBuddy,
        visit,
    ]);
    if (!visit) {
        return null;
    }

    const showRadioGroup = isDoubleUp;
    const showStateChangeDisabledWarning = getHcpHasActiveJob({
        isHcp1,
        patientVisit: visit,
        hcpResults,
        buddyResults,
    });
    const showTimeMarkedAsCurrent = getShowTimeMarkedAsCurrent({
        changedStatus: values?.newJobStatus,
    });
    const timeInputFormat = getMomentDateFormatter(dateTimeFormat);
    const madeCurrentDateTimeDefault = values[
        propertyNames.madeCurrentDateTime as keyof IChangeStatusFormFormikValues
    ] as Date | undefined | null;

    const arrivedDateTimeDefaultValue = values[
        propertyNames.arrivedDateTime as keyof IChangeStatusFormFormikValues
    ] as Date | undefined | null;

    const finishedDateTimeDefaultValue = values[
        propertyNames.finishedDateTime as keyof IChangeStatusFormFormikValues
    ] as Date | undefined | null;

    const confirmButtonDisabled = isConfirmButtonDisabled({
        loading,
        postVisitNotesMandatory: isPostVisitNoteRequired,
        values,
        validationWarnings,
    });
    let selectHcpSelectedValue = 'user1';
    if (!isHcp1) {
        selectHcpSelectedValue = 'user2';
    }

    return (
        <Form>
            <div className="v2__dialog-block v2__dialog-block--no-margin">
                {/* SELECT HCP */}
                <ConditionalDisplay show={showRadioGroup}>
                    <div className="v2__dialog-radiobuttons-group-container">
                        <p>
                            This is a double-up. Please select the HCP for whom you would like to
                            change the status.
                        </p>
                        <RadioGroup
                            className="v2__dialog-radiobuttons-group"
                            selectedValue={selectHcpSelectedValue}
                            onChange={onChangeFocusedUser}
                        >
                            <Radio
                                label={getFormattedHcpUserForRadioLabelChooser(visit, hcp1, 1)}
                                value="user1"
                                // job status cannot be changed OR we don't have a hcpId
                                disabled={!isJobStatusCanBeChange || !visit.hcpId}
                            />
                            <Radio
                                label={getFormattedHcpUserForRadioLabelChooser(visit, hcp2, 2)}
                                data-test="radioUser2"
                                value="user2"
                                disabled={!isBuddyStatusCanBeChange || !visit.buddyId}
                            />
                        </RadioGroup>
                    </div>
                </ConditionalDisplay>
            </div>
            {/* STATE CHANGE WARNING*/}
            <ConditionalDisplay show={showStateChangeDisabledWarning}>
                <p>Some state changes have been disabled as the HCP already has an active job</p>
            </ConditionalDisplay>
            <div className="v2__dialog-block">
                <Select
                    label="Please select the new job status"
                    // this value is used as a selector in our cypress end-to-end tests
                    // if you change it you will need to update them in (at time of writing just)
                    // cypress/support/page_object/components/changeVisit.ts
                    name="newJobStatus"
                    options={validStates}
                    withEmptyOption
                />
            </div>
            {/* START AND FINISH TIMES */}
            <div className="v2__dialog-setTime-block">
                <ConditionalDisplay show={showTimeMarkedAsCurrent}>
                    <div className="v2__dialog-time-input-block">
                        <p className="v2__dialog-setTime-text">Time marked as current</p>
                        <DateInput
                            {...timeInputFormat}
                            timePrecision={'minute'}
                            name={propertyNames.madeCurrentDateTime}
                            className="v2__form-group--pos-2-3" // not sure what the class should be, not sure if it matters
                            defaultValue={madeCurrentDateTimeDefault?.toISOString()}
                            required
                            disabled={loading}
                        />
                    </div>
                </ConditionalDisplay>
                <ConditionalDisplay show={showActualStartTime}>
                    <div className="v2__dialog-time-input-block">
                        <p className="v2__dialog-setTime-text">Actual start time</p>
                        <DateInput
                            {...timeInputFormat}
                            timePrecision={'minute'}
                            name={propertyNames.arrivedDateTime}
                            className="v2__form-group--pos-1-3"
                            defaultValue={arrivedDateTimeDefaultValue?.toISOString()}
                            //selectAllOnFocus
                            required
                            disabled={loading}
                        />
                    </div>
                    <ConditionalDisplay show={showActualEndTime}>
                        <div className="v2__dialog-time-input-block">
                            <p className="v2__dialog-setTime-text">Actual end time</p>
                            <DateInput
                                {...timeInputFormat}
                                timePrecision={'minute'}
                                name={propertyNames.finishedDateTime}
                                className="v2__form-group--pos-2-3"
                                defaultValue={finishedDateTimeDefaultValue?.toISOString()}
                                //selectAllOnFocus
                                required
                                disabled={loading}
                            />
                        </div>
                    </ConditionalDisplay>
                </ConditionalDisplay>
            </div>
            {/* POST VISIT NOTES */}
            <ConditionalDisplay show={showPostVisitNote}>
                <div className="v2__dialog-block">
                    <TextArea
                        name="postVisitNotes"
                        required={isPostVisitNoteRequired}
                        label={`Please add post-job notes ${
                            isPostVisitNoteRequired ? '' : '(optional)'
                        }`}
                        disabled={loading}
                    />
                </div>
            </ConditionalDisplay>
            {/* WARNINGS */}
            <WarningBanner spacerTop={true} compact={true} simpleWarnings={validationWarnings} />
            {/* BUTTONS */}
            <div className="v2__dialog-change-visit-status-buttons-container">
                <Button
                    name="Cancel"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    color={ButtonColors.RED}
                    disabled={loading}
                    clickEvent={onClose}
                    className="v2__dialog-change-visit-status-button"
                />
                <Button
                    name="Confirm"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    type="submit"
                    className="v2__dialog-change-visit-status-button"
                    disabled={confirmButtonDisabled}
                />
            </div>
        </Form>
    );
};

export default observer(ChangeVisitStatusForm);
