import { Button, ButtonGroup, Switch } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { match, useHistory, useLocation } from 'react-router';

import { PatientsContext } from '../../../hook/usePatientsContext';
import { ConditionalDisplay } from '../../CondtionalDisplay';
import Loader from '../../modules/helpers/Loader';
import Filters from '../../modules/jobs/list/Filters';
import { RouteDetails, RoutesTable, UpdateRoute } from './components';
import { useRoutesModel } from './useRoutesModel';
import { routeStatusMappings } from './utils';
import CreateRoutes from './views/CreateRoutes/CreateRoutes';

const filterOptions = [
    {
        name: 'jobStatus',
        label: 'Status',
        options: [
            'AVAILABLE',
            'ACCEPTED',
            'CURRENT',
            'ARRIVED',
            'COMPLETED',
            'HCP_ABORTED',
            'WITHDRAWN',
        ],
        labelTransform: (option: string) => routeStatusMappings[option],
    },
];

export type THistory<T = unknown> = ReturnType<typeof useHistory<T>>;
export type TLocation<T = unknown> = ReturnType<typeof useLocation<T>>;

export interface RoutesProps {
    location: TLocation<{ closeModal: boolean }>;
    history: THistory;
    routeMatch: match<{ jobId?: string }> | null;
}

const Routes: FC<RoutesProps> = ({ location, history, routeMatch }) => {
    const {
        loading,
        pagedJobs,
        pageStart,
        pageEnd,
        pageCount,
        routesPage,
        filters,
        setFilters,
        totalJobsNumber,
        showAbortedByController,
        toggleShowAbortedByController,
        getUserSession,
        isSuperuser,
        getAuthToken,
        hubs,
        clientKeys,
        vaccinationDuration,
        vaccinationDetails,
        doseInterval,
        hcpType,
        showUpdateRoute,
        showCreateRoutes,
        showRouteDetails,
        handleShowRouteDetails,
        handleCloseModal,
        handleSort,
        setUrlPage,
        pathname,
        jobId,
        refetch: refetchRoutes,
    } = useRoutesModel({ location, history, routeMatch });

    return (
        <>
            <nav className="vaccinations__navigation">
                {!loading && (
                    <>
                        Showing routes {totalJobsNumber > 0 ? pageStart + 1 : 0} -{' '}
                        {pageEnd < totalJobsNumber ? pageEnd : totalJobsNumber} of {totalJobsNumber}
                        <ButtonGroup className="vaccinations__pager" minimal>
                            <Button
                                icon="chevron-left"
                                text="Previous"
                                disabled={routesPage === 0}
                                intent="primary"
                                onClick={() => setUrlPage(routesPage - 1)}
                            />
                            <Button
                                rightIcon="chevron-right"
                                text="Next"
                                disabled={routesPage === pageCount - 1}
                                intent="primary"
                                onClick={() => setUrlPage(routesPage + 1)}
                            />
                        </ButtonGroup>
                    </>
                )}
            </nav>
            <aside className="vaccinations__tools">
                <h3 className="h3">Filters</h3>
                <Filters filterOptions={filterOptions} filters={filters} setFilters={setFilters} />
                <h3 className="h3">Mode</h3>
                <Switch
                    checked={showAbortedByController}
                    onChange={() => {
                        toggleShowAbortedByController();
                    }}
                    alignIndicator="right"
                    className={showAbortedByController ? '' : 'filter__label--any'}
                >
                    Controller aborted routes
                </Switch>
            </aside>
            <main className="vaccinations__main">
                {loading ? (
                    <Loader fullscreen={false} />
                ) : (
                    <RoutesTable
                        jobs={pagedJobs}
                        handleOnClick={handleShowRouteDetails}
                        hubs={hubs}
                        onSort={handleSort}
                    />
                )}
            </main>
            <PatientsContext.Consumer>
                {({
                    getPatients: { refetch: setVaccinations },
                    patients: allPatients,
                    selectedPatients: selectedJobs,
                    select,
                    deselect,
                    deselectAll,
                }) => (
                    <>
                        <ConditionalDisplay show={showCreateRoutes}>
                            <div className="vaccinations__modal">
                                <CreateRoutes
                                    onClose={handleCloseModal}
                                    jobs={selectedJobs}
                                    getUserSession={getUserSession}
                                    refetchRoutes={refetchRoutes}
                                    deselect={deselect}
                                    setVaccinations={setVaccinations}
                                    hubs={hubs}
                                    apiKey={clientKeys.googleMaps}
                                    vaccinationDuration={vaccinationDuration}
                                    vaccinationDetails={vaccinationDetails}
                                    hcpType={hcpType}
                                    isSuperuser={isSuperuser}
                                    doseInterval={doseInterval}
                                />
                            </div>
                        </ConditionalDisplay>
                        <ConditionalDisplay show={showUpdateRoute}>
                            <div className="vaccinations__modal">
                                {/* VSU-2078 Looks like you cannot remove/withdraw a patient and recalculate
                                is that because you must first remove/withdraw a patient and then trigger a separate recalculate
                                or are they just mutually exclusive?  UpdateRoute does not seem to trigger a recalculate after a remove/withdraw */}
                                <UpdateRoute
                                    onClose={handleCloseModal}
                                    patientsToAdd={selectedJobs}
                                    deselect={deselect}
                                    setVaccinations={setVaccinations}
                                    allPatients={allPatients}
                                    addPatients={pathname.endsWith('/add-to-existing')}
                                    onSort={handleSort}
                                    recalculateRoute={pathname.endsWith('/recalculate')}
                                    refetchRoutes={refetchRoutes}
                                />
                            </div>
                        </ConditionalDisplay>
                        <ConditionalDisplay show={showRouteDetails}>
                            <div className="vaccinations__modal">
                                <RouteDetails
                                    onClose={handleCloseModal}
                                    getAuthToken={getAuthToken}
                                    refetchRoutes={refetchRoutes}
                                    setVaccinations={setVaccinations}
                                    hubs={hubs}
                                    jobId={jobId as string}
                                    select={select}
                                    deselectAll={deselectAll}
                                    apiKey={clientKeys.googleMaps}
                                    isSuperuser={isSuperuser}
                                />
                            </div>
                        </ConditionalDisplay>
                    </>
                )}
            </PatientsContext.Consumer>
        </>
    );
};

export default observer(Routes);
