import cn from 'classnames';
import { FC, useState } from 'react';

import { IconNavigateNext, IconNavigatePrev } from '../../../../../helpers/ucr/icons';

interface IProps {
    panel: string;
    isReverse?: boolean;
    isDisabled?: boolean;
    clickEvent: () => void;
}

const Arrow: FC<IProps> = ({ panel, isReverse, isDisabled, clickEvent }) => {
    const [isHover, setHover] = useState<boolean>(false);

    const onMouseEnter = () => setHover(true);
    const onMouseLeave = () => setHover(false);

    const Icon: FC = panel === 'right' ? IconNavigatePrev : IconNavigateNext;

    return (
        <button
            type="button"
            className={cn('ucr__side-arrow', {
                'ucr__side-arrow--reverse': isReverse,
                'ucr__side-arrow--forward': !isReverse,
                'ucr__side-arrow--hovered': isHover,
                'ucr__side-arrow--default': !isHover,
                'ucr__side-arrow--disabled': isDisabled,
            })}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={clickEvent}
        >
            <Icon />
        </button>
    );
};

export default Arrow;
