import { action, makeObservable, observable } from 'mobx';

import { fetchLovs } from '../api/lovsApi';

class LovsStore {
    constructor(rootStore) {
        makeObservable(this, {
            hcpType: observable,
            gender: observable,
            genderStaffPreferred: observable,
            jobType: observable,
            disposition: observable,
            languagesSpoken: observable,
            speciality: observable,
            controllerReverseReason: observable,
            controllerPatientAlertResolutionReason: observable,
            controllerStaffAlertResolutionReason: observable,
            nominatedHcps: observable,
            referrer: observable,
            availabilityTimeSettings: observable,
            controllerAdminAbortReason: observable,
            getLovs: action,
            setLovs: action,
            setLov: action,
        });

        this.rootStore = rootStore;
    }

    hcpType = [];
    gender = [];
    genderStaffPreferred = [];
    jobType = [];
    disposition = [];
    languagesSpoken = [];
    speciality = [];
    controllerReverseReason = [];
    controllerPatientAlertResolutionReason = [];
    controllerStaffAlertResolutionReason = [];
    nominatedHcps = [];
    referrer = [];
    // powers the selectors for the dropdown menus.
    availabilityTimeSettings = [];
    managementDay = [];
    managementDayNullValue = { label: 'Unknown', value: 'unknown' };
    controllerAdminAbortReason = [];

    getLovs = () => {
        const lovs = fetchLovs();
        return lovs;
    };

    setLovs = () => {
        const lovs = this.getLovs();

        for (let property in lovs) {
            if (lovs.hasOwnProperty(property)) {
                this.setLov(property, lovs[property]);
            }
        }
    };

    setLov = (lov, value) => {
        this[lov] = value;
    };
}

export default LovsStore;
