import { Service } from '@doc-abode/data-models';
import React from 'react';

import { Select } from '../../../../v2/form';
import { SetValuesFn } from './usePathwayServiceSelectViewModel';

export interface ServiceSelectProps {
    setValues: SetValuesFn;
    setRandomTimepickerKey: () => void;
    services: Service[];
    loading: boolean;
    isContinueBtnClicked: boolean;
    name: string;
    label: string;
}

export const ServiceSelect = ({
    loading,
    isContinueBtnClicked,
    setValues,
    setRandomTimepickerKey,
    services,
    name,
    label,
}: ServiceSelectProps) => {
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValues(e.target.value);
        setRandomTimepickerKey();
    };

    return (
        <Select
            data-testid={name}
            name={name}
            className="v2__form-group--pos-2-2"
            label={label}
            required
            disabled={loading}
            isContinueBtnClicked={isContinueBtnClicked}
            onChange={onChange}
        >
            <option value="">Select...</option>
            {services.map((service) => (
                <option key={service.value} value={service.value}>
                    {service.label}
                </option>
            ))}
        </Select>
    );
};
