import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { GetJobDictionaryDocument, GetJobDictionaryQuery } from '../__generated__/v2';

export function useJobDictionary() {
    const { data, error } = useQuery<GetJobDictionaryQuery>(GetJobDictionaryDocument);

    const jobDictionary = useMemo(() => {
        return data?.getJobDictionary;
    }, [data?.getJobDictionary]);

    return { jobDictionary, error };
}
