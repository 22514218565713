import { JobStatus } from '@doc-abode/data-models';

import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';

interface IProps {
    routeStatus?: JobStatus;
}

export const useRouteHcpListViewModel = ({ routeStatus }: IProps) => {
    const {
        RootStore: {
            usersStore: { hcpUsers, getEnabledUsers },
        },
    } = useStores<{ RootStore: RootStore }>();

    // Return enabled HCPs only, in alphanumeric sorted order
    const enabledHcpUsers = getEnabledUsers(hcpUsers).sort((hcp1, hcp2) =>
        hcp1.userName.localeCompare(hcp2.userName),
    );

    const areHcpsSelectable = routeStatus
        ? ![
              JobStatus.CURRENT,
              JobStatus.ARRIVED,
              JobStatus.COMPLETED,
              JobStatus.CONTROLLER_ABORTED,
          ].includes(routeStatus)
        : true;

    return {
        areHcpsSelectable,
        enabledHcpUsers,
    };
};
