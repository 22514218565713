import { Patient } from '@doc-abode/data-models';
import { convertArrayToConcatenatedString } from '@doc-abode/helpers';
import { observer } from 'mobx-react';
import { FC, Fragment } from 'react';

import { genderMapping } from '../../../../../../constants/mainConst';
import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import { formatDisplayDate } from '../../../../../modules/helpers/formatData';
import Info from '../../../../../v2/components/Info';
import InfoItem from '../../../../../v2/components/InfoItem';

type IProps = {
    job: Patient;
};

const InfoPanelPatientSection: FC<IProps> = ({ job }) => {
    const {
        addressLine1,
        addressLine2,
        addressLine3,
        firstName,
        middleName,
        lastName,
        town,
        postCode,
        age,
        dateOfBirth,
        gender,
        nhsNumber,
        contactNumber,
        additionalContactNumbers,
        languagesSpoken,
    } = job;

    const contactNumbers = [contactNumber, ...(additionalContactNumbers || [])].filter((_) => _);

    const isNotRelatedToPatient =
        !firstName &&
        !middleName &&
        !lastName &&
        !gender &&
        !nhsNumber &&
        !contactNumber &&
        !additionalContactNumbers?.length &&
        !addressLine1 &&
        !addressLine2 &&
        !addressLine3 &&
        !town &&
        !postCode &&
        !languagesSpoken?.length;

    return (
        <div className="visit-details__patient">
            <ConditionalDisplay show={isNotRelatedToPatient}>
                <Info title="The job is not related to a patient">
                    <></>
                </Info>
            </ConditionalDisplay>
            <ConditionalDisplay show={!isNotRelatedToPatient}>
                <Info>
                    <InfoItem label="Address" alignSelf="start">
                        {[addressLine1, addressLine2, addressLine3, town, postCode]
                            .filter((_) => _)
                            .map((addressPart, i) => (
                                <Fragment key={i}>
                                    {i !== 0 && <br />}
                                    {addressPart}
                                </Fragment>
                            ))}
                    </InfoItem>
                    <InfoItem label="Date of birth">
                        {dateOfBirth ? formatDisplayDate(dateOfBirth) : dateOfBirth}
                    </InfoItem>
                    <InfoItem label="Age">{String(age)}</InfoItem>
                    <InfoItem label="Gender">
                        {gender ? genderMapping[gender] : genderMapping.not_provided}
                    </InfoItem>
                    <InfoItem label="NHS number">{nhsNumber}</InfoItem>
                    <InfoItem label="Contact number(s)" alignSelf="start">
                        {contactNumbers.length > 0
                            ? contactNumbers.map((number, i) => (
                                  <Fragment key={i}>
                                      {i !== 0 && <br />}
                                      {`+${number}`}
                                  </Fragment>
                              ))
                            : null}
                    </InfoItem>
                    <InfoItem label="Language(s) spoken">
                        {convertArrayToConcatenatedString(languagesSpoken, ', ')}
                    </InfoItem>
                </Info>
            </ConditionalDisplay>
        </div>
    );
};

export default observer(InfoPanelPatientSection);
