import cn from 'classnames';
import { FC, ReactNode } from 'react';

interface IProps {
    label?: ReactNode;
    Icon?: any;
    children: ReactNode;
    intent?: 'success' | 'warning' | 'danger';
    alignSelf?: 'start' | 'end' | 'center';
    customClassName?: string;
    onClick?: () => void;
    testId?: string;
}

const InfoItem: FC<IProps> = ({
    label,
    Icon,
    children,
    intent,
    alignSelf,
    customClassName,
    onClick,
    testId,
}) => {
    const IconlabelComponent = (
        <span>
            {Icon ? (
                <span className="job-summary__hcp-double-up">
                    <Icon
                        className="job-summary__double-up-icon"
                        style={{ verticalAlign: 'middle' }}
                    />
                </span>
            ) : (
                label
            )}
        </span>
    );

    const titleClassName = cn(
        'v2-info__title',
        {
            [`v2-info__title--${intent}`]: intent,
        },
        customClassName,
    );

    const definitionClassName = cn(
        'v2-info__definition',
        {
            [`v2-info__definition--${intent}`]: intent,
        },
        customClassName,
    );

    const style = { alignSelf: alignSelf || 'center' };
    const componentToShow = children || <span className="v2-info__not-set">Not set</span>;

    return onClick ? (
        <>
            <button
                type="button"
                onClick={onClick}
                className={titleClassName}
                style={style}
                data-testid={`${testId}-key`}
            >
                {IconlabelComponent}
            </button>
            <button
                type="button"
                onClick={onClick}
                className={definitionClassName}
                data-testid={`${testId}-value`}
            >
                {componentToShow}
            </button>
        </>
    ) : (
        <>
            <dt className={titleClassName} style={style} data-testid={`${testId}-key`}>
                {IconlabelComponent}
            </dt>
            <dd className={definitionClassName} data-testid={`${testId}-value`}>
                {componentToShow}
            </dd>
        </>
    );
};

export default InfoItem;
