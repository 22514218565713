import { Intent } from '@blueprintjs/core';
import { JobStatus, Route } from '@doc-abode/data-models';
import { formatMetresAsMilesWithSingleDigit } from '@doc-abode/helpers';

import { getHcpName } from '../../../../../../helpers';
import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import { formatDisplayDateTime } from '../../../../../modules/helpers/formatData';
import { colors } from '../../../MapView';
import { VaccinationRoute } from '../../../types';
import { routeStatusMappings, routeStatusTags } from '../../../utils/constants';

interface IProps {
    index: number;
    route: Route | VaccinationRoute;
}

export const useRouteSummaryCardViewModel = ({ index, route }: IProps) => {
    const {
        RootStore: {
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    // Get styling for card
    const borderColour = colors[index];

    // Get styling and labels for job status tag
    const jobStatus = route.jobStatus;

    const jobStatusIntentColour = routeStatusTags[jobStatus] as Intent | undefined;
    const jobStatusTagMinimal = JobStatus.COMPLETED !== jobStatus;
    const jobStatusLabel = routeStatusMappings[jobStatus];

    // Get HCP Name
    const nominatedHcps = route.nominatedHcps ?? [];
    const hcpId = nominatedHcps?.length > 0 ? nominatedHcps[0] : undefined;
    const hcpName = getHcpName(users, hcpId);

    const itinerary = route.itinerary;

    // Extract number of patients
    const instructions = itinerary?.instructions;
    let numberOfPatients = 0;

    if (instructions) {
        numberOfPatients = instructions.filter(
            ({ instructionType }) => instructionType === 'VisitLocation',
        ).length;
    }

    // Extract summary information
    const routeInRoute = itinerary?.route;

    let totalTravelDistance;
    let totalTravelTime;
    let startTime;
    let endTime;

    if (routeInRoute) {
        totalTravelDistance = routeInRoute.totalTravelDistance;
        totalTravelTime = routeInRoute.totalTravelTime;
        startTime = routeInRoute.startTime;
        endTime = routeInRoute.endTime;
    }

    return {
        borderColour,
        jobStatusIntentColour,
        jobStatusTagMinimal,
        jobStatusLabel,
        hcpName,
        numberOfPatients,
        formattedTravelDistance: totalTravelDistance
            ? formatMetresAsMilesWithSingleDigit(totalTravelDistance)
            : undefined,
        totalTravelTime,
        formattedStartTime: startTime ? formatDisplayDateTime(startTime) : undefined,
        formattedEndTime: endTime ? formatDisplayDateTime(endTime) : undefined,
    };
};
