import { useField } from 'formik';
import { FC, ReactNode } from 'react';

import { FormGroup } from '../FormGroup';

interface IProps {
    name: string;
    title?: string;
    description: string | string[];
    className?: string;
    titleClassName?: string;
    descriptionClassName?: string;
    label?: string;
    required?: boolean;
    notSetText?: string;
    titleComponent?: ReactNode;
    descriptionTestId?: string;
}

const TextComponent: FC<IProps> = ({
    title,
    titleComponent,
    description,
    className,
    titleClassName,
    descriptionClassName,
    label,
    required,
    notSetText = 'Not set',
    descriptionTestId,
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props.name}
            required={required}
            touched={meta.touched}
            error={meta.error}
            {...field}
        >
            {(title || titleComponent) && (
                <h4 className={`v2__form-section-title ${titleClassName || ''}`}>
                    {title || titleComponent}{' '}
                </h4>
            )}
            {!description || description.length === 0 ? (
                <p className="v2-info__not-set" data-testid={descriptionTestId}>
                    {notSetText}
                </p>
            ) : Array.isArray(description) ? (
                description.map((d) => (
                    <p className={descriptionClassName} key={d}>
                        {String(d)}
                    </p>
                ))
            ) : (
                <p className={descriptionClassName} data-testid={descriptionTestId}>
                    {String(description)}
                </p>
            )}
        </FormGroup>
    );
};

export default TextComponent;
