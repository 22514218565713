import { TimePicker, TimePickerProps } from '@blueprintjs/datetime';
import cn from 'classnames';
import { useField, useFormikContext } from 'formik';
import { FC, KeyboardEvent } from 'react';

import { getDateDuration } from '../../../../helpers/ucr';
import { FormGroup } from '../FormGroup';

const availableKeys = ['Alt', 'Backspace', 'Ctrl', 'Enter', 'Shift', 'Tab', 'ArrowUp', 'ArrowDown'];

interface IProps extends TimePickerProps {
    name: string;
    label?: string;
    fullWidth?: boolean;
    required?: boolean;
    checkboxPosition?: string;
    onChangeCheckbox?: (isChecked: boolean) => void;
    handleOnChange?: (value: Date) => void;
    showCheckbox?: boolean;
    displayErrors?: string;
}

const DurationInputComponent: FC<IProps> = ({
    name,
    className,
    label,
    fullWidth,
    required,
    defaultValue,
    selectAllOnFocus = true,
    onChangeCheckbox = () => {},
    disabled,
    displayErrors,
    handleOnChange = () => {},
    ...props
}) => {
    const [field, meta] = useField(name);
    const { setFieldValue, setFieldTouched } = useFormikContext();

    const formatDate = (date: Date) =>
        `${date.getHours().toString().padStart(2, '0')}:${date
            .getMinutes()
            .toString()
            .padStart(2, '0')}`;

    const changeTime = (time: Date) => {
        setFieldValue(name, formatDate(time));
        setFieldTouched(name, true, true);

        if (handleOnChange) {
            handleOnChange(time);
        }
    };

    const keyDownEvent = (event: KeyboardEvent) => {
        const matchesDigit = /\d/.test(event.key);

        if (!matchesDigit && !availableKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <FormGroup
            className={cn(className, 'v2__form-time-input-group')}
            label={label}
            labelFor={field.name}
            required={required}
            touched={meta.touched}
            error={meta.error}
            displayErrors={displayErrors}
        >
            <div data-testid={field.name}>
                <TimePicker
                    {...props}
                    className={cn('v2__form-time-input', {
                        'v2__form-time-input--am-pm': props.useAmPm,
                        'v2__form-time-input--full': fullWidth,
                    })}
                    defaultValue={getDateDuration(field.value)}
                    onKeyDown={keyDownEvent}
                    onChange={changeTime}
                    disabled={disabled}
                    selectAllOnFocus={selectAllOnFocus}
                />
            </div>
        </FormGroup>
    );
};

export default DurationInputComponent;
