import { Pathway } from '@doc-abode/data-models';
import { DEFAULT_VISIT_DURATION } from '@doc-abode/helpers';
import React from 'react';

import { Select, SelectComponent } from '../../../../v2/form';
import { FormikValues, SetFormikValues } from '../common/types';

export interface PathwaySelectProps {
    values: FormikValues;
    setValues: SetFormikValues | null;
    setRandomTimepickerKey: () => void;
    pathways: Pathway[];
    loading: boolean;
    isContinueBtnClicked: boolean;
    selectedPathwayOverride: Pathway | null;
}

export const PathwaySelect = (props: PathwaySelectProps) => {
    const {
        loading,
        isContinueBtnClicked,
        values,
        setValues,
        setRandomTimepickerKey,
        pathways,
        selectedPathwayOverride,
    } = props;

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValues?.({
            ...values,
            referralPathway: e.target.value,
            disposition: '',
            duration: DEFAULT_VISIT_DURATION,
        });
        setRandomTimepickerKey();
    };

    if (selectedPathwayOverride) {
        return (
            <SelectComponent
                data-testid="referralPathway"
                name="referralPathway"
                className="v2__form-group--pos-1-2"
                label="Pathway"
                required
                disabled={loading}
                isContinueBtnClicked={isContinueBtnClicked}
                defaultValue={selectedPathwayOverride.value}
            >
                <option value={selectedPathwayOverride.value}>
                    {selectedPathwayOverride.label}
                </option>
            </SelectComponent>
        );
    }

    return (
        <Select
            data-testid="referralPathway"
            name="referralPathway"
            className="v2__form-group--pos-1-2"
            label="Pathway"
            required
            disabled={loading}
            isContinueBtnClicked={isContinueBtnClicked}
            onChange={onChange}
        >
            <option value="">Select...</option>
            {pathways.map(
                (pathway) =>
                    pathway.enabled && (
                        <option key={pathway.value} value={pathway.value}>
                            {pathway.label}
                        </option>
                    ),
            )}
        </Select>
    );
};
