import { Button, Popover } from '@blueprintjs/core';
import { Schedule } from '@doc-abode/data-models';
import { FC, useState } from 'react';

import { formatScheduleStartAndEndForDisplayLabel } from '../../../../../../helpers/ucr/formatSchedules';
import { ExpandMore, ScheduleIcon } from '../../../../../../helpers/ucr/icons';
import { getMaxSchedule, getSortedHcpHours } from '../../../../../../helpers/ucr/sortSchedules';
import useStores from '../../../../../../hook/useStores';
import { IHcp } from '../../../../../../interfaces/ucr';
import RootStore from '../../../../../../stores/RootStore';
import { Availability } from './HcpAvailabilityForm';
import { IAvailabilityTime } from './HcpAvailabilityFormTypes';
import { reverseLookupScheduleToPattern } from './reverseLookupScheduleToPattern';

type IProps = {
    hcp: IHcp;
    hcpSchedules: Schedule[];
};

const HcpAvailability: FC<IProps> = ({ hcpSchedules, hcp }) => {
    const {
        RootStore: {
            lovsStore: { availabilityTimeSettings },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [open, setIsOpen] = useState(false);
    const sortedHcpSchedules = getSortedHcpHours(hcpSchedules);

    const hasMultiplePatterns = sortedHcpSchedules.length > 1;

    const hcpHcpAvailableHours = sortedHcpSchedules.length
        ? getMaxSchedule(sortedHcpSchedules)
        : null;
    const hcpAvailabilityLabel = hcpHcpAvailableHours
        ? reverseLookupScheduleToPattern(
              hcpHcpAvailableHours,
              availabilityTimeSettings as IAvailabilityTime[],
              hasMultiplePatterns,
          )
        : { label: 'Not Set', value: 'notSet' };

    const patternLabel =
        hcpHcpAvailableHours && hcpAvailabilityLabel.label !== 'None'
            ? `${formatScheduleStartAndEndForDisplayLabel(hcpHcpAvailableHours)} ${
                  hasMultiplePatterns ? `(+${sortedHcpSchedules.length - 1})` : ''
              }`
            : 'Not Set';

    return (
        <div>
            <Popover
                isOpen={open}
                placement="bottom-start"
                className="hcp-availability-popover-wrap"
                content={
                    <Availability
                        hcpHcpAvailableHours={hcpHcpAvailableHours}
                        pattern={hcpAvailabilityLabel.value}
                        hcp={hcp}
                        onSubmit={() => setIsOpen(false)}
                    />
                }
                renderTarget={({ isOpen, ref, ...p }) => (
                    <div className="ucr__calendar-hcp-availability-wrap">
                        <Button
                            {...p}
                            ref={ref as any}
                            className={'ucr__calendar-hcp-availability'}
                            icon={<ScheduleIcon className="ucr__calendar-hcp-availability-icon" />}
                            text={patternLabel}
                            rightIcon={
                                <ExpandMore className="ucr__calendar-hcp-availability-expand-icon" />
                            }
                            onClick={() => setIsOpen(!open)}
                        />
                    </div>
                )}
                onClose={() => {
                    setIsOpen(false);
                }}
            ></Popover>
        </div>
    );
};

export default HcpAvailability;
