import { convertArrayToConcatenatedString, formatNameLastMiddleFirst } from '@doc-abode/helpers';
import { FormikValues } from 'formik';
import moment from 'moment';
import { FC } from 'react';

import { genderMapping } from '../../../../../constants/mainConst';
import { dateFormat } from '../../../../../constants/patientsConst';
import { formatAddressString } from '../../../../../helpers';
import { Text } from '../../../../v2/form';

export type PatientDetailsReviewFormType = {
    values: FormikValues;
};
const PatientDetailsReviewForm: FC<PatientDetailsReviewFormType> = ({ values }) => {
    const allContactNumbers = values.contactNumber
        ? [values.contactNumber, ...(values.additionalContactNumbers || [])]
        : [];

    let genderPreferredMapping = values.staffPreferredGender ? values.staffPreferredGender : [];
    genderPreferredMapping = genderPreferredMapping.map((index: any) => {
        if (index) {
            return genderMapping[index];
        }
        return '';
    });

    const formattedPhoneNumbers = convertArrayToConcatenatedString(
        allContactNumbers?.map((number: string) => number && `+${number}`),
        ', ',
    );

    return (
        <div className="v2__form-block">
            <Text
                name="nhsNumber"
                title="NHS number"
                description={values.nhsNumber}
                className="v2__form-group--pos-1-4"
            />
            <Text
                name="name"
                title="Name"
                description={formatNameLastMiddleFirst(values)}
                className="v2__form-group--pos-2-4"
            />
            <Text
                name="dateOfBirth"
                title="Date of birth"
                description={
                    values.dateOfBirth ? moment(values.dateOfBirth).format(dateFormat) : 'Not set'
                }
                className="v2__form-group--pos-3-4"
            />
            <Text
                name="Gender"
                title="Gender"
                description={genderMapping[values.gender]}
                className="v2__form-group--pos-4-4"
            />
            <Text
                name="address"
                title="Address"
                description={formatAddressString({
                    addressLine1: values.addressLine1,
                    addressLine2: values.addressLine2,
                    addressLine3: values.addressLine3,
                    postCode: values.postCode,
                    town: values.town,
                })}
                className="v2__form-group--pos-1-2"
            />
            <Text
                name="contactNumber"
                title="Phone number(s)"
                description={formattedPhoneNumbers || 'Not set'}
                className="v2__form-group--pos-2-2"
            />{' '}
            <Text
                name="languagesSpoken"
                title="Spoken language"
                description={convertArrayToConcatenatedString(values.languagesSpoken, ', ')}
                className="v2__form-group--pos-1-2"
            />
            <Text
                name="staffPreferredGender"
                title="Gender of visiting staff"
                description={convertArrayToConcatenatedString(genderPreferredMapping, ', ')}
                className="v2__form-group--pos-2-2"
            />
        </div>
    );
};

export default PatientDetailsReviewForm;
