import { JobStatus } from '@doc-abode/data-models';
import { FormikValues } from 'formik';

import { dateTimeFormat } from '../../../../../constants/patientsConst';
import { getMomentDateFormatter } from '../../../../../helpers/ucr/getMomentDateFormatter';
import { getPropertyNames } from '../ChangeVisitStatus/changeVisitStatusHelpers';

interface IProps {
    values: FormikValues;
    isBuddy: boolean;
}

export const useJobStatusDetailsViewModel = ({ values, isBuddy }: IProps) => {
    const propertyNames = getPropertyNames({ isHcp1: !isBuddy });
    const timeInputFormat = getMomentDateFormatter(dateTimeFormat);

    // ViewModel returns a combination of field (value) and field name to enable display and binding

    // Job Status
    const jobStatusFieldName = propertyNames.jobStatus;
    const jobStatus = values[propertyNames.jobStatus];

    // Made current datetime
    const madeCurrentDateTimeFieldName = propertyNames.madeCurrentDateTime;
    const madeCurrentDateTime = values[propertyNames.madeCurrentDateTime];

    // Arrived datetime
    const arrivedDateTimeFieldName = propertyNames.arrivedDateTime;
    const arrivedDateTime = values[propertyNames.arrivedDateTime];

    // Completed datetime
    const finishedDateTimeFieldName = propertyNames.finishedDateTime;
    let finishedDateTime =
        jobStatus === JobStatus.COMPLETED ? values[propertyNames.finishedDateTime] : undefined;

    // Arrived datetime
    const postJobNotesFieldName = propertyNames.postVisitNotes;
    const postJobNotes = values[propertyNames.postVisitNotes];

    // Inconsistency for aborted fields is due to the way we handle HCP aborted vs. Controller aborted
    let abortedDateTimeFieldName: string = '';
    let abortedDateTime: string | undefined;
    let abortedReasonFieldName: string = '';
    let abortedReason: string | undefined;
    let abortedNotesFieldName: string = '';
    let abortedNotes: string | undefined;

    if (jobStatus === JobStatus.CONTROLLER_ABORTED) {
        abortedDateTime = values[propertyNames.finishedDateTime];
        abortedDateTimeFieldName = propertyNames.finishedDateTime;

        abortedReason = values[propertyNames.controllerAbortedReason];
        abortedReasonFieldName = propertyNames.controllerAbortedReason;

        abortedNotes = values[propertyNames.controllerAbortedNotes];
        abortedNotesFieldName = propertyNames.controllerAbortedNotes;
    } else if (jobStatus === JobStatus.HCP_ABORTED) {
        abortedDateTime = values[propertyNames.hcpAbortedDateTime];
        abortedDateTimeFieldName = propertyNames.hcpAbortedDateTime;

        abortedReason = values[propertyNames.hcpAbortedReason];
        abortedReasonFieldName = propertyNames.hcpAbortedReason;

        abortedNotes = values[propertyNames.hcpAbortedNotes];
        abortedNotesFieldName = propertyNames.hcpAbortedNotes;
    }

    const showMadeCurrentDateTime = !!madeCurrentDateTime;
    const showArrivedDateTime = !!arrivedDateTime;
    const showFinishedDateTime = !!finishedDateTime;
    const showPostJobNotes = !!postJobNotes;
    const showAbortedDateTime = !!abortedDateTime;
    const showAbortedDetails = !!abortedReason;

    return {
        // propertyNames,
        timeInputFormat,

        showMadeCurrentDateTime,
        showArrivedDateTime,
        showFinishedDateTime,
        showPostJobNotes,
        showAbortedDateTime,
        showAbortedDetails,
        jobStatus,
        jobStatusFieldName,
        madeCurrentDateTime,
        madeCurrentDateTimeFieldName,
        arrivedDateTime,
        arrivedDateTimeFieldName,
        finishedDateTime,
        finishedDateTimeFieldName,
        postJobNotesFieldName,
        postJobNotes,
        abortedDateTime,
        abortedDateTimeFieldName,
        abortedReason,
        abortedReasonFieldName,
        abortedNotes,
        abortedNotesFieldName,
    };
};
