import { FormikErrors, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Button, ButtonSizes } from '../../../../v2/components';
import { Checkbox, TextArea } from '../../../../v2/form';
import { WarningBanner } from '../../../../v2/form/WarningBanner';
import { VisitValuesType } from '../../blocks/panels/VisitDetailsTypes';
import { HcpSelect } from '../../components';
import { JobStatusDetails } from '../AddVisit/JobStatusDetails';
import { FormMode, FormSteps } from '../common';
import { DateTimeSelect } from '../DateTime';
import { PathwayServiceSelect } from '../Pathway';
import { useActivityDetailsViewModel } from './useActivityDetailsViewModel';

interface ActivityDetailsType {
    values: FormikValues;
    loading: boolean;
    onNextStep: (next: FormSteps, prev: FormSteps) => void;
    setFieldValue: (field: string, value: any) => void;
    setValues: (
        values: React.SetStateAction<FormikValues>,
        shouldValidate?: boolean,
    ) => Promise<void | FormikErrors<FormikValues>>;
    formMode: FormMode;
    onSaveForm?: (values: FormikValues) => Promise<void>;
}

const ActivityDetails: FC<ActivityDetailsType> = ({
    values,
    loading,
    onNextStep,
    setFieldValue,
    setValues,
    formMode,
}) => {
    const {
        setShowUnavailableHcp,
        onSaveClick,
        buttonName,
        defaultStartTime,
        hasWarningMessages,
        highlightedWarnings,
        isContinueBtnClicked,
        MAXDATE,
        MINTIME,
        showUnavailableHcp,
        warningMessages,
    } = useActivityDetailsViewModel({
        values,
        onNextStep,
        setFieldValue,
        formMode,
    });

    const [timepickerKey, setTimepickerKey] = useState(uuidv4());

    return (
        <div className="v2__form-block">
            <PathwayServiceSelect
                loading={loading}
                values={values}
                setValues={setValues}
                isContinueBtnClicked={isContinueBtnClicked}
                setTimepickerKey={setTimepickerKey}
                isEditMode
            />
            <TextArea
                name="notes"
                className="v2__form-group--pos-1-1"
                label="Pre-job notes"
                disabled={loading}
            />
            <DateTimeSelect
                values={values}
                isEditMode
                loading={loading}
                isContinueBtnClicked={isContinueBtnClicked}
                maxDate={MAXDATE}
                minTime={MINTIME}
                timepickerKey={timepickerKey}
                highlightedWarnings={highlightedWarnings}
                defaultStartTime={values.startTime || defaultStartTime}
                timeRequired
            />
            <div className="v2__form-group--pos-1-1">
                <strong>Staff member</strong>
            </div>
            <HcpSelect
                patient={values as VisitValuesType}
                disabled={loading}
                includeUnavailableHcps={showUnavailableHcp}
                ariaLabel="hcp select"
            />
            <Checkbox
                name="Show unavailable"
                className="v2__form-group--pos-2-2"
                title="Show unavailable"
                disabled={loading}
                checked={showUnavailableHcp}
                onChange={() => setShowUnavailableHcp(!showUnavailableHcp)}
                aria-label="show unavailable"
            />
            <ConditionalDisplay show={formMode === FormMode.EDIT_VISIT}>
                <JobStatusDetails isBuddy={false} values={values} isEditMode={true} />
            </ConditionalDisplay>

            <ConditionalDisplay show={hasWarningMessages}>
                <div className="v2__form-group--pos-1-1">
                    <WarningBanner spacerTop={true} compact={true} warnings={warningMessages} />
                </div>
            </ConditionalDisplay>
            <label className="v2__form-group" htmlFor="submit">
                <Button
                    className="v2__form-submit-button"
                    name={buttonName}
                    size={ButtonSizes.MEDIUM}
                    clickEvent={onSaveClick}
                    type="submit"
                    id="submit"
                    testId="careDetailsSave"
                />
            </label>
        </div>
    );
};

export default observer(ActivityDetails);
