import { DateFormatProps } from '@blueprintjs/datetime';
import { JobStatus, Patient } from '@doc-abode/data-models';
import { FormikValues } from 'formik';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC } from 'react';

import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { DateInput, Text, TextArea } from '../../../../v2/form';
import { useJobStatusDetailsViewModel } from './useJobStatusDetailsViewModel';

interface IProps {
    values: FormikValues;
    isBuddy: boolean;
    isEditMode?: boolean;
}

interface ITextAreaProps {
    label: string;
    fieldName: string;
    value?: string;
    className: string;
    isEditMode?: boolean;
}

interface IDateTimeFieldProps {
    label: string;
    fieldName: string;
    value?: string;
    className: string;
    timeInputFormat?: DateFormatProps;
    isEditMode?: boolean;
}

const ConditionalDateTimeField = ({
    fieldName,
    label,
    value,
    className,
    isEditMode,
    timeInputFormat,
}: IDateTimeFieldProps) => {
    if (isEditMode === true) {
        return (
            <DateInput
                {...timeInputFormat}
                timePrecision={'minute'}
                canBeBlank={false}
                name={fieldName}
                label={label}
                className={className}
                placeholder={value ? moment(value).format('DD/MM/YYYY HH:mm') : 'DD/MM/YYYY HH:mm'}
                required
            />
        );
    }

    return (
        <Text
            name={fieldName}
            title={label}
            className={className}
            description={value ? moment(value).format('DD/MM/YYYY HH:mm') : ''}
        />
    );
};

const ConditionalTextAreaField = ({
    fieldName,
    label,
    value,
    className,
    isEditMode,
}: ITextAreaProps) => {
    if (isEditMode === true) {
        return <TextArea name={fieldName} label={label} className={className} />;
    }

    return (
        <Text
            name={fieldName}
            title={label}
            description={value ?? 'Not set'}
            className={className}
        />
    );
};

export const JobStatusDetails: FC<IProps> = observer(({ values, isBuddy, isEditMode }) => {
    const {
        timeInputFormat,
        showMadeCurrentDateTime,
        showArrivedDateTime,
        showFinishedDateTime,
        showAbortedDateTime,
        showPostJobNotes,
        showAbortedDetails,

        jobStatus,
        jobStatusFieldName,
        madeCurrentDateTime,
        madeCurrentDateTimeFieldName,
        arrivedDateTimeFieldName,
        arrivedDateTime,
        finishedDateTimeFieldName,
        finishedDateTime,
        postJobNotesFieldName,
        postJobNotes,
        abortedDateTimeFieldName,
        abortedDateTime,
        abortedReason,
        abortedReasonFieldName,
        abortedNotes,
        abortedNotesFieldName,
    } = useJobStatusDetailsViewModel({ values, isBuddy });

    return (
        <>
            <ConditionalDisplay show={Boolean(values.jobStatus)}>
                <Text
                    name={jobStatusFieldName}
                    title="Status"
                    className="v2__form-group--pos-1-4"
                    description={Patient.getFriendlyVisitStatus(jobStatus as JobStatus)}
                />
            </ConditionalDisplay>

            <ConditionalDisplay show={showMadeCurrentDateTime}>
                <ConditionalDateTimeField
                    isEditMode={isEditMode}
                    fieldName={madeCurrentDateTimeFieldName}
                    value={madeCurrentDateTime}
                    label="Time marked as current"
                    className="v2__form-group--pos-2-4"
                    timeInputFormat={timeInputFormat}
                />
            </ConditionalDisplay>

            <ConditionalDisplay show={showArrivedDateTime}>
                <ConditionalDateTimeField
                    isEditMode={isEditMode}
                    fieldName={arrivedDateTimeFieldName}
                    value={arrivedDateTime}
                    label="Actual start"
                    className="v2__form-group--pos-3-4"
                    timeInputFormat={timeInputFormat}
                />
            </ConditionalDisplay>

            <ConditionalDisplay show={showFinishedDateTime}>
                <ConditionalDateTimeField
                    isEditMode={isEditMode}
                    fieldName={finishedDateTimeFieldName}
                    value={finishedDateTime}
                    label="Actual end"
                    className="v2__form-group--pos-4-4"
                    timeInputFormat={timeInputFormat}
                />
            </ConditionalDisplay>

            <ConditionalDisplay show={showAbortedDateTime}>
                <ConditionalDateTimeField
                    isEditMode={isEditMode}
                    fieldName={abortedDateTimeFieldName}
                    value={abortedDateTime}
                    label="Aborted time"
                    className="v2__form-group--pos-4-4"
                    timeInputFormat={timeInputFormat}
                />
            </ConditionalDisplay>

            <ConditionalDisplay show={showPostJobNotes}>
                <ConditionalTextAreaField
                    fieldName={postJobNotesFieldName}
                    value={postJobNotes}
                    isEditMode={isEditMode}
                    className="v2__form-group--pos-1-1 v2__form-group--notes"
                    label="Post-job notes"
                />
            </ConditionalDisplay>

            <ConditionalDisplay show={showAbortedDetails}>
                <Text
                    name={abortedReasonFieldName}
                    title="Aborted Reason"
                    description={abortedReason ?? 'Not set'}
                    className="v2__form-group--pos-1-2"
                />
                <Text
                    name={abortedNotesFieldName}
                    title="Aborted Notes"
                    description={abortedNotes ?? ' Not set'}
                    className="v2__form-group--pos-2-2 v2__form-group--notes"
                />
            </ConditionalDisplay>
        </>
    );
});
