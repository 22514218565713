import { Button, Heading, Text, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react';

export const authComponents = {
    SignIn: {
        Header() {
            const { tokens } = useTheme();

            return (
                <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={2}>
                    Sign In
                </Heading>
            );
        },
        Footer() {
            const { toForgotPassword } = useAuthenticator();

            return (
                <>
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toForgotPassword}
                            size="small"
                            variation="link"
                        >
                            Forgotten Your Password?
                        </Button>
                    </View>
                    <Text>
                        If you are having problems signing in please email us at{' '}
                        <a href="mailto:support@docabode.com">support@docabode.com</a>.
                    </Text>
                </>
            );
        },
    },
    ConfirmSignIn: {
        Footer() {
            return (
                <Text>
                    We've sent a verification code to your registered phone number, please enter it
                    above to continue. The code will expire in three minutes, if you do not enter it
                    in time you must sign in again.
                </Text>
            );
        },
    },
};
