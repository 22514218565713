import { JobStatus, Patient, PatientAlert, Warning } from '@doc-abode/data-models';
import moment from 'moment';
import { ReactNode } from 'react';

import { ViewToShow } from '../../constants/mainConst';
import { IFilterOption, INameFilter } from '../../interfaces/ucr';
import { FormattedWarning } from '../../interfaces/warning';
import { IJobFilter } from '../../stores/UCRStore';
import formatNhsNumber from './formatNhsNumber';
import {
    isBreachOfEarliestArrival,
    isBreachOfLatestArrival,
    WarningCategories,
} from './getWarnings';
import { isMultiAssigneeJob } from './isMultiAssigneeJob';

interface IProps {
    jobs: Patient[];
    jobFilters: Partial<IJobFilter>;
    nameFilters: INameFilter;
    warnings?: FormattedWarning;
    patientAlerts: PatientAlert[];
    viewToShow: ViewToShow;
    filterByHcp?: boolean;
}

export const checkJobStatus = (job: Patient, jobStatus: IFilterOption[], isBuddy?: boolean) => {
    let isOk: boolean = isBuddy ? false : true;

    const status: string[] = jobStatus.map(({ value }: IFilterOption) => value);
    const buddyJobStatus = job.buddyJobStatus || JobStatus.PENDING; //sometimes the buddyJobStatus is null...

    switch (isBuddy ? buddyJobStatus : job.jobStatus) {
        case JobStatus.ACCEPTED:
        case JobStatus.PENDING:
            isOk = status.includes('notStarted');
            break;
        case JobStatus.CURRENT:
            isOk = status.includes('current');
            break;
        case JobStatus.ARRIVED:
            isOk = status.includes('arrived');
            break;
        case JobStatus.COMPLETED:
            isOk = status.includes('completed');
            break;
        case JobStatus.HCP_ABORTED:
        case JobStatus.CONTROLLER_ABORTED:
            isOk = status.includes('aborted');
            break;
    }

    return isOk;
};

export const checkWarning = (
    job: Patient,
    warnings: Warning[] = [],
    filterWarnings: IFilterOption[],
    patientAlerts: PatientAlert[],
) => {
    let isOk: boolean = true;

    //TODO update logic to support multiple values
    const warning = filterWarnings.map(({ value }) => value)[0];

    switch (warning) {
        case 'withAnyWarnings':
            isOk = warnings.length > 0;
            break;
        case 'withPatientAlerts':
            isOk = patientAlerts.some((alert) => alert.jobId === job.id);
            break;
        case 'withConflicts':
            isOk = warnings.some(
                ({ category }) =>
                    category === WarningCategories.HCP_SCHEDULING_CONFLICT ||
                    category === WarningCategories.PATIENT_SCHEDULING_CONFLICT,
            );
            break;
        case 'outsideAvailable':
            isOk = warnings.some(
                ({ category }) => category === WarningCategories.HCP_AVAILABILITY_CONFLICT,
            );
            break;
        case 'earliestArrival':
            isOk = isBreachOfEarliestArrival(job);
            break;
        case 'latestArrival':
            isOk = isBreachOfLatestArrival(job);
            break;
        case 'affectedByDelays':
            isOk =
                moment().isAfter(job.startDateTime) &&
                (job.jobStatus === JobStatus.ACCEPTED || job.jobStatus === JobStatus.PENDING);
            break;
        case 'withDoubleUp':
            isOk = isMultiAssigneeJob(job) && job.buddyId !== undefined;
            break;
    }
    return isOk;
};

export const checkPatient = (job: Patient, patientName: ReactNode[]) =>
    patientName.some((name) => {
        const lowerCaseName = String(name).toLowerCase().trim();
        return (
            job.firstName?.toLowerCase().includes(lowerCaseName) ||
            job.middleName?.toLowerCase().includes(lowerCaseName) ||
            job.lastName?.toLowerCase().includes(lowerCaseName) ||
            job.nhsNumber?.includes(formatNhsNumber(lowerCaseName))
        );
    });

export const checkHcp = (job: Patient, staffName: string[]) =>
    staffName.some((name) => {
        const lowerCaseName = String(name).toLowerCase().trim();
        const isHcp = job.hcpName?.toLowerCase().includes(lowerCaseName);
        const isBuddy =
            isMultiAssigneeJob(job) && job.buddyName?.toLowerCase().includes(lowerCaseName);
        return isHcp || isBuddy;
    });

const getFilteredJobs = ({
    jobs,
    jobFilters,
    nameFilters,
    warnings,
    patientAlerts,
    viewToShow,
    filterByHcp,
}: IProps): Patient[] => {
    if (!Array.isArray(jobs) || !jobs.length) return [];

    const { jobStatus = [], warning = [], ...filtersObj } = jobFilters;
    const { patientName, staffName } = nameFilters;

    const filtersArr = Object.entries(filtersObj).filter(([key, val]) =>
        Array.isArray(val) ? val.length > 0 : typeof val !== 'undefined' && val !== '',
    );

    const filtered = jobs.filter((job: Patient) => {
        // Check all filters from Select lists
        let check =
            // Check jobStatus of the visit, if it's specified
            (!jobStatus.length || checkJobStatus(job, jobStatus)) &&
            // Check patient warnings, if it's specified
            checkWarning(job, warnings?.[job.id], warning, patientAlerts) &&
            // Check the patient's name, if it's specified
            (!patientName.length || checkPatient(job, patientName)) &&
            (!filterByHcp || !staffName.length || checkHcp(job, staffName)) &&
            filtersArr.every(([key, val]) => {
                return Array.isArray(val)
                    ? val.map(({ value }) => value).includes(job[key as keyof Patient]) ||
                          (val[0].value === 'unset' && !job[key as keyof Patient])
                    : job[key as keyof Patient] === val ||
                          ((val === 'unset' || !val) && !job[key as keyof Patient]);
            });

        // if DoubleUp job then check the buddy status
        if (!check && isMultiAssigneeJob(job) && viewToShow === ViewToShow.MAP) {
            check = checkJobStatus(job, jobStatus, true);
        }
        return check;
    });
    return filtered;
};

export default getFilteredJobs;
