import { Icon, Tab, Tabs } from '@blueprintjs/core';
import { S1ClientConnectionStatus } from '@doc-abode/data-models';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { useGetS1ClientConnectionStatuses } from '../../../api/s1Queries';
import { IconKnowledge } from '../../../helpers/ucr/icons';
import useStores from '../../../hook/useStores';
import RootStore from '../../../stores/RootStore';
import { useUsersForRoleViewModel } from '../../pages/users/useUsersForRoleViewModel';
import { getRoutes } from '../controller/getRoutes';
import { reverseLookupTab } from './getHomepageTab';
import { getTabId } from './getTabId';
import { getTabTarget } from './getTabTarget';

const Header: FC = () => {
    const {
        RootStore: {
            configStore: { isFeatureEnabled },
            userStore: { isAdmin, isController, isComplianceOfficer, isSuperuser, logOut },
        },
    } = useStores<{ RootStore: RootStore }>();

    // Admins can see more users than a normal Controller
    const { users } = useUsersForRoleViewModel();

    const preventDragHandler = (event: React.DragEvent) => event.preventDefault();

    const routes = getRoutes(
        isController,
        isAdmin,
        isSuperuser,
        isComplianceOfficer,
        isFeatureEnabled,
    );

    const availableUsers = users.filter((user: any) => user.available);

    const currentPath = window.location.pathname;
    const selectedTab = reverseLookupTab(
        currentPath,
        isController,
        isAdmin,
        isSuperuser,
        isComplianceOfficer,
        isFeatureEnabled,
    );

    const {
        isError,
        isSuccess,
        data: s1ClientConnectionStatuses,
    } = useGetS1ClientConnectionStatuses();

    const S1ClientConnectionStatus = ({
        isError,
        isSuccess,
        data,
    }: {
        isError: boolean;
        isSuccess: boolean;
        data: S1ClientConnectionStatus[];
    }) => {
        if (isError || !isSuccess) {
            return <></>;
        }
        const numberOfConnectedClients = data.filter(
            (client) => client.status === 'connected',
        ).length;
        const numberOfWarningClients = data.filter((client) => client.status === 'warning').length;
        const getClassName = (numberOfWarningClients: number, numberOfConnectedClients: number) => {
            if (numberOfWarningClients > 0) {
                return 'nav__item-warning';
            }
            if (numberOfConnectedClients > 0) {
                return 'nav__item-connected';
            }
            return 'nav__item-disconnected';
        };

        return (
            <>
                <span
                    data-testid="connected-clients-count"
                    className={getClassName(numberOfWarningClients, numberOfConnectedClients)}
                >
                    {' '}
                    {numberOfConnectedClients}
                </span>
                /
                <span data-testid="total-clients-count" className="nav__item-total">
                    {numberOfConnectedClients + numberOfWarningClients}
                </span>
            </>
        );
    };

    return (
        <header className={classNames('header', 'ucr__header')}>
            <Link to="/">
                <img
                    src={'/images/logos/docabode_logo-white.png'}
                    alt="Doc Abode Logo"
                    className="header__logo"
                />
            </Link>
            <Tabs id="NavigationTabs" selectedTabId={selectedTab} className="header__tabs" large>
                {routes.map((route) => {
                    return (
                        <Tab
                            id={getTabId(route)}
                            key={getTabId(route)}
                            onDragStart={preventDragHandler}
                        >
                            <Link to={getTabTarget(route)}>
                                {route.tabIcon && (
                                    <Icon icon={route.tabIcon} className="header__tab-icon" />
                                )}
                                {route.tabLabel}
                                {getTabId(route) === 'users' && (
                                    <>
                                        <span className="nav__item-available">
                                            {' '}
                                            {availableUsers.length}
                                        </span>
                                        /<span className="nav__item-total">{users.length}</span>
                                    </>
                                )}
                                {getTabId(route) === 'systmone' && s1ClientConnectionStatuses && (
                                    <S1ClientConnectionStatus
                                        isError={isError}
                                        isSuccess={isSuccess}
                                        data={s1ClientConnectionStatuses}
                                    />
                                )}
                            </Link>
                        </Tab>
                    );
                })}
                <Tab id="tab-resources" key="tab-resources" onDragStart={preventDragHandler}>
                    <a
                        href="https://docabode.atlassian.net/wiki/spaces/IS/overview"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <IconKnowledge className="header__tab-icon" />
                        Resources
                    </a>
                </Tab>
                <Tab id="logout" onDragStart={preventDragHandler}>
                    <Link to="/logout" onClick={() => logOut()}>
                        <Icon icon="log-out" className="header__tab-icon" />
                        Sign out
                    </Link>
                </Tab>
            </Tabs>
        </header>
    );
};

export default observer(Header);
