import { getHcpTypesDisplayLabel } from '../../../../../../helpers/getHcpTypeDisplayLabel';
import useStores from '../../../../../../hook/useStores';
import { IHcp } from '../../../../../../interfaces';
import RootStore from '../../../../../../stores/RootStore';

interface IProps {
    user: IHcp;
    selectedUsers: string[];
}

export const useRouteHcpListRowViewModel = ({ user, selectedUsers }: IProps) => {
    const {
        RootStore: {
            usersStore: { getUserHcpTypes },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { userId, userName } = user;

    const isUserAlreadySelected = selectedUsers.includes(userId);

    // Get a list of formatted HCP types for the user
    const formattedHcpTypes = getHcpTypesDisplayLabel(getUserHcpTypes(userId));

    return {
        userId,
        userName,
        isUserAlreadySelected,
        formattedHcpTypes,
    };
};
