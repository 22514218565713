import moment from 'moment';

import { FormFieldRecord } from '../jobCreator';
import { JobType } from './JobTypes';

export const referralSchema: FormFieldRecord[] = [
    { key: 'systmOneRef', followUpCopy: [JobType.ADMIN, JobType.VISIT] },
    {
        key: 'referrer',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            return values.referrer ?? null;
        },
    },
    {
        key: 'referralDateTime',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            if (values.referralDateTime) {
                return moment(values.referralDateTime).toISOString();
            }
            return null;
        },
    },
];
