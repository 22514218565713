import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import { Text } from '../../../../v2/form';
import { FormikValues, SetFormikValues } from '../common/types';
import { usePathwayServiceSelectViewModel } from '.';
import { PathwaySelect } from './PathwaySelect';
import { ServiceSelect } from './ServiceSelect';

interface BaseProps {
    isEditMode?: boolean;
    values: FormikValues;
}

interface EditModeProps extends BaseProps {
    isEditMode: true;
    loading?: boolean;
    isContinueBtnClicked?: boolean;
    setValues: SetFormikValues;
    setTimepickerKey: (key: string) => void;
}

interface ViewModeProps extends BaseProps {
    isEditMode?: false;
}

export type PathwayServiceSelectProps = EditModeProps | ViewModeProps;

export const PathwayServiceSelect = observer((props: PathwayServiceSelectProps) => {
    const { isEditMode, values } = props;

    const setValues: SetFormikValues | null = isEditMode ? props.setValues : null;

    const {
        pathways,
        services,
        pathwayDisplayLabel,
        serviceOrAdminTypeDisplayLabel,
        serviceOrAdminTypeLabel,
        serviceOrAdminTypeName,
        selectedPathwayOverride,
        setValuesFn,
    } = usePathwayServiceSelectViewModel({ values, setValues });

    if (!isEditMode) {
        return (
            <>
                <Text
                    name="referralPathway"
                    title="Pathway"
                    description={pathwayDisplayLabel}
                    className="v2__form-group--pos-1-2"
                />
                <Text
                    name={serviceOrAdminTypeLabel}
                    title={serviceOrAdminTypeLabel}
                    description={serviceOrAdminTypeDisplayLabel}
                    className="v2__form-group--pos-2-2"
                />
            </>
        );
    }

    const { loading = false, isContinueBtnClicked = false, setTimepickerKey } = props;

    const setRandomTimepickerKey = () => setTimepickerKey(uuidv4());

    return (
        <>
            <PathwaySelect
                loading={loading}
                isContinueBtnClicked={isContinueBtnClicked}
                values={values}
                setValues={setValues}
                setRandomTimepickerKey={setRandomTimepickerKey}
                pathways={pathways}
                selectedPathwayOverride={selectedPathwayOverride}
            />

            <ServiceSelect
                loading={loading}
                isContinueBtnClicked={isContinueBtnClicked}
                setValues={setValuesFn}
                setRandomTimepickerKey={setRandomTimepickerKey}
                services={services}
                name={serviceOrAdminTypeName}
                label={serviceOrAdminTypeLabel}
            />
        </>
    );
});
