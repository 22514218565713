import { Patient } from '@doc-abode/data-models';
import { FormikValues } from 'formik';
import { FC } from 'react';

import getHcpName from '../../../../../helpers/ucr/getHcpName';
import { transformFormikValuesToPatientForWarningValidation } from '../../../../../helpers/ucr/getWarnings';
import { IconChangeStatus } from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { DialogAlerts, Dialogs } from '../../../../../stores/UCRStore';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import {
    Accordion,
    AccordionColors,
    AccordionTab,
    Button,
    ButtonElems,
    ButtonSizes,
} from '../../../../v2/components';
import { Text } from '../../../../v2/form';
import { WarningBanner } from '../../../../v2/form/WarningBanner';
import { usePatientFlagsToChangeStatus } from '../../hooks/usePatientFlagsToChangeStatus';
import { useWarningMessageViewModel } from '../../hooks/useWarningMessageViewModel';
import { JobStatusDetails } from '../AddVisit/JobStatusDetails';
import { FormMode, FormSteps } from '../common';
import { DateTimeSelect } from '../DateTime';
import { PathwayServiceSelect } from '../Pathway';
import PatientDetailsReviewForm from '../PatientDetails/PatientDetailsReviewForm';

interface Props {
    values: FormikValues;
    onSave: (values: FormikValues) => Promise<void>;
    onEdit: (step: FormSteps) => void;
    formMode: string;
}

const ReviewForm: FC<Props> = ({ values, onSave, onEdit, formMode }) => {
    const {
        RootStore: {
            usersStore: { users },
            ucrStore: { openedDialogAlert, setOpenedDialogAlert, setOpenedDialog, jobs },
        },
    } = useStores<{ RootStore: RootStore }>();

    const patient: Patient = jobs.filter((user: any) => user.id === values.id)[0];
    const { isDisabled } = usePatientFlagsToChangeStatus(patient);

    if (
        openedDialogAlert !== DialogAlerts.SAVE_ADMINISTRATIVE_TIME &&
        formMode !== FormMode.EDIT_VISIT
    ) {
        setOpenedDialogAlert(DialogAlerts.SAVE_ADMINISTRATIVE_TIME);
    }

    // Wrapping in an array to keep changes to downstream dependencies minimal
    const staffFiltered = [getHcpName(users, values.hcpId, false)];

    // Create a temporary job to allow reuse of validations
    const tmpJobForValidation = transformFormikValuesToPatientForWarningValidation({
        values,
        isAdmin: true,
    });

    const { warningMessages, hasWarningMessages } = useWarningMessageViewModel({
        job: tmpJobForValidation,
    });

    return (
        <Accordion>
            <AccordionTab
                name="patiens"
                title="Patient details"
                onEdit={() => onEdit(FormSteps.PATIENT)}
                color={AccordionColors.PINK}
                open
            >
                <PatientDetailsReviewForm values={values} />
            </AccordionTab>
            <AccordionTab
                name="activity"
                title="Activity details"
                onEdit={() => onEdit(FormSteps.ACTIVITY)}
                color={AccordionColors.PINK}
                open
            >
                <CareDetails values={values} staffFiltered={staffFiltered} />
            </AccordionTab>
            <ConditionalDisplay show={hasWarningMessages}>
                <div className="v2__form-group--pos-1-1">
                    <WarningBanner spacerTop={true} compact={true} warnings={warningMessages} />
                </div>
            </ConditionalDisplay>
            {formMode !== FormMode.EDIT_VISIT && (
                <div>
                    <label className="v2__form-group" htmlFor="submit">
                        <Button
                            className="v2__form-submit-button"
                            elem={ButtonElems.DIV}
                            name={'Add administrative time'}
                            size={ButtonSizes.MEDIUM}
                            clickEvent={() => onSave(values)}
                            id="submit"
                            testId="saveVisit"
                        />
                    </label>
                </div>
            )}

            {isDisabled ? (
                <Button
                    className="v2__form-submit-button"
                    size={ButtonSizes.MEDIUM}
                    name="Change status"
                    Icon={IconChangeStatus}
                    clickEvent={() => setOpenedDialog(Dialogs.CHANGE_VISIT_STATUS)}
                />
            ) : null}
        </Accordion>
    );
};

export default ReviewForm;

function CareDetails({ values, staffFiltered }: { values: FormikValues; staffFiltered: string[] }) {
    return (
        <div className="v2__form-block">
            <PathwayServiceSelect values={values} />
            <Text
                name="Notes"
                title="Pre-job notes"
                className="v2__form-group--pos-1-1 v2__form-group--notes"
                description={values.notes}
            />
            <DateTimeSelect values={values} />
            <Text
                name="staffRequired"
                title="Staffing requirements"
                description={staffFiltered}
                className="v2__form-group--pos-1-2"
            />
            <JobStatusDetails isBuddy={false} values={values} isEditMode={false} />
        </div>
    );
}
