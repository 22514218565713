import { Patient, Warning } from '@doc-abode/data-models';
import { useMemo } from 'react';

import { getHcp } from '../../../../helpers/ucr/getHcp';
import getHcpName from '../../../../helpers/ucr/getHcpName';
import {
    filterDuplicateWarningMessages,
    getGenderWarningText,
    getLanguageWarningText,
    WarningCategories,
    WarningMessages,
} from '../../../../helpers/ucr/getWarnings';
import useStores from '../../../../hook/useStores';
import { IHcp } from '../../../../interfaces/ucr';
import RootStore from '../../../../stores/RootStore';
import { FormSteps } from '../forms/common';
import { useWarningConditions } from './useWarningConditions';

export interface WarningMessageViewModelWarning extends Warning {
    isActive?: boolean;
    showOnSection?: FormSteps[];
}

interface IFilterWarningMessagesBySection {
    warnings?: WarningMessageViewModelWarning[];
    sectionShown?: FormSteps;
}

export function filterWarningMessagesBySection({
    warnings = [],
    sectionShown,
}: IFilterWarningMessagesBySection) {
    if (!sectionShown) {
        return warnings;
    }

    return warnings.filter((warning) => warning.showOnSection?.includes(sectionShown));
}

interface ITransformBackendWarnings {
    warnings?: Warning[];
    users?: IHcp[];
}

export function transformBackendWarnings({ warnings, users = [] }: ITransformBackendWarnings) {
    const transformedWarnings: WarningMessageViewModelWarning[] = [];

    warnings?.forEach((warning) => {
        let message = '';
        let appliesTo: FormSteps[] = [];

        if (warning.category === WarningCategories.PATIENT_SCHEDULING_CONFLICT) {
            message = WarningMessages.SCHEDULE_CONFLICT_HCP;
            appliesTo = [FormSteps.PATIENT, FormSteps.ACTIVITY, FormSteps.CARE];
        } else if (warning.category === WarningCategories.WARNING_GEOLOCATION_MISSING) {
            message = WarningMessages.ADDRESS_WARNING;
            appliesTo = [FormSteps.PATIENT];
        } else if (warning.category === WarningCategories.HCP_SCHEDULING_CONFLICT) {
            message = `${getHcpName(users, warning.data?.hcpId)} has another job within this time period`;
            appliesTo = [FormSteps.ACTIVITY, FormSteps.CARE];
        } else if (warning.category === WarningCategories.HCP_AVAILABILITY_CONFLICT) {
            message = `The job is outside of ${getHcpName(
                users,
                warning.data?.hcpId,
            )} working hours`;
            appliesTo = [FormSteps.ACTIVITY, FormSteps.CARE];
        }

        if (message !== '') {
            transformedWarnings.push({
                category: warning.category,
                isActive: true,
                message,
                showOnSection: appliesTo,
            });
        }
    });

    return transformedWarnings;
}

interface IProps {
    job: Patient;
    sectionShown?: FormSteps;
}

export const useWarningMessageViewModel = ({ job, sectionShown }: IProps) => {
    const {
        hasAddressWarning,
        hasEarliestTimeBreach,
        hasLatestTimeBreach,
        isPartiallyAssigned,
        hasS1ReferralWarning,
        hasPlannedTimeInPast,
        hcp1SpecificWarnings,
        hcp2SpecificWarnings,
    } = useWarningConditions({ job });

    const {
        RootStore: {
            ucrStore: { warnings: allWarnings },
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    // Getting the current warnings outside the memo to ensure we are
    const jobSpecificWarningsInStore = allWarnings[job.id];

    return useMemo(() => {
        // List of all warning conditions leading to a
        const allWarningMessages: WarningMessageViewModelWarning[] = [
            {
                category: WarningCategories.PLANNED_START_IN_PAST,
                isActive: hasPlannedTimeInPast,
                message: WarningMessages.PLANNED_START_IN_PAST,
                showOnSection: [FormSteps.ACTIVITY, FormSteps.CARE],
            },
            {
                category: WarningCategories.WARNING_GEOLOCATION_MISSING,
                isActive: hasAddressWarning,
                message: WarningMessages.ADDRESS_WARNING,
                showOnSection: [FormSteps.PATIENT],
            },
            {
                category: WarningCategories.BEFORE_EARLIEST_TIME,
                isActive: hasEarliestTimeBreach,
                message: WarningMessages.BEFORE_EARLIEST_TIME,
                showOnSection: [FormSteps.ACTIVITY, FormSteps.CARE],
            },
            {
                category: WarningCategories.AFTER_LATEST_TIME,
                isActive: hasLatestTimeBreach,
                message: WarningMessages.AFTER_LATEST_TIME,
                showOnSection: [FormSteps.ACTIVITY, FormSteps.CARE],
            },
            {
                category: WarningCategories.MISSING_HCP1,
                isActive: isPartiallyAssigned && !job.hcpId,
                message: WarningMessages.MISSING_HCP1,
                showOnSection: [FormSteps.ACTIVITY, FormSteps.CARE],
            },
            {
                category: WarningCategories.MISSING_HCP2,
                isActive: isPartiallyAssigned && !job.buddyId,
                message: WarningMessages.MISSING_HCP2,
                showOnSection: [FormSteps.ACTIVITY, FormSteps.CARE],
            },
            {
                category: WarningCategories.MISSING_S1_REFERRAL,
                isActive: hasS1ReferralWarning,
                message: WarningMessages.MISSING_S1_REFERRAL,
                showOnSection: [FormSteps.REFERRAL, FormSteps.ACTIVITY, FormSteps.CARE],
            },
            {
                category: WarningCategories.INCOMPATIBLE_LANGUAGE_HCP1,
                isActive: hcp1SpecificWarnings?.hasLanguageWarning,
                message: getLanguageWarningText(getHcp(users, job.hcpId)),
                showOnSection: [FormSteps.PATIENT, FormSteps.ACTIVITY, FormSteps.CARE],
            },
            {
                category: WarningCategories.INCOMPATIBLE_LANGUAGE_HCP2,
                isActive: hcp2SpecificWarnings?.hasLanguageWarning,
                message: getLanguageWarningText(getHcp(users, job.buddyId)),
                showOnSection: [FormSteps.PATIENT, FormSteps.ACTIVITY, FormSteps.CARE],
            },
            {
                category: WarningCategories.INCOMPATIBLE_GENDER_HCP1,
                isActive: hcp1SpecificWarnings?.hasGenderWarning,
                message: getGenderWarningText(getHcp(users, job.hcpId)),
                showOnSection: [FormSteps.PATIENT, FormSteps.ACTIVITY, FormSteps.CARE],
            },
            {
                category: WarningCategories.INCOMPATIBLE_GENDER_HCP2,
                isActive: hcp2SpecificWarnings?.hasGenderWarning,
                message: getGenderWarningText(getHcp(users, job.buddyId)),
                showOnSection: [FormSteps.PATIENT, FormSteps.ACTIVITY, FormSteps.CARE],
            },
        ];

        // Add the job specific warnings that have been determined elsewhere
        // Future work - redesign how these warnings are populated
        const jobSpecificWarnings = transformBackendWarnings({
            warnings: jobSpecificWarningsInStore,
            users,
        });
        allWarningMessages.push(...jobSpecificWarnings);

        // Ensure only relevant messages will be returned to the users
        let filteredWarningMessages = allWarningMessages.filter(
            (warningCondition) => warningCondition.isActive,
        );

        filteredWarningMessages = filterDuplicateWarningMessages({
            warnings: filteredWarningMessages,
        });

        // If the user is viewing a specific section, we need to further filter the displayed messages
        filteredWarningMessages = filterWarningMessagesBySection({
            warnings: filteredWarningMessages,
            sectionShown: sectionShown,
        });

        return {
            warningMessages: filteredWarningMessages,
            hasWarningMessages: filteredWarningMessages.length > 0,
        };
    }, [
        hasPlannedTimeInPast,
        hasAddressWarning,
        hasEarliestTimeBreach,
        hasLatestTimeBreach,
        isPartiallyAssigned,
        job.hcpId,
        job.buddyId,
        hasS1ReferralWarning,
        hcp1SpecificWarnings?.hasLanguageWarning,
        hcp1SpecificWarnings?.hasGenderWarning,
        users,
        hcp2SpecificWarnings?.hasLanguageWarning,
        hcp2SpecificWarnings?.hasGenderWarning,
        jobSpecificWarningsInStore,
        sectionShown,
    ]);
};
