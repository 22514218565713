import { baseUrl, retryable, rootUrl } from './baseApi';

export const getUser = (userId: string, authToken: string) => {
    return retryable(() =>
        fetch(`${rootUrl}/users/${userId}`, {
            method: 'GET',
            headers: {
                Authorization: authToken,
                'Cache-Control': 'no-cache',
            },
        }),
    );
};

export const updateUser = (userId: string, userData: any, authToken: string) => {
    return fetch(`${baseUrl}/users/${userId}`, {
        method: 'PATCH',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
        body: JSON.stringify(userData),
    });
};

export const updateUserFromController = (
    userId: string,
    userData: string | { [key: string]: any },
    authToken: string,
) => {
    return fetch(`${baseUrl}/users/${userId}/staffpins`, {
        method: 'POST',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
        body: JSON.stringify(userData),
    });
};

export const resetPassword = (userId: string, authToken: string) => {
    return fetch(`${baseUrl}/users/${userId}/reset`, {
        method: 'POST',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
        body: JSON.stringify({}),
    });
};

export const getUsers = (authToken: string) => {
    return fetch(`${baseUrl}/users`, {
        method: 'GET',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
    });
};

export const addUser = (userData: string | { [key: string]: any }, authToken: string) => {
    return fetch(`${baseUrl}/users/unified`, {
        method: 'POST',
        headers: {
            Authorization: authToken,
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(userData),
    });
};

export const updateUserAbsence = (userId: string, userData: any, authToken: string) => {
    return fetch(`${baseUrl}/users/${userId}/absence`, {
        method: 'POST',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
        body: JSON.stringify(userData),
    });
};

export const setHcpFunction = (userId: string, userData: any, authToken: string) => {
    return fetch(`${baseUrl}/users/${userId}/hcp-function`, {
        method: 'POST',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
        body: JSON.stringify(userData),
    });
};

export const getHcpFunctions = (date: string, authToken: string) => {
    return fetch(`${baseUrl}/users/functions?date=${date}`, {
        method: 'GET',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
    });
};
