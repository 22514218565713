import { AdminTypeV2, Pathway } from '@doc-abode/data-models';

import { adminGenericSchema } from './schemas/adminGeneric';
import { genericSchema } from './schemas/generic';
import { JobType } from './schemas/JobTypes';
import { patientSchema } from './schemas/patient';
import { referralSchema } from './schemas/referral';
import { visitGenericSchema } from './schemas/visitGeneric';

type followUpCopy = JobType[];

export type valueType =
    | string
    | number
    | null
    | boolean
    | string[]
    | number[]
    | undefined
    | Record<string, string | number | null | boolean | string[] | number[] | undefined>;

export interface FormFieldRecord {
    value?: valueType;
    key: string;
    followUpCopy: followUpCopy;
    transformTo?: string;
    transformValue?: (props: JobCreatorIProps) => any;
    onCreateOnly?: boolean;
    onUpdateOnly?: boolean;
    isAnIndex?: boolean;
}

interface JobCreatorIProps {
    typeOfJob: JobType;
    values: Record<string, any>;
    followUp?: boolean;
    onCreate?: boolean;
    pathways: Pathway[];
    adminTypes: AdminTypeV2[];
}

// The ordering of these schemas are important, as the duration in the genericSchema is set based on the service / activityType set.
export const adminSchema = [...patientSchema, ...adminGenericSchema, ...genericSchema];
export const visitSchema = [
    ...patientSchema,
    ...referralSchema,
    ...visitGenericSchema,
    ...genericSchema,
];

export const jobCreator = (props: JobCreatorIProps) => {
    if (props.typeOfJob === JobType.ADMIN) {
        return doJobCreation({
            typeOfJob: JobType.ADMIN,
            values: props.values,
            formFields: adminSchema,
            followUp: props.followUp ?? false,
            onCreate: props.onCreate ?? false,
            pathways: props.pathways,
            adminTypes: props.adminTypes,
        });
    } else if (props.typeOfJob === JobType.VISIT) {
        return doJobCreation({
            typeOfJob: JobType.VISIT,
            values: props.values,
            formFields: visitSchema,
            followUp: props.followUp ?? false,
            onCreate: props.onCreate ?? false,
            pathways: props.pathways,
            adminTypes: props.adminTypes,
        });
    }
};

interface CreationIProps extends JobCreatorIProps {
    formFields: FormFieldRecord[];
}

export const doJobCreation = (props: CreationIProps) => {
    let schema: Record<string, valueType> = {};

    // initial setting of values: either keep the same or transform
    for (const field of props.formFields) {
        // if it a follow up and we want to copy the fields else continue.
        if (props.followUp && !field.followUpCopy.includes(props.typeOfJob)) {
            continue;
        }

        // if the field is on create only and it not on create.
        if (field.onCreateOnly && !props.onCreate) {
            continue;
        }

        // certain fields should only be created onUpdate such as abort information, current timestamps so on.
        if (field.onUpdateOnly && props.onCreate) {
            continue;
        }

        schema[field.transformTo ?? field.key] = field.transformValue
            ? field.transformValue(props)
            : props.values[field.key];

        // indexes on create cannot be null, they must either be undefined or a value.
        const isNullorEmptyString =
            schema[field.transformTo ?? field.key] === null ||
            schema[field.transformTo ?? field.key] === '';

        if (field.isAnIndex && isNullorEmptyString && props.onCreate) {
            schema[field.transformTo ?? field.key] = undefined;
        }
    }

    return schema;
};
