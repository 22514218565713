import { observer } from 'mobx-react';
import { FC } from 'react';

import { IconAdd, IconRemove } from '../../../../../helpers/ucr/icons';
import { Button, ButtonColors, ButtonSizes } from '../../../../v2/components';
import { useCollapseAndExpandButtonsViewModel } from './useCollapseAndExpandButtonsViewModel';

interface IProps {}

export const CollapseAndExpandButtons: FC<IProps> = observer(() => {
    const { expandAll, collapseAll } = useCollapseAndExpandButtonsViewModel();

    return (
        <div className="ucr__calendar-buttons">
            <div className="ucr__calendar-buttons-button-wrapper">
                <Button
                    color={ButtonColors.GREY}
                    size={ButtonSizes.SMALL}
                    Icon={IconRemove}
                    name="Collapse all"
                    clickEvent={collapseAll}
                />
            </div>
            <div className="ucr__calendar-buttons-button-wrapper">
                <Button
                    color={ButtonColors.GREY}
                    size={ButtonSizes.SMALL}
                    Icon={IconAdd}
                    name="Expand all"
                    clickEvent={expandAll}
                    testId="calendarViewExpandAll"
                />
            </div>
        </div>
    );
});

export default CollapseAndExpandButtons;
