import { S1ClientConnectionStatus } from '@doc-abode/data-models';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { TSortValue } from '../../../../../interfaces/sort';
import { TableHeader } from '../../../ucr/components/listView/ListView';
import ConnectedClientRow from './ConnectedClientRow';
import { useConnectedClientsTableViewModel } from './useConnectedClientsTableViewModel';

const FilterSelectContainer: FC = ({ children }) => {
    return (
        <div className="s1-connected-clients__status-filter-container">
            <p>Filter by status:</p>
            {children}
        </div>
    );
};

const ConnectedClientsTable: FC = () => {
    const {
        isLoading,
        isError,
        isSuccess,
        s1ClientConnectionStatuses,
        requestSort,
        getClassNamesFor,
        FilterSelect,
    } = useConnectedClientsTableViewModel();

    return (
        <div className="s1-connected-clients__main">
            <FilterSelectContainer>
                <FilterSelect />
            </FilterSelectContainer>
            <table className="s1-connected-clients__containerWidth">
                <TableHeader
                    headerKey="s1-connected-clients"
                    headerRowClassName="s1-connected-clients__list-row s1-connected-clients__list-row--sticky"
                    headerColumns={headerProperties}
                    headerColumnClassName="s1-connected-clients__header"
                    getClassNamesFor={getClassNamesFor}
                    sort={requestSort}
                />
                {isSuccess && s1ClientConnectionStatuses.length > 0 && (
                    <tbody>
                        {s1ClientConnectionStatuses.map((clientConnectionStatus, index) => (
                            <ConnectedClientRow
                                clientConnectionStatus={clientConnectionStatus}
                                index={index}
                                key={`${generateUniqueRowKey(clientConnectionStatus)}` + index}
                            />
                        ))}
                    </tbody>
                )}
            </table>
            {isLoading && (
                <EmptyConnectedClientsTable message={loadingStateMessage} state="loading" />
            )}
            {isError && <EmptyConnectedClientsTable message={errorStateMessage} state="error" />}
            {isSuccess && s1ClientConnectionStatuses.length === 0 && (
                <EmptyConnectedClientsTable message={emptyStateMessage} state="empty" />
            )}
        </div>
    );
};

const emptyStateMessage: string = 'There are no recently active clients';
const errorStateMessage: string = 'An error occurred while fetching connected clients';
const loadingStateMessage: string = 'Loading...';

const EmptyConnectedClientsTable: FC<{ message: string; state: 'loading' | 'error' | 'empty' }> = ({
    message,
    state,
}) => {
    return (
        <div
            className={cn('s1-connected-clients__empty', {
                's1-connected-clients__empty--loading': state === 'loading',
                's1-connected-clients__empty--error': state === 'error',
                's1-connected-clients__empty--empty': state === 'empty',
            })}
        >
            {message}
        </div>
    );
};

const generateUniqueRowKey = (status: S1ClientConnectionStatus): string => {
    return `MACHINEID#${status.machineId}#USERID#${status.userId}#ORG#${status.organisation}`;
};

const headerProperties: TSortValue[] = [
    { headerName: 'User name', columnName: 'userId', sortable: true },
    { headerName: 'Client version', columnName: 'clientVersion', sortable: true },
    { headerName: 'Last activity', columnName: 'lastActivity', sortable: true },
    { headerName: 'Status', columnName: 'status', sortable: true },
];

export default observer(ConnectedClientsTable);
