import { JobStatus } from '@doc-abode/data-models';
import { observer } from 'mobx-react';

import { IHcp } from '../../../../../../interfaces';
import { RouteHcpListRow } from './RouteHcpListRow';
import { useRouteHcpListViewModel } from './useRouteHcpListViewModel';

interface IProps {
    isCreateRoute: boolean;
    routeStatus?: JobStatus;
    selectedUsers: string[];
    onToggleUser: (
        userId: string,
        selected: boolean,
        event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>,
    ) => void;
}

export const RouteHcpList = observer(
    ({ isCreateRoute, routeStatus, selectedUsers, onToggleUser }: IProps) => {
        const { enabledHcpUsers, areHcpsSelectable } = useRouteHcpListViewModel({
            routeStatus,
        });

        return (
            <table className="bp5-html-table users-table bp5-interactive">
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>User ID</th>
                        <th>HCP Type(s)</th>
                    </tr>
                </thead>
                <tbody>
                    {enabledHcpUsers.map((user: IHcp) => {
                        return (
                            <RouteHcpListRow
                                key={`route-hcp-list-${user.userId}`}
                                isCreateRoute={isCreateRoute}
                                onSelect={onToggleUser}
                                user={user}
                                selectedUsers={selectedUsers}
                                areHcpsSelectable={areHcpsSelectable}
                            />
                        );
                    })}
                </tbody>
            </table>
        );
    },
);
