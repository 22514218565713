import { Warning } from '@doc-abode/data-models';

import { WarningCategories, WarningMessages } from '../../../../../helpers/ucr/getWarnings';

type StringOrBoolean = string | boolean;

interface WarningInterface {
    warnings: Warning[];
    values?: any;
}

export interface AffectedFieldsInterface {
    buddyId?: StringOrBoolean;
    hcpId?: StringOrBoolean;
    visitDate?: StringOrBoolean;
    startTime?: StringOrBoolean;
    nhsNumber?: StringOrBoolean;
}

export const warningHighlight = ({
    warnings,
    values,
}: WarningInterface): AffectedFieldsInterface => {
    const affectedFields: AffectedFieldsInterface = {
        buddyId: false,
        hcpId: false,
        visitDate: false,
        startTime: false,
        nhsNumber: false,
    };

    if (warnings && warnings.length > 0) {
        warnings.forEach((warning) => {
            if (
                warning.category === WarningCategories.HCP_AVAILABILITY_CONFLICT ||
                warning.category === WarningCategories.HCP_SCHEDULING_CONFLICT
            ) {
                if (values.hcpId === warning?.data?.hcpId) {
                    affectedFields.hcpId = warning.message;
                } else if (values.buddyId === warning?.data?.hcpId) {
                    affectedFields.buddyId = warning.message;
                }
            }
            if (warning.category === WarningCategories.PATIENT_SCHEDULING_CONFLICT) {
                affectedFields.nhsNumber = warning.message;
                affectedFields.startTime = warning.message;
                affectedFields.visitDate = warning.message;
            }
            if (warning.message === WarningMessages.PLANNED_START_IN_PAST) {
                affectedFields.startTime = warning.message;
                affectedFields.visitDate = warning.message;
            }
        });
    }

    return affectedFields;
};
