import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import {
    Button,
    Callout,
    Collapse,
    FormGroup,
    HTMLSelect,
    Icon,
    NumericInput,
} from '@blueprintjs/core';
import { TimePicker } from '@blueprintjs/datetime';
import { DateInput3 } from '@blueprintjs/datetime2';
import { HCPType, Vaccination } from '@doc-abode/data-models';
import moment from 'moment';
import React from 'react';

import { formatDisplayDate, parseDateStringToJSDate } from '../../../../modules/helpers/formatData';
import Loader from '../../../../modules/helpers/Loader';
import Modal from '../../../../modules/modal/Modal';
import { Itinerary, ItineraryItemSummary, MapWrapper, Warnings } from '../../components';
import { RouteHcpList } from '../../components/route/routeHcpList/RouteHcpList';
import { RouteSummaryCard } from '../../components/route/RouteSummaryCard/RouteSummaryCard';
import { friendlyRouteTypes } from '../../utils';
import useCreateRoutesViewController from './useCreateRoutesViewController';

interface ICreateRoutesFooterProps {
    onSubmit: () => Promise<void>;
    disabled: boolean;
}
const CreateRoutesFooter: React.FC<ICreateRoutesFooterProps> = ({ onSubmit, disabled }) => (
    <Button intent="success" large onClick={onSubmit} icon="play" disabled={disabled}>
        Generate routes
    </Button>
);

interface IConfirmRoutesFooterProps {
    onConfirm: () => void;
}
const ConfirmRoutesFooter: React.FC<IConfirmRoutesFooterProps> = ({ onConfirm }) => (
    <Button intent="success" large onClick={onConfirm} icon="tick">
        Accept routes
    </Button>
);

interface IConfirmedRoutesFooterProps {
    onClose: () => void;
}
const ConfirmedRoutesFooter: React.FC<IConfirmedRoutesFooterProps> = ({ onClose }) => (
    <Button large onClick={onClose} icon="cross">
        Close
    </Button>
);
interface ICreateRoutesProps {
    onClose: () => void;
    jobs: Vaccination[];
    getUserSession: () => Promise<any>;
    refetchRoutes: (
        variables?: Partial<OperationVariables> | undefined,
    ) => Promise<ApolloQueryResult<any>>;
    deselect: (id: string) => void;
    setVaccinations: (vaccinations: any) => void;
    hubs: any;
    apiKey: string;
    vaccinationDuration: Record<string, string>;
    vaccinationDetails: any;
    hcpType: HCPType[];
    isSuperuser: boolean;
    doseInterval: Record<string, Record<string, number>>;
}

const CreateRoutes: React.FC<ICreateRoutesProps> = ({
    onClose,
    jobs,
    getUserSession,
    refetchRoutes,
    deselect,
    setVaccinations,
    hubs,
    apiKey,
    vaccinationDuration,
    vaccinationDetails,
    hcpType,
    isSuperuser,
    doseInterval,
}) => {
    const {
        startTime,
        endTime,
        selectedUsers,
        requestingRoutes,
        confirmingRoutes,
        confirmedRoutes,
        routes,
        capacity,
        hub,
        vaccinationsWithWarnings,
        didError,
        actualErrorForDidError,
        smsResults,
        selectedRoute,
        setSelectedRoute,
        showPatientList,
        setShowPatientList,
        loadingWarnings,
        selectedDateErrorMessage,
        onConfirm,
        onStartTimeChange,
        onSubmit,
        disableSubmit,
        routeType,
        onRemoveWarningPatients,
        multipleHubWarning,
        onEndTimeChange,
        isCovidRoute,
        onChangeHub,
        onCapacityChange,
        hubOptions,
        onToggleUser,
        selectedDateTimeWarnings,
        newSelectedDate,
        onNewSelectedDateChange,
        routeForItinerary,
    } = useCreateRoutesViewController({
        doseInterval,
        vaccinationDetails,
        vaccinationDuration,
        hubs,
        getUserSession,
        setVaccinations,
        refetchRoutes,
        jobs,
        deselect,
        hcpType,
    });

    return (
        <Modal
            title="Create routes"
            onClose={onClose}
            footer={
                requestingRoutes || confirmingRoutes ? null : confirmedRoutes ? (
                    <ConfirmedRoutesFooter onClose={onClose} />
                ) : routes.length > 0 ? (
                    <ConfirmRoutesFooter onConfirm={onConfirm} />
                ) : (
                    <CreateRoutesFooter onSubmit={onSubmit} disabled={disableSubmit} />
                )
            }
            shadow
        >
            <div className="create-routes">
                {loadingWarnings ? (
                    <Loader fullscreen={false} />
                ) : confirmedRoutes ? (
                    <>
                        <Callout
                            intent="success"
                            icon="tick-circle"
                            className="create-routes__callout"
                        >
                            Routes confirmed.
                        </Callout>
                        {smsResults &&
                            (smsResults.successes?.length > 0 ||
                                smsResults.failures?.length > 0) && (
                                <>
                                    <h3 className="h3">SMS messages sent to patients</h3>
                                    <table className="bp5-html-table">
                                        <thead>
                                            <tr>
                                                <th>NHS number</th>
                                                <th>Name</th>
                                                <th>Contact number</th>
                                                <th>Success</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {smsResults.successes.map(
                                                ({
                                                    contactNumber,
                                                    firstName,
                                                    lastName,
                                                    nhsNumber,
                                                }) => {
                                                    return (
                                                        <tr key={nhsNumber}>
                                                            <td>{nhsNumber}</td>
                                                            <td>
                                                                {firstName} {lastName}
                                                            </td>
                                                            <td>{contactNumber}</td>
                                                            <td className="create-routes__sms--sent">
                                                                <Icon icon="tick-circle" /> Sent
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                            {smsResults.failures.map(({ contactNumber }) => {
                                                const job = jobs.find(
                                                    (job) => job.contactNumber === contactNumber,
                                                );
                                                if (!job) return null;
                                                const { nhsNumber, firstName, lastName } = job;
                                                return (
                                                    <tr key={nhsNumber}>
                                                        <td>{nhsNumber}</td>
                                                        <td>
                                                            {firstName} {lastName}
                                                        </td>
                                                        <td>{contactNumber}</td>
                                                        <td className="create-routes__sms--not-sent">
                                                            <Icon icon="ban-circle" /> Not sent
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </>
                            )}
                    </>
                ) : confirmingRoutes ? (
                    <div className="vaccinations__loading">
                        <Loader fullscreen={false} />
                        <p className="create-routes__please-wait">Confirming routes...</p>
                    </div>
                ) : routes.length > 0 ? (
                    <>
                        <Callout intent="success" icon="tick-circle">
                            Generated {routes.length} routes covering{' '}
                            {routes.reduce(
                                (total, route) =>
                                    (total += route.itinerary.instructions.filter(
                                        ({ instructionType }) =>
                                            instructionType === 'VisitLocation',
                                    ).length),
                                0,
                            )}{' '}
                            patients.
                        </Callout>
                        <div className="create-routes__summaries">
                            {routes.map((route, index) => {
                                const selected = route.id === selectedRoute;
                                return (
                                    <RouteSummaryCard
                                        index={index}
                                        route={route}
                                        showJobStatusTag={false}
                                        key={`create-route-route-summary-card-${route.id}`}
                                    >
                                        <Button
                                            text={selected ? 'Hide itinerary' : 'Show itinerary'}
                                            icon={selected ? 'cross' : 'properties'}
                                            className="create-routes__summary-btn"
                                            onClick={() =>
                                                setSelectedRoute(selected ? null : route.id)
                                            }
                                        />
                                    </RouteSummaryCard>
                                );
                            })}
                        </div>
                        {selectedRoute ? (
                            routeForItinerary ? (
                                <Itinerary
                                    hub={hub}
                                    instructions={routeForItinerary.itinerary.instructions}
                                    openInNewWindow
                                />
                            ) : null
                        ) : (
                            <MapWrapper
                                itineraries={routes.map(({ itinerary }) => itinerary)}
                                apiKey={apiKey}
                            />
                        )}
                    </>
                ) : requestingRoutes ? (
                    <div className="vaccinations__loading">
                        <Loader fullscreen={false} />
                        <p className="create-routes__please-wait">
                            Calculating route(s). Please wait, this could take several minutes...
                        </p>
                    </div>
                ) : (
                    <>
                        {didError && actualErrorForDidError && (
                            <Callout intent="danger" className="create-routes__callout">
                                Route generation was unsuccessful. Reported error:{' '}
                                {actualErrorForDidError.message}
                                {actualErrorForDidError.details?.map((detail, idx) => (
                                    <div key={`error_detail_${idx}`}>{detail}</div>
                                ))}
                            </Callout>
                        )}
                        {jobs.length > 0 ? (
                            <div className="create-routes__patients-selected">
                                <p>
                                    <strong>Route type:</strong>{' '}
                                    {
                                        friendlyRouteTypes[
                                            routeType as keyof typeof friendlyRouteTypes
                                        ]
                                    }
                                </p>
                                <span className="create-routes__patients-selected-summary">
                                    <strong>{jobs.length} patients selected.</strong>
                                    <Button
                                        className="create-routes__patients-selected-button"
                                        onClick={() => setShowPatientList(!showPatientList)}
                                        icon={showPatientList ? 'eye-off' : 'eye-open'}
                                    >
                                        {showPatientList ? 'Hide' : 'Show'}
                                    </Button>
                                </span>
                                <Collapse isOpen={showPatientList}>
                                    <ul>
                                        {jobs.map((job) => (
                                            <li key={job.id}>
                                                <ItineraryItemSummary job={job} openInNewWindow />
                                            </li>
                                        ))}
                                    </ul>
                                </Collapse>
                            </div>
                        ) : (
                            <Callout intent="danger" className="create-routes__callout">
                                There are no patients selected, please select at least one patient
                                and try again.
                            </Callout>
                        )}
                        <h3 className="h3">Select route options</h3>
                        {vaccinationsWithWarnings.length > 0 && (
                            <Callout intent="danger" className="create-routes__callout">
                                The following patients have validation warnings, please see below
                                for details.
                                <Warnings warnings={vaccinationsWithWarnings} />
                                <Button
                                    text="Remove affected patient(s) from selection"
                                    onClick={onRemoveWarningPatients}
                                    outlined
                                />
                            </Callout>
                        )}
                        {multipleHubWarning && (
                            <Callout intent="danger" className="create-routes__callout">
                                The selected patients belong multiple hubs that cannot be combined
                                into a single route. Please refine your selection and try again.
                            </Callout>
                        )}
                        <div className="create-routes__select-date-time">
                            <div className="create-routes__date-time-controls">
                                <FormGroup label="Date" labelFor="date">
                                    <DateInput3
                                        inputProps={{ id: 'date' }}
                                        formatDate={formatDisplayDate}
                                        parseDate={parseDateStringToJSDate}
                                        onChange={(date) => {
                                            date && onNewSelectedDateChange(new Date(date));
                                        }}
                                        minDate={isSuperuser ? undefined : new Date()}
                                        maxDate={moment().add(1, 'year').toDate()}
                                        value={newSelectedDate?.toISOString()}
                                    />
                                </FormGroup>
                                <FormGroup label="Start time">
                                    <TimePicker
                                        onChange={onStartTimeChange}
                                        value={startTime}
                                        selectAllOnFocus
                                        maxTime={endTime}
                                    />
                                </FormGroup>
                                <FormGroup label="End time">
                                    <TimePicker
                                        onChange={onEndTimeChange}
                                        value={endTime}
                                        minTime={startTime}
                                        selectAllOnFocus
                                    />
                                </FormGroup>
                                {isCovidRoute && (
                                    <FormGroup label="Max. doses *">
                                        <NumericInput
                                            className="create-routes__numeric-input"
                                            onValueChange={onCapacityChange}
                                            value={capacity}
                                            min={1}
                                            selectAllOnFocus
                                            large
                                        />
                                    </FormGroup>
                                )}
                                <FormGroup label="Start/end location">
                                    <HTMLSelect
                                        onChange={onChangeHub}
                                        value={hub!.id}
                                        large
                                        options={hubOptions}
                                        disabled={multipleHubWarning}
                                    />
                                </FormGroup>
                            </div>
                            <div className="form-error-message">{selectedDateErrorMessage}</div>
                            {isCovidRoute && (
                                <span>
                                    * Applies only to COVID-19 vaccines, this is usually based on
                                    vial size
                                </span>
                            )}
                        </div>
                        <h3 className="h3">Select HCPs</h3>
                        <RouteHcpList
                            isCreateRoute={true}
                            selectedUsers={selectedUsers}
                            onToggleUser={onToggleUser}
                        />
                        {selectedDateTimeWarnings.length > 0 && (
                            <Callout intent="warning" className="create-routes__callout">
                                {selectedDateTimeWarnings.map((warning) => (
                                    <li key={warning}>{warning}</li>
                                ))}
                            </Callout>
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default CreateRoutes;
