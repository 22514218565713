import { Button } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import Select from 'react-select';

import { getHcp } from '../../../../helpers/ucr/getHcp';
import { useHcpSuggestionOptions } from '../../../../hook/useHcpSuggestionOptions';
import useStores from '../../../../hook/useStores';
import RootStore from '../../../../stores/RootStore';
import { compareByLabels } from '../../../modules/helpers/sortFunctions';
import Suggest from '../../../v2/form/Suggest';
import { VisitValuesType } from '../blocks/panels/VisitDetailsTypes';

interface IProps {
    patient: VisitValuesType;
    isFirstUser?: boolean;
    onHcpReassignment?: (hcpId: string) => void;
    insideForm?: boolean;
    disabled?: boolean;
    displayErrors?: string;
    includeUnavailableHcps?: boolean;
    menuPlacement?: 'auto' | 'top' | 'bottom';
    isAdmin?: boolean;
    ariaLabel?: string;
}

interface IHcpSelectOption {
    value: string;
    label: string;
    isWorking?: boolean;
    isDisabled?: boolean;
}

export const HcpSelect: FC<IProps> = ({
    patient,
    isFirstUser = true,
    onHcpReassignment = () => {},
    insideForm = true,
    disabled = false,
    includeUnavailableHcps = false,
    displayErrors,
    menuPlacement,
    isAdmin = false,
    ariaLabel,
}) => {
    const {
        RootStore: {
            usersStore: { users },
            userStore: { user, isHcp },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { hcpId, buddyId } = patient;

    const [selectedValue, setSelectedValue] = useState(isFirstUser ? hcpId : buddyId);

    const staff = getHcp(users, isFirstUser ? hcpId : buddyId);

    const hcpOptions = useHcpSuggestionOptions(patient);

    const hcpList: IHcpSelectOption[] = [
        ...hcpOptions
            .map((option) => ({
                ...option,
                ...(!includeUnavailableHcps && { isDisabled: !option.isWorking }),
            }))
            .filter((l: any) => {
                return (
                    (includeUnavailableHcps ||
                        l.isWorking ||
                        l.value === (staff ? staff.userId : '')) &&
                    (isFirstUser ? l.value !== buddyId : l.value !== hcpId)
                );
            })
            .sort(compareByLabels),
    ];

    if (!isAdmin) {
        hcpList.unshift({ label: 'Not set', value: '' });
    }

    const selectedHcp = hcpList.find((hcp) => hcp.value === (staff ? staff.userId : ''));

    const onItemSelect = (item: IHcpSelectOption) => {
        onHcpReassignment(item.value || '');
    };

    const handleUpdate = (value: string) => {
        setSelectedValue(value);
    };

    const displayAssignToMe = isHcp && user.username !== hcpId && user.username !== buddyId;

    const AssignToMeButton = displayAssignToMe && (
        <Button
            text="Assign to me"
            minimal
            intent="primary"
            className="underlined-text-button"
            onClick={() => {
                if (insideForm) {
                    handleUpdate(user.username);
                } else {
                    onHcpReassignment(user.username);
                }
            }}
        />
    );

    return insideForm ? (
        <div className="v2__form-group--pos-1-2">
            <Suggest
                name={isFirstUser ? 'hcpId' : 'buddyId'}
                displayErrors={displayErrors}
                className="v2__form-group--pos-1-2"
                disabled={disabled}
                value={selectedValue}
                options={hcpList}
                menuPlacement={menuPlacement || 'top'}
                aria-label={ariaLabel}
                handleUpdate={handleUpdate}
                testId="careDetails-hcpSelect"
            />
            {AssignToMeButton}
        </div>
    ) : (
        <div className="hcp-select-container" data-testid="careDetails-hcpSelect">
            <Select
                className="visit-details__select"
                classNamePrefix="visit-details__select"
                options={hcpList}
                value={selectedHcp}
                onChange={(option) => {
                    onItemSelect(option as IHcpSelectOption);
                }}
            />
            {AssignToMeButton}
        </div>
    );
};

export default observer(HcpSelect);
