import { JobStatus, Patient } from '@doc-abode/data-models';
import { formatNameLastMiddleFirst, getAbortedDetails } from '@doc-abode/helpers';
import moment from 'moment';
import { FC } from 'react';

import { genderMapping } from '../../../../../../constants/mainConst';
import getHcpName from '../../../../../../helpers/ucr/getHcpName';
import { getPathwayServiceOrAdminTypeLabels } from '../../../../../../helpers/ucr/getPathwayServiceOrAdminTypeLabels';
import { isAdminJob } from '../../../../../../helpers/ucr/isAdminJob';
import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import { formatDisplayDate } from '../../../../../modules/helpers/formatData';
import { useWarningConditions } from '../../../hooks/useWarningConditions';
import { IJobSummaryComponentProps, JobSummaryComponent, JobType } from './JobSummaryComponent';
import { IHCP } from './JobSummaryHeader';

export const JobSummaryContainer: FC<{ job: Patient; hcpid: string }> = ({ job, hcpid }) => {
    const {
        RootStore: {
            usersStore: { users },
            configStore: { pathways, adminTypes },
        },
    } = useStores<{ RootStore: RootStore }>();

    const {
        // Header
        firstName,
        middleName,
        lastName,
        staffRequired,
        hcpId,
        jobStatus,
        buddyId,
        buddyJobStatus,

        // Patient Details
        addressLine1,
        addressLine2,
        town,
        postCode,
        dateOfBirth,
        age,
        gender,

        // Visit and Activity Details
        referrer,

        // Notes
        notes,
    } = job;

    const hcpJobMapper = (id: string | null | undefined, status: JobStatus): IHCP | null => {
        return id
            ? {
                  isMain: hcpid === id,
                  name: getHcpName(users, id, false),
                  status: Patient.getFriendlyVisitStatus(status),
                  hcpID: id,
              }
            : null;
    };
    let hcps: (IHCP | null)[] = [hcpJobMapper(hcpId, jobStatus)];
    if (staffRequired === 2) {
        hcps.push(hcpJobMapper(buddyId, buddyJobStatus!));
    }

    ////////////////////////////////////////////////////////////////////////////
    // Aborted
    ////////////////////////////////////////////////////////////////////////////

    const isFirstUser = hcpid === job.hcpId;

    const aborted = getAbortedDetails(job, !isFirstUser);

    ////////////////////////////////////////////////////////////////////////////
    // Data Construction
    ////////////////////////////////////////////////////////////////////////////

    const isAdmin = isAdminJob(job);

    const {
        hasNonEnglishSpeaker: isNonEnglishSpoken,
        hasComplexCare: isCareComplex,
        hasCarRequired: isCarRequired,
    } = useWarningConditions({ job });

    const { pathwayDisplayLabel, serviceOrAdminTypeDisplayLabel } =
        getPathwayServiceOrAdminTypeLabels(job, pathways, adminTypes);

    const data: IJobSummaryComponentProps = {
        type: isAdmin ? JobType.ADMIN : JobType.VISIT,

        header: {
            name: formatNameLastMiddleFirst({ firstName, middleName, lastName }),
            hcps,
            currentHcp: hcpid,
        },

        data: {
            address: [
                [addressLine1, addressLine2, town]
                    .filter(Boolean)
                    .map((ln) => ln!.replace(/,\s*$/, ''))
                    .join(', '),
                postCode,
            ]
                .filter(Boolean)
                .join(' '),
            gender: gender ? genderMapping[gender] : genderMapping.not_provided,
            dateOfBirth: dateOfBirth && `${formatDisplayDate(dateOfBirth)} (age: ${age})`,
            ...aborted,
            pathway: pathwayDisplayLabel,
            referrer,
            service: serviceOrAdminTypeDisplayLabel,
            activity: serviceOrAdminTypeDisplayLabel,
            preVisitNotes: notes,
            isNonEnglishSpoken,
            isCareComplex,
            isCarRequired,
            availableFrom: job.availableFrom
                ? moment(job.availableFrom).format('HH:mm')
                : undefined,
            availableTo: job.availableTo ? moment(job.availableTo).format('HH:mm') : undefined,
        },
        patient: job,
    };

    return <JobSummaryComponent {...data} />;
};

export default JobSummaryContainer;
