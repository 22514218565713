import { v2 } from '@doc-abode/data-models';

/**
 * Utility to get a sorted list of abort reasons for a job cancellation.
 * The utility allows users to restrict the list to enabled types only, as well as only return those
 * types that are relevant for a part cancellation of a job that has multiple assignees
 * @param abortReasons list of all potential abort reasons
 * @param enabledOptionsOnly restricts results to active options only
 * @param appliesToIndividualJobAssignmentOnly if set to true, restricts results to abort reasons that can be used for a part cancellations. To cancel all (remaining) job parts the flag must be sent as false
 * @returns a sorted AbortReason[] whose values match the input criteria
 */
export function getSortedV2AbortReasons(
    abortReasons: v2.AssignmentStatusReason[],
    enabledOptionsOnly: boolean,
    appliesToIndividualJobAssignmentOnly: boolean,
) {
    return abortReasons
        .slice()
        .sort((a: v2.AssignmentStatusReason, b: v2.AssignmentStatusReason) =>
            a.reason.localeCompare(b.reason),
        )
        .filter((abortReason: v2.AssignmentStatusReason) => {
            if (enabledOptionsOnly && abortReason.isReverse) {
                // The caller requests active (i.e. enabled) options only
                // If an option is reversed, it must not be returned
                return false;
            }

            return !(
                appliesToIndividualJobAssignmentOnly &&
                !abortReason.appliesToIndividualJobAssignment
            );
        })
        .map((abortReason: v2.AssignmentStatusReason) => {
            return { label: abortReason.reason, value: abortReason.reason };
        });
}
