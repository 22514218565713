import { Button, FormGroup } from '@blueprintjs/core';
import { DateInput3 } from '@blueprintjs/datetime2';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Component } from 'react';

import { formatDisplayDate, parseDateStringToJSDate } from '../../modules/helpers/formatData';
import Loader from '../../modules/helpers/Loader';
import { RouteSummaryCard } from './components/route/RouteSummaryCard/RouteSummaryCard';
import MapView from './MapView';

const MapExperimental = inject('RootStore')(
    observer(
        class MapExperimental extends Component {
            state = {
                selectedDate: new Date(),
                focusedItineraryId: null,
            };

            _handleDateChange = (date) =>
                this.setState({ selectedDate: date, focusedItineraryId: null });

            _handleFocusRoute = (id) =>
                this.setState({
                    focusedItineraryId: id === this.state.focusedItineraryId ? null : id,
                });

            _handleViewRouteDetails = (id) => this.props.history.push(`/vaccinations/routes/${id}`);

            render() {
                const {
                    routesStore: { allJobs, loading },
                    configStore: { clientKeys },
                } = this.props.RootStore;
                const { selectedDate, focusedItineraryId } = this.state;

                const routes = allJobs.filter(
                    (job) =>
                        moment(selectedDate).isSame(moment(job.itinerary.route.startTime), 'day') &&
                        job.jobStatus !== 'CONTROLLER_ABORTED',
                );

                return (
                    <>
                        <aside className="vaccinations__tools">
                            <h3 className="h3">Date</h3>
                            <FormGroup>
                                <DateInput3
                                    locale={navigator.languages[0]}
                                    inputProps={{ id: 'date' }}
                                    formatDate={formatDisplayDate}
                                    parseDate={parseDateStringToJSDate}
                                    onChange={this._handleDateChange}
                                    value={selectedDate}
                                    fill
                                />
                            </FormGroup>
                            <h3 className="h3">Routes</h3>
                            {routes.map((route, index) => {
                                const focused = focusedItineraryId === route.itineraryId;
                                return (
                                    <RouteSummaryCard
                                        index={index}
                                        route={route}
                                        showJobStatusTag={true}
                                        key={`map-route-summary-card-${route.id}`}
                                    >
                                        <Button
                                            onClick={() => this._handleViewRouteDetails(route.id)}
                                            text="View details"
                                            icon="route"
                                            className="create-routes__summary-btn"
                                        />
                                        <Button
                                            onClick={() =>
                                                this._handleFocusRoute(route.itineraryId)
                                            }
                                            text="Focus"
                                            icon="eye-open"
                                            active={focused}
                                            className="create-routes__summary-btn"
                                        />
                                    </RouteSummaryCard>
                                );
                            })}
                        </aside>
                        <main className="vaccinations__main vaccinations__main--full">
                            {loading || !clientKeys.googleMaps ? (
                                <Loader fullscreen={false} />
                            ) : (
                                <MapView
                                    routes={routes}
                                    focusedItineraryId={focusedItineraryId}
                                    apiKey={clientKeys.googleMaps}
                                />
                            )}
                        </main>
                    </>
                );
            }
        },
    ),
);

export default MapExperimental;
