import { Checkbox, Radio } from '@blueprintjs/core';
import { observer } from 'mobx-react';

import { IHcp } from '../../../../../../interfaces';
import { useRouteHcpListRowViewModel } from './useRouteHcpListRowViewModel';

interface IProps {
    isCreateRoute: boolean;
    areHcpsSelectable: boolean;
    selectedUsers: string[];
    onSelect: (
        userId: string,
        selected: boolean,
        event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>,
    ) => void;
    user: IHcp;
}

export const RouteHcpListRow = observer(
    ({ isCreateRoute, areHcpsSelectable, selectedUsers, onSelect, user }: IProps) => {
        const { userId, userName, isUserAlreadySelected, formattedHcpTypes } =
            useRouteHcpListRowViewModel({ user, selectedUsers });

        return (
            <tr
                key={userId}
                className={`users-table__row ${isUserAlreadySelected ? 'users-table__row--selected' : ''}`}
                onClick={(event) =>
                    areHcpsSelectable
                        ? onSelect(userId, !isUserAlreadySelected, event)
                        : event.preventDefault()
                }
            >
                <td>
                    {isCreateRoute ? (
                        <Checkbox
                            checked={isUserAlreadySelected}
                            onChange={(event) => onSelect(userId, !isUserAlreadySelected, event)}
                            disabled={!areHcpsSelectable}
                            data-testid={`route-hcp-list-row-checkbox-${userId}`}
                        />
                    ) : (
                        <Radio
                            id={userId}
                            checked={isUserAlreadySelected}
                            onChange={(event) => onSelect(userId, !isUserAlreadySelected, event)}
                            disabled={!areHcpsSelectable}
                            data-testid={`route-hcp-list-row-radio-${userId}`}
                        />
                    )}
                </td>
                <td>{userName}</td>
                <td>{userId}</td>
                <td>{formattedHcpTypes}</td>
            </tr>
        );
    },
);
