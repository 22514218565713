import { JobStatus, Patient } from '@doc-abode/data-models';
import moment, { Moment } from 'moment';

export const getMomentDuration = (duration?: string): Moment => {
    /*
    duration is passed around as a datetime/moment where 01:30am is 1.5 hours

    Selecting a random date which is not a clock change as it can result in timing errors, 
    as the start of the day on a clock change day is not 0:00.
    */
    let momentDuration = moment('1970-01-01').startOf('day');

    if (!duration) {
        return momentDuration;
    }

    // Duration is in format HH:mm
    const hours = duration.substring(0, 2);
    const minutes = duration.substring(3);

    return momentDuration.hours(Number(hours)).minutes(Number(minutes));
};

export const getDateDuration = (duration?: string): Date => {
    return getMomentDuration(duration).toDate();
};

export const getDurationInMinutes = (duration?: string): number => {
    const [hours, minutes] = (duration || '').split(':');
    return Number.parseInt(hours) * 60 + Number.parseInt(minutes);
};

export const calculateDurationInMinutes = (endDateTime: string, startDateTime: string) => {
    return moment(endDateTime).diff(startDateTime, 'minutes');
};

export function getJobDuration(job: Patient, isBuddy: boolean): number {
    let jobStatus: JobStatus | undefined = job.jobStatus;
    let arrivedDateTime = job.arrivedDateTime;
    let finishedDateTime = job.finishedDateTime;
    let hcpAbortedDateTime = job.hcpAbortedDateTime;

    if (isBuddy) {
        jobStatus = job.buddyJobStatus;
        arrivedDateTime = job.buddyArrivedDateTime;
        finishedDateTime = job.buddyFinishedDateTime;
        hcpAbortedDateTime = job.buddyHcpAbortedDateTime;
    }

    const completionTime =
        JobStatus.HCP_ABORTED === jobStatus ? hcpAbortedDateTime : finishedDateTime;

    if (arrivedDateTime && completionTime) {
        return calculateDurationInMinutes(completionTime, arrivedDateTime);
    }

    return getDurationInMinutes(job.duration);
}
