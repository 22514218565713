import { formatNameLastMiddleFirst, getAgeFromDateOfBirth } from '@doc-abode/helpers';
import moment from 'moment';
import { FC } from 'react';

import { genderMapping } from '../../../../../constants/mainConst';
import { PatientListJob, PatientListPatient } from '../../../../../graphql/queries/patients';
import { nhsNumberFormatter } from '../../../../../helpers/presentation';
import { NotSet } from './NotSet';
import { PatientListJobDetails } from './PatientListJobDetails';
import { PatientListQuickActions } from './PatientListQuickActions';

type JobInfo = [string | undefined | null, string | undefined | null] | [];

function getLastCompletedJobInfo(lastCompletedJob: PatientListJob): JobInfo {
    if (!lastCompletedJob) {
        return [];
    }

    const {
        staffRequired,

        hcpId,
        arrivedDateTime,

        buddyId,
        buddyArrivedDateTime,
    } = lastCompletedJob;

    if (staffRequired !== 2) {
        return [hcpId, arrivedDateTime];
    }

    if (!buddyArrivedDateTime) {
        return [hcpId, arrivedDateTime];
    } else if (!arrivedDateTime) {
        return [buddyId, buddyArrivedDateTime];
    }

    const main = new Date(arrivedDateTime).getTime();
    const buddy = new Date(buddyArrivedDateTime).getTime();

    return main < buddy ? [hcpId, arrivedDateTime] : [buddyId, buddyArrivedDateTime];
}

function nextUpcomingJobInfo(nextUpcomingJob: PatientListJob): JobInfo {
    if (!nextUpcomingJob) {
        return [];
    }

    const { startDateTime, dateOfVisit, hcpId } = nextUpcomingJob || ({} as PatientListJob);
    const nextUpcomingJobDate =
        startDateTime || moment(dateOfVisit).hours(0).minutes(0).seconds(0).toISOString();
    const nextUpcomingJobHCP = hcpId;

    return [nextUpcomingJobHCP, nextUpcomingJobDate];
}

export const PatientListRow: FC<{ patient: PatientListPatient }> = ({ patient }) => {
    const { lastCompletedJob, nextUpcomingJob } = patient;
    const [nextUpcomingJobHCP, nextUpcomingJobDate] = nextUpcomingJobInfo(nextUpcomingJob);
    const [lastCompletedJobHCP, lastCompletedJobDate] = getLastCompletedJobInfo(lastCompletedJob);

    return (
        <tr>
            <td>{nhsNumberFormatter(patient.nhsNumber) || <NotSet />}</td>
            <td>{formatNameLastMiddleFirst(patient) || <NotSet />}</td>
            <td>
                {patient.dateOfBirth ? (
                    `${moment(patient.dateOfBirth).format('DD/MM/YYYY')} (Age: ${getAgeFromDateOfBirth(
                        patient.dateOfBirth,
                    )})`
                ) : (
                    <NotSet />
                )}
            </td>
            <td>{genderMapping[patient.gender!] || patient.gender || <NotSet />}</td>
            <td>{patient.postcode?.toUpperCase() || <NotSet />}</td>
            <td>
                <div
                    className={`ucr__patient-list-admission-status
                ucr__patient-list-admission-status${
                    patient.admission?.isAdmitted ? '__admitted' : '__discharged'
                }`}
                >
                    {patient.admission?.isAdmitted ? 'Current' : 'Discharged'}{' '}
                </div>
            </td>
            <PatientListJobDetails
                job={lastCompletedJob}
                date={lastCompletedJobDate}
                hcp={lastCompletedJobHCP}
            />
            <PatientListJobDetails
                job={nextUpcomingJob}
                date={nextUpcomingJobDate}
                hcp={nextUpcomingJobHCP}
                hideHour={!nextUpcomingJob?.startDateTime}
            />
            <td className="patient-list__actions-cell">
                <PatientListQuickActions patient={patient} />
            </td>
        </tr>
    );
};
