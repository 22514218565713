import { fetchAuthSession } from 'aws-amplify/auth';

interface GetBearerTokenOptions {
    tokenType?: 'id' | 'access';
    asBearerToken?: boolean;
}

export const getBearerToken = async (
    options: GetBearerTokenOptions = {},
): Promise<string | null> => {
    try {
        const userSession = await fetchAuthSession();

        const cognitoToken =
            options.tokenType === 'id'
                ? userSession.tokens?.idToken
                : userSession.tokens?.accessToken;
        const token = cognitoToken?.toString();

        if (!token) {
            return null;
        }

        return `${options.asBearerToken ? 'Bearer ' : ''}${token}`;
    } catch (error) {
        return null;
    }
};
