import { AbortReason, v2 } from '@doc-abode/data-models';

/**
 * Utility to determine whether a selected abort reason requires mandatory notes
 * @param abortReasons list of potential abort reasons to check against
 * @param selectedReason the name of the selected reason for which to check
 * @returns a boolean to indicate whether the selected type required mandatory notes
 */
export function isAbortNoteRequired(
    abortReasons: AbortReason[] | v2.AssignmentStatusReason[],
    selectedReason: string,
): boolean {
    if (!Array.isArray(abortReasons)) {
        return false;
    }

    const abortReasonFound: v2.AssignmentStatusReason | undefined = (
        abortReasons as v2.AssignmentStatusReason[]
    ).find((abortReason: v2.AssignmentStatusReason) => abortReason.reason === selectedReason);

    return abortReasonFound?.requiresNote || false;
}
