interface IConditionalDisplayProps {
    show: boolean;
    children: null | JSX.Element | string | JSX.Element[];
}

export function ConditionalDisplay({
    show,
    children,
}: IConditionalDisplayProps): null | JSX.Element {
    if (show) {
        return <>{children}</>;
    }
    return null;
}
