import moment from 'moment';
import * as Yup from 'yup';

import { ValidationMessages } from './validation';

const isDateLaterOrEqualToAnotherDate = (
    dateExpectedToBeLater?: Date,
    dateExpectedToBeEarlier?: Date,
) => {
    if (!dateExpectedToBeEarlier) {
        return true;
    }

    if (!dateExpectedToBeLater) {
        return true;
    }

    return dateExpectedToBeLater >= dateExpectedToBeEarlier;
};

const getMaxPermissibleFutureDate = () => {
    // Adding a few minutes to the max permissible date allows them to enter data and complete
    return moment(new Date()).add(5, 'minutes').toDate();
};

export const jobStatusValidationSchema = Yup.object({
    madeCurrentDateTime: Yup.lazy(() =>
        Yup.date()
            .nullable()
            .max(getMaxPermissibleFutureDate(), ValidationMessages.MADE_CURRENT_IN_FUTURE),
    ),
    arrivedDateTime: Yup.lazy(() =>
        Yup.date()
            .nullable()
            .max(getMaxPermissibleFutureDate(), ValidationMessages.ARRIVED_IN_FUTURE)
            .when('madeCurrentDateTime', (madeCurrentDateTime, schema) => {
                return madeCurrentDateTime
                    ? schema.test(
                          'arrivedVsCurrent',
                          ValidationMessages.ARRIVED_AFTER_MADE_CURRENT,
                          (value: Date) => {
                              return isDateLaterOrEqualToAnotherDate(value, madeCurrentDateTime);
                          },
                      )
                    : schema;
            }),
    ),
    finishedDateTime: Yup.lazy(() =>
        Yup.date()
            .nullable()
            .max(getMaxPermissibleFutureDate(), ValidationMessages.FINISHED_IN_FUTURE)
            .when('madeCurrentDateTime', (madeCurrentDateTime, schema) => {
                return madeCurrentDateTime
                    ? schema.test(
                          'finishedVsCurrent',
                          ValidationMessages.FINISHED_AFTER_MADE_CURRENT,
                          (value: Date) => {
                              return isDateLaterOrEqualToAnotherDate(value, madeCurrentDateTime);
                          },
                      )
                    : schema;
            })
            .when('arrivedDateTime', (arrivedDateTime, schema) => {
                return arrivedDateTime
                    ? schema.test(
                          'finishedVsArrived',
                          ValidationMessages.FINISHED_AFTER_ARRIVED,
                          (value: Date) => {
                              return isDateLaterOrEqualToAnotherDate(value, arrivedDateTime);
                          },
                      )
                    : schema;
            }),
    ),
    hcpAbortedDateTime: Yup.lazy(() =>
        Yup.date()
            .nullable()
            .max(getMaxPermissibleFutureDate(), ValidationMessages.HCP_ABORTED_IN_FUTURE)
            .when('madeCurrentDateTime', (madeCurrentDateTime, schema) => {
                return madeCurrentDateTime
                    ? schema.test(
                          'abortedVsCurrent',
                          ValidationMessages.ABORTED_AFTER_MADE_CURRENT,
                          (value: Date) => {
                              return isDateLaterOrEqualToAnotherDate(value, madeCurrentDateTime);
                          },
                      )
                    : schema;
            })
            .when('arrivedDateTime', (arrivedDateTime, schema) => {
                return arrivedDateTime
                    ? schema.test(
                          'abortedVsArrived',
                          ValidationMessages.ABORTED_AFTER_ARRIVED,
                          (value: Date) => {
                              return isDateLaterOrEqualToAnotherDate(value, arrivedDateTime);
                          },
                      )
                    : schema;
            }),
    ),
    buddyMadeCurrentDateTime: Yup.lazy(() =>
        Yup.date()
            .nullable()
            .max(getMaxPermissibleFutureDate(), ValidationMessages.MADE_CURRENT_IN_FUTURE),
    ),
    buddyArrivedDateTime: Yup.lazy(() =>
        Yup.date()
            .nullable()
            .max(getMaxPermissibleFutureDate(), ValidationMessages.ARRIVED_IN_FUTURE)
            .when('buddyMadeCurrentDateTime', (buddyMadeCurrentDateTime, schema) => {
                return buddyMadeCurrentDateTime
                    ? schema.test(
                          'buddyArrivedVsCurrent',
                          ValidationMessages.ARRIVED_AFTER_MADE_CURRENT,
                          (value: Date) => {
                              return isDateLaterOrEqualToAnotherDate(
                                  value,
                                  buddyMadeCurrentDateTime,
                              );
                          },
                      )
                    : schema;
            }),
    ),
    buddyFinishedDateTime: Yup.lazy(() =>
        Yup.date()
            .nullable()
            .max(getMaxPermissibleFutureDate(), ValidationMessages.FINISHED_IN_FUTURE)
            .when('buddyMadeCurrentDateTime', (buddyMadeCurrentDateTime, schema) => {
                return buddyMadeCurrentDateTime
                    ? schema.test(
                          'buddyFinishedVsCurrent',
                          ValidationMessages.FINISHED_AFTER_MADE_CURRENT,
                          (value: Date) => {
                              return isDateLaterOrEqualToAnotherDate(
                                  value,
                                  buddyMadeCurrentDateTime,
                              );
                          },
                      )
                    : schema;
            })
            .when('buddyArrivedDateTime', (buddyArrivedDateTime, schema) => {
                return buddyArrivedDateTime
                    ? schema.test(
                          'buddyFinishedVsArrived',
                          ValidationMessages.FINISHED_AFTER_ARRIVED,
                          (value: Date) => {
                              return isDateLaterOrEqualToAnotherDate(value, buddyArrivedDateTime);
                          },
                      )
                    : schema;
            }),
    ),
    buddyHcpAbortedDateTime: Yup.lazy(() =>
        Yup.date()
            .nullable()
            .max(getMaxPermissibleFutureDate(), ValidationMessages.HCP_ABORTED_IN_FUTURE)
            .when('buddyMadeCurrentDateTime', (buddyMadeCurrentDateTime, schema) => {
                return buddyMadeCurrentDateTime
                    ? schema.test(
                          'buddyAbortedVsCurrent',
                          ValidationMessages.ABORTED_AFTER_MADE_CURRENT,
                          (value: Date) => {
                              return isDateLaterOrEqualToAnotherDate(
                                  value,
                                  buddyMadeCurrentDateTime,
                              );
                          },
                      )
                    : schema;
            })
            .when('buddyArrivedDateTime', (buddyArrivedDateTime, schema) => {
                return buddyArrivedDateTime
                    ? schema.test(
                          'buddyAbortedVsArrived',
                          ValidationMessages.ABORTED_AFTER_ARRIVED,
                          (value: Date) => {
                              return isDateLaterOrEqualToAnotherDate(value, buddyArrivedDateTime);
                          },
                      )
                    : schema;
            }),
    ),
});
